import { Stack } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'

import PageTransition from '@/components/atoms/PageTransition'

import VaultsOverview from '@/pages/SmartVaults/VaultsOverview'
import SmartVaultsRewards from '@/pages/SmartVaults/VaultsRewards'

import { Routes } from '@/constants/routes'

const SmartVaults = () => {
  const { chain } = useConnectionState()

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)}>
        {location.pathname === Routes(chain).smartVaults.earnings.url ? (
          <SmartVaultsRewards />
        ) : (
          <VaultsOverview />
        )}
      </Stack>
    </PageTransition>
  )
}

export default SmartVaults
