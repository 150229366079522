const SpoolLogoBig = () => {
  return (
    <svg
      width='33'
      height='50'
      viewBox='0 0 33 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.4875 22.2794C26.5326 21.6718 27.1241 20.5764 27.1434 19.4415L27.9016 19.0008L32.2869 26.6483C33.4839 28.7358 32.7786 31.4022 30.7115 32.6038L7.38395 46.1641C6.33881 46.7716 5.74728 47.8671 5.72799 49.002L4.96985 49.4427L0.584557 41.7952C-0.612489 39.7076 0.0928424 37.0413 2.15996 35.8396L25.4875 22.2794Z'
        fill='#006BA6'
      />
      <path
        d='M25.4875 3.27936C26.5326 2.67183 27.1241 1.57638 27.1434 0.441502L27.9016 0.0007953L32.2869 7.64831C33.4839 9.73584 32.7786 12.4022 30.7115 13.6038L7.38395 27.1641C6.33881 27.7716 5.74728 28.8671 5.72799 30.002L4.96985 30.4427L0.584557 22.7952C-0.612489 20.7076 0.0928424 18.0413 2.15996 16.8396L25.4875 3.27936Z'
        fill='#006BA6'
      />
    </svg>
  )
}

export default SpoolLogoBig
