import {
  alpha,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

interface ThemeProps {
  children: React.ReactNode
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHelper: true
    tableHeader: true
    chip: true
    alertTitle: true
    badgeLabel: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  tableHelper: React.CSSProperties
  tableHeader: React.CSSProperties
  chip: React.CSSProperties
  alertTitle: React.CSSProperties
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    highlight: {
      background: string
      border: string
    }
    icon: {
      primary: string
    }
  }
  interface PaletteOptions {
    highlight: {
      background: string
      border: string
    }
    icon: {
      primary: string
    }
  }
}

const fontFamily = [
  '"Poppins"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(',')

const primaryColor = '#006BA6'

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#ffffff',
    },
    success: {
      main: '#2E7D32',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D32F2F',
      contrastText: '#ffffff',
    },
    highlight: {
      background: 'rgba(42, 162, 202, 0.08)',
      border: 'rgba(42, 162, 202, 0.3)',
    },
    icon: {
      primary: 'rgba(0, 0, 0, 0.54)',
    },
  },
  typography: {
    fontFamily: fontFamily,
    allVariants: {
      fontFamily: fontFamily,
      fontWeight: 400,
    },
    h6: {
      fontFamily: fontFamily,
      fontSize: 20,
      fontWeight: 300,
    },
    body1: {
      fontFamily: fontFamily,
      fontSize: 16,
    },
    body2: {
      fontFamily: fontFamily,
      fontSize: 14,
      lineHeight: '20px',
    },
    caption: {
      fontFamily: fontFamily,
      fontSize: 12,
      lineHeight: '20px',
    },
    overline: {
      fontFamily: fontFamily,
      letterSpacing: '1px',
    },
    tableHeader: {
      fontFamily: fontFamily,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
    },
    tableHelper: {
      fontFamily: fontFamily,
      fontSize: 12,
      letterSpacing: '0.4px',
      fontWeight: 400,
    },
    chip: {
      fontFamily: fontFamily,
      fontSize: 13,
    },
    alertTitle: {
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: fontFamily,
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    badgeLabel: {
      fontFamily: fontFamily,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.14px',
      textTransform: 'uppercase',
    },
  } as ExtendedTypographyOptions,
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 900,
      lg: 1250,
      xl: 1536,
      xxl: 1921,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          position: 'absolute',
          width: '100%',
          padding: '16px',
          marginTop: '8px',
          backgroundColor: '#ffffff',
          color: '#000000',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        message: {
          width: '100%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&.section-card': {
            overflow: 'inherit',
          },
          '&.section-item-card': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '4px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            padding: '0px',
          },
          '&.section-card-content': {
            display: 'flex',
            flexDirection: 'column',
          },
          '&.section-item-card-content': {
            width: '100%',
          },
          '> .section-item-header': {
            display: 'flex',
            alignItems: 'baseline',
            whiteSpace: 'pre',
            '&.darker': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
          '> .section-item-header-column': {
            flexDirection: 'column',
          },
          '> .section-item-content': {
            backgroundColor: '#fff',
          },
          '> .section-content': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          },
          '> .section-content-center': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          },
          '.primary-background-color': {
            backgroundColor: alpha(primaryColor, 0.04),
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          width: '64px',
          height: '50px',
          bgcolor: 'rgba(0, 0, 0, 0.04)',
          textAlign: 'center',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '&.staking-history-table-head': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&.default-table-head': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&.create-vault-table-head': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            height: '56px',
            th: {
              verticalAlign: 'middle',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '0px',
          wordWrap: 'break-word',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          ':last-child>td': {
            borderBottom: 'unset',
          },
          '&.staking-vault': {
            '&.vault-details': {
              backgroundColor: alpha(primaryColor, 0.04),
            },
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // Default styles for the root element
          '&.Mui-active': {
            '.MuiTableSortLabel-icon': {
              color: alpha(primaryColor, 0.54),
            },
          },
          '&:hover': {
            '.MuiTableSortLabel-icon': {
              color: alpha(primaryColor, 0.87),
              opacity: 1,
            },
          },
          '.MuiTableSortLabel-icon': {
            // Default icon color (inactive)
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },

        icon: {
          opacity: '0.5',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        selectLabel: {
          fontSize: 12,
        },
        displayedRows: {
          fontSize: 12,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: '0.46px',
          fontWeight: 500,
          minWidth: 0,
          '&.connect-wallet-button': {
            height: '55px',
            width: '100%',
          },
          '&.disabled': {
            backgroundColor: '#ffffffee',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          gap: '0.2rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '0.7rem',
          gap: '0.2rem',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.hook-loading': {
            color: primaryColor,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: '30px',
          color: primaryColor,
          fontSize: 13,
          fontWeight: 500,
          padding: '4px 10px',
          letterSpacing: '0.46px',
          border: `1px solid ${alpha(primaryColor, 0.5)}`,
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: primaryColor,
            color: 'white',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          fontWeight: 400,
          maxWidth: '350px',
          padding: '14px',
          backgroundColor: '#ffffff',
          color: 'rgba(0, 0, 0, 0.87)',
          borderRadius: '4px',
          boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
          filter:
            'drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))',
        },
        arrow: {
          color: '#ffffff',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.sm': {
            width: 16,
            height: 16,
          },
          '&.md': {
            width: 18,
            height: 18,
          },
          '&.lg': {
            width: 20,
            height: 20,
          },
          '&.xl': {
            width: 22,
            height: 22,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          height: '100%',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: 'black',
          '&:hover': {
            color: '#006BA6',
          },
        },
      },
    },
  },
})

const MainTheme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <StyledEngineProvider>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MainTheme
