import { SortOrder } from '@solidant/spool-v2-fe-lib'

import {
  AllocationAction,
  AllocationActionTypes,
  AllocationState,
} from '@/store/governance/allocation.types'

const allocationReducer = (
  state: AllocationState,
  action: AllocationAction
) => {
  switch (action.type) {
    case AllocationActionTypes.CHANGE_GOVERNANCE_SORT_DIRECTION:
      return {
        ...state,
        governanceSort: {
          ...state.governanceSort,
          direction:
            state.governanceSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC,
        },
      }
    case AllocationActionTypes.SET_GOVERNANCE_SORT:
      return { ...state, governanceSort: action.payload }
    case AllocationActionTypes.SET_FILLED_EMISSION_VAULTS:
      return { ...state, filledEmissionVaults: action.payload }
    case AllocationActionTypes.SET_ALLOCATION:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          [action.address]: action.amount,
        },
      }
  }
}

export default allocationReducer
