import {
  EmissionVault,
  GovernanceDetails,
  TimeConversions,
  UserVotedChoice,
} from '@solidant/spool-v2-fe-lib'
import dayjs, { unix } from 'dayjs'

import useLocale from '@/hooks/context/useLocale'

import ErrorWithText from '@/components/molecules/ErrorWithText'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import Table from '@/components/organisms/Governance/Table'

interface EmissionResultsProps {
  vaults: EmissionVault[]
  userVotingPower: number
  isError: boolean
  isActiveProposal: boolean
  userHasVoted: boolean
  userVotedChoice: UserVotedChoice
  governanceDetails: GovernanceDetails
}

const EmissionResults: React.FC<EmissionResultsProps> = ({
  vaults,
  userVotingPower,
  isError,
  isActiveProposal,
  userHasVoted,
  userVotedChoice,
  governanceDetails,
}) => {
  const t = useLocale()

  const incentiveRewardsIssueDate =
    governanceDetails?.proposal.end + 3 * TimeConversions.SECONDS_PER_DAY

  const timestampNow = dayjs().unix()

  return (
    <Section>
      <SectionColumn
        title={
          isActiveProposal
            ? t('components.organisms.governance.activeProposal.title')
            : `${t(
                'components.organisms.governance.emissionResults.title'
              )} ${unix(governanceDetails.proposal.start).format(
                'DD.MM.YYYY'
              )} - ${unix(governanceDetails.proposal.end).format('DD.MM.YYYY')}`
        }
        subtitle={
          !isActiveProposal &&
          `${
            timestampNow > incentiveRewardsIssueDate
              ? t(
                  'components.organisms.governance.emissionResults.incentivesIssued'
                )
              : t(
                  'components.organisms.governance.emissionResults.incentivesPending'
                )
          }${unix(incentiveRewardsIssueDate).format('DD.MM.YYYY')}`
        }
        sx={{
          gap: (theme) => theme.spacing(1),
        }}
      >
        {isError ? (
          <ErrorWithText
            errorText={t(
              'components.organisms.governance.emissionResults.errorText'
            )}
          />
        ) : (
          <Table
            vaults={vaults}
            votingPower={userVotingPower}
            isActiveProposal={isActiveProposal}
            userHasVoted={userHasVoted}
            userVotedChoice={userVotedChoice}
            governanceDetails={governanceDetails}
          />
        )}
      </SectionColumn>
    </Section>
  )
}

export default EmissionResults
