// import { lazy } from 'react'

import { Box } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { MainContainer } from '@/components/atoms/MainContainer'
import VaultTitle from '@/components/organisms/SmartVaults/VaultDetails/VaultTitle'
import StrategyTitle from '@/components/organisms/Strategies/StrategyTitle'

import CreatorDashboard from '@/pages/Dashboard/CreatorDashboard'
import Dashboard from '@/pages/Dashboard/Dashboard'
import Governance from '@/pages/Governance/Governance'
import UnsupportedMobilePage from '@/pages/Mobile/UnsupportedMobilePage'
import CreateVault from '@/pages/SmartVaults/CreateVault'
import SmartVaults from '@/pages/SmartVaults/SmartVaults'
import VaultsOverviewHeader from '@/pages/SmartVaults/VaultsOverviewHeader'
import VaultsPage from '@/pages/SmartVaults/VaultsPage'
import Staking from '@/pages/Staking/Staking'
import Strategies from '@/pages/Strategies/Strategies'
import StrategyDetails from '@/pages/Strategies/StrategyDetails'
import Vesting from '@/pages/Vesting/Vesting'

import {
  BaseRoutesPaths,
  BaseRoutesPathsWithChain,
  Routes,
} from '@/constants/routes'

// const Governance = lazy(() => import('@/pages/Governance/Governance'))
// const SmartVaults = lazy(() => import('@/pages/SmartVaults/SmartVaults'))
// const Staking = lazy(() => import('@/pages/Staking/Staking'))
// const Vesting = lazy(() => import('@/pages/Vesting/Vesting'))
// const Dashboard = lazy(() => import('@/pages/Dashboard/Dashboard'))
// const Incentives = lazy(() => import('@/pages/Incentives/Incentives'))

const getRouteConfigurations = () => {
  const { chain } = useConnectionState()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const routeConfigurations = [
    ...Object.values(Routes(chain).governance).map(({ url }) => ({
      path: url,
      element: belowPhabletBreakpoint ? (
        <UnsupportedMobilePage feature='Emissions voting' />
      ) : (
        <MainContainer>
          <Governance />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).staking).map(({ url }) => ({
      path: url,
      element: belowPhabletBreakpoint ? (
        <UnsupportedMobilePage feature='Staking' />
      ) : (
        <MainContainer>
          <Staking />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).vesting).map(({ url }) => ({
      path: url,
      element: (
        <MainContainer>
          <Vesting />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).smartVaults).map(({ url }) => {
      if (
        url ===
        `${BaseRoutesPathsWithChain(
          chain,
          BaseRoutesPaths.SMART_VAULTS
        )}/:vaultId`
      ) {
        return {
          path: url,
          element: (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              alignItems='center'
            >
              <VaultTitle />
              <MainContainer>
                <VaultsPage />
              </MainContainer>
            </Box>
          ),
        }
      }
      return {
        path: url,
        element: (
          <Box
            display='flex'
            width='100%'
            flexDirection='column'
            alignItems='center'
          >
            <VaultsOverviewHeader />
            <MainContainer>
              <SmartVaults />
            </MainContainer>
          </Box>
        ),
      }
    }),
    ...Object.values(Routes(chain).createVault).map(({ url }) => ({
      path: url,
      element: belowPhabletBreakpoint ? (
        <UnsupportedMobilePage feature='Create vault' />
      ) : (
        <MainContainer>
          <CreateVault />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).dashboard).map(({ url }) => ({
      path: url,
      element: (
        <MainContainer>
          <Dashboard />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).manager).map(({ url }) => ({
      path: url,
      element: (
        <MainContainer>
          <CreatorDashboard />
        </MainContainer>
      ),
    })),
    ...Object.values(Routes(chain).strategies).map(({ url }) => {
      if (
        url ===
        `${BaseRoutesPathsWithChain(
          chain,
          BaseRoutesPaths.STRATEGIES
        )}/:strategyId`
      ) {
        return {
          path: url,
          element: (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              alignItems='center'
            >
              <StrategyTitle />
              <MainContainer>
                <StrategyDetails />
              </MainContainer>
            </Box>
          ),
        }
      }
      return {
        path: url,
        element: (
          <MainContainer>
            <Strategies />
          </MainContainer>
        ),
      }
    }),
  ]

  return routeConfigurations
}

export default getRouteConfigurations
