import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'
import useStakedSpool from '@/hooks/staking/useStakedSpool'

import SectionItem from '@/components/molecules/SectionItem'

import { Token } from '@/constants/smartVault'
import { formatUSD, getUnixDateTimeString } from '@/utils/formats'

const EndGoal = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  const { rewardDetails, isLoading: isLoadingRewards } =
    useRewardDetails(account)
  const { stakedSpool, isLoading: isLoadingStaked } = useStakedSpool(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.endGoal.title'
      )}
      sx={{ width: '100%' }}
    >
      <Box display='flex' alignItems='baseline'>
        <Typography variant='h6'>
          {!isLoadingRewards ? formatUSD(stakedSpool) : '0'}
        </Typography>
        <Typography variant='body1'>&nbsp;{Token.VOSPOOL}</Typography>
      </Box>
      {!isLoadingStaked && (
        <Typography variant='caption' color='rgba(33, 35, 34, 0.6)'>
          {t('components.organisms.staking.rewards.sectionItems.endGoal.on')}{' '}
          {getUnixDateTimeString(rewardDetails?.endGoalTime).dateOfDeposit}
        </Typography>
      )}
    </SectionItem>
  )
}

export default EndGoal
