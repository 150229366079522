import { formatEther } from '@ethersproject/units'
import { Box, Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useUserBalance from '@/hooks/web3/useUserBalance'

import SectionItem from '@/components/molecules/SectionItem'
import AmountTextUSD from '@/components/organisms/Staking/AmountTextUSD'

import { Token } from '@/constants/smartVault'
import { tokenAddresses } from '@/constants/tokens'
import { formatUSD } from '@/utils/formats'
//import {useEffect} from "react";

const SpoolBalance = () => {
  const t = useLocale()
  const { chain } = useConnectionState()

  const { balance /*, updateUserBalance, isLoading, isValidating*/ } =
    useUserBalance(tokenAddresses[chain].SPOOL)

  /*useEffect(() => {
    console.log('Is loading:', isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log('Is validating:', isValidating);
  }, [isValidating]);*/

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.overview.sectionItems.tokensInWallet.title'
      )}
    >
      <Box display='flex' alignItems='baseline'>
        <Typography variant='h6'>{formatUSD(formatEther(balance))}</Typography>
        <Typography variant='body1'>&nbsp;{Token.SPOOL}</Typography>
      </Box>
      <AmountTextUSD amount={formatEther(balance) ?? '0'} />
    </SectionItem>
  )
}

export default SpoolBalance
