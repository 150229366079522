import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'
import { CreatorSmartVault, UserClaimableNFTs } from '@solidant/spool-v2-fe-lib'
import { useMemo } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useMultiNftClaim from '@/hooks/vaultList/Transactions/useMultiNftClaim'

import { formatUSD } from '@/utils/formats'

interface CreatorVaultsMobileRowWithdrawal {
  vaultClaimableNFTs: UserClaimableNFTs[]
  row: CreatorSmartVault
}

const CreatorVaultsMobileRowWithdrawal: React.FC<
  CreatorVaultsMobileRowWithdrawal
> = ({ vaultClaimableNFTs, row }) => {
  const t = useLocale()

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const claimMultipleNft = useMultiNftClaim()

  const { address, pendingWithdrawals } = row

  const totalClaimableAmount = useMemo(() => {
    return vaultClaimableNFTs.reduce((curr, { withdrawAmount }) => {
      return (curr += +withdrawAmount)
    }, 0)
  }, [vaultClaimableNFTs])

  const wNfts = useMemo(
    () =>
      vaultClaimableNFTs.map(({ wnftId, shares }) => {
        return { nftId: wnftId, shares }
      }),
    [vaultClaimableNFTs]
  )

  return (
    (!!wNfts.length || +pendingWithdrawals.total) && (
      <Box
        borderRadius={1}
        border='1px solid rgba(33, 35, 34, 0.12) '
        display='flex'
        flexDirection='column'
        gap='1rem'
        padding='0.5rem'
      >
        {!!wNfts.length && (
          <Box
            display='flex'
            alignItems='start'
            flexDirection={belowPhabletBreakpoint ? 'column' : 'row'}
            justifyContent={belowPhabletBreakpoint ? '' : 'space-between'}
            gap='0.2rem'
          >
            <Box display='flex'>
              <Box display='flex' alignItems='start' gap='0.1rem'>
                <Box display='flex' alignItems='center'>
                  <InfoOutlinedIcon
                    sx={{ color: 'text.secondary' }}
                    fontSize='small'
                  />
                </Box>
                <Typography fontSize='14px'>
                  {totalClaimableAmount
                    ? `$${formatUSD(totalClaimableAmount)} is `
                    : 'There are deposits '}
                  available to withdraw
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ cursor: 'pointer', mr: '0.1rem', ml: '0.3rem' }}
              onClick={() => {
                claimMultipleNft(wNfts, address)
              }}
            >
              <Typography fontSize='14px' color='primary'>
                {t(
                  'components.organisms.dashboard.investor.investorAllocations.vaultsAllocation.buttons.withdrawFunds.label'
                )}
              </Typography>
            </Box>
          </Box>
        )}
        {Number(pendingWithdrawals.total ?? '0') > 0 && (
          <Box display='flex' alignItems='start' gap='0.1rem'>
            <Box display='flex' alignItems='center'>
              <InfoOutlinedIcon
                sx={{ color: 'text.secondary' }}
                fontSize='small'
              />
            </Box>
            <Typography fontSize='14px'>
              ${formatUSD(pendingWithdrawals.total)} withdrawal has been
              initiated. It will be available to be claimed within 24 hours
            </Typography>
          </Box>
        )}
      </Box>
    )
  )
}

export default CreatorVaultsMobileRowWithdrawal
