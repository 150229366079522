import { Box, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'

import AmountTextUSD from '@/components/organisms/Staking/AmountTextUSD'

import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

interface AmountTextProps {
  rewards?: any[]
  displayPriceFeed?: boolean
}

const AmountText: React.FC<AmountTextProps> = ({
  rewards,
  displayPriceFeed,
}) => {
  return (
    <>
      {(!rewards || rewards.length === 0) && (
        <Box display='flex' alignItems='baseline'>
          <Typography variant='h6'>{formatUSD(0)}</Typography>
          <Typography variant='body1'>&nbsp;{Token.SPOOL}</Typography>
        </Box>
      )}
      <Stack>
        {rewards &&
          rewards.map((totalReward) => (
            <Fragment key={totalReward.asset.symbol}>
              <Box display='flex' alignItems='baseline'>
                <Typography variant='h6'>
                  {formatUSD(totalReward.amount)}
                </Typography>
                <Typography variant='body1'>
                  &nbsp;{totalReward.asset.symbol}
                </Typography>
              </Box>
              {displayPriceFeed && (
                <AmountTextUSD amount={totalReward?.amount || 0} />
              )}
            </Fragment>
          ))}
      </Stack>
    </>
  )
}

export default AmountText
