import {
  Box,
  Checkbox,
  Stack,
  SxProps,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { ChangeEvent, memo, useState } from 'react'

import TableCellInfoTooltip from '@/components/organisms/Tooltips/TableCellInfoTooltip'

import { HeadCell, Pagination, SortType, SortTypes } from '@/types/table'

interface TableWrapperProps<T> {
  header: readonly HeadCell[]
  headerClass?: string
  withCheckbox?: boolean
  pagination?: Pagination
  verticalAlignTop?: boolean
  handleSortClick?: (
    key: keyof T,
    sortType: SortType<T>,
    mutatableData?: any
  ) => void
  mutatableData?: any
  sortType?: SortTypes
  children: React.ReactNode
  onCheckedChange?: () => void
  infoTitle?: string
  infoText?: string
  tokens?: boolean
  moreOptions?: boolean
  headerSX?: SxProps<Theme>
}

const TableWrapper = <T,>({
  header,
  headerClass,
  withCheckbox,
  pagination,
  verticalAlignTop,
  handleSortClick,
  mutatableData,
  sortType,
  children,
  onCheckedChange,
  tokens,
  moreOptions,
  headerSX,
}: TableWrapperProps<T>) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [orderBy, setOrderBy] = useState<string>()

  const handleSort = (id: string) => {
    const isDesc = orderBy === id && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(id)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pagination?.setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    pagination?.setRowsPerPage(parseInt(event.target.value, 10))
    pagination?.setPage(0)
  }

  return (
    <>
      <TableContainer
        component={Box}
        sx={{ position: 'relative', overflow: 'hidden' }}
      >
        <Table size='small'>
          <TableHead className={headerClass}>
            <TableRow
              sx={{ verticalAlign: verticalAlignTop ? 'top' : 'bottom' }}
            >
              {withCheckbox && (
                <TableCell padding='none'>
                  {onCheckedChange && <Checkbox onChange={onCheckedChange} />}
                </TableCell>
              )}
              {tokens && <TableCell sx={{ pl: 0, ...headerSX }} />}
              {header.map(
                (
                  {
                    id,
                    label,
                    notSortable,
                    tableHelperTop,
                    tableHelperBottom,
                    withCheckbox,
                    onCheckboxChange,
                    align,
                    width,
                    infoTitle,
                    infoText,
                  },
                  index
                ) => {
                  return (
                    // TODO: Refactor with sorting state
                    <TableCell
                      key={id}
                      sx={{
                        width:
                          id === 'name' ? 'auto' : width ? { width } : 'auto',
                        textAlign: align,
                        verticalAlign: verticalAlignTop ? 'top' : 'bottom',
                        pl: tokens && index === 0 && 0,
                        ...headerSX,
                      }}
                      // sortDirection={orderBy === id ? order : false}
                      sortDirection={
                        sortType
                          ? sortType.direction === SortOrder.ASC
                            ? 'asc'
                            : 'desc'
                          : 'desc'
                      }
                    >
                      {withCheckbox && (
                        <Checkbox
                          sx={{ padding: onCheckedChange && '0' }}
                          onChange={onCheckboxChange}
                        />
                      )}

                      {notSortable ? (
                        <Box display='flex' justifyContent={align}>
                          <Stack>
                            {tableHelperTop && (
                              <Typography variant='tableHeader'>
                                {tableHelperTop}
                              </Typography>
                            )}
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              gap={0.5}
                            >
                              <Typography variant='tableHeader'>
                                {label}
                              </Typography>
                              {infoTitle && (
                                <TableCellInfoTooltip
                                  infoTitle={infoTitle}
                                  infoText={infoText}
                                />
                              )}
                            </Box>
                            {tableHelperBottom && (
                              <Typography variant='tableHeader'>
                                {tableHelperBottom}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      ) : (
                        <TableSortLabel
                          active={orderBy === id}
                          direction={
                            sortType
                              ? sortType.key === id &&
                                sortType.direction === SortOrder.ASC
                                ? 'asc'
                                : 'desc'
                              : 'desc'
                          }
                          onClick={() =>
                            // TODO: needs to be refactored when all tables has sorting function
                            handleSortClick
                              ? handleSortClick(
                                  id as any,
                                  sortType as any,
                                  mutatableData
                                )
                              : handleSort(id)
                          }
                          sx={{
                            alignItems: verticalAlignTop ? 'start' : 'end',
                            '.MuiTableSortLabel-icon': {
                              margin: '4px',
                              color: sortType
                                ? sortType.key === id
                                  ? '#0088ff'
                                  : 'rgba(0, 0, 0, 0.87)'
                                : 'rgba(0, 0, 0, 0.87)',
                            },
                          }}
                        >
                          <Stack textAlign={align}>
                            {tableHelperTop && (
                              <Typography variant='tableHeader'>
                                {tableHelperTop}
                              </Typography>
                            )}
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              gap={0.5}
                            >
                              <Typography variant='tableHeader'>
                                {label}
                              </Typography>
                              {infoTitle && (
                                <TableCellInfoTooltip
                                  infoTitle={infoTitle}
                                  infoText={infoText}
                                />
                              )}
                            </Box>
                            {tableHelperBottom && (
                              <Typography variant='tableHeader'>
                                {tableHelperBottom}
                              </Typography>
                            )}
                          </Stack>
                        </TableSortLabel>
                      )}
                    </TableCell>
                  )
                }
              )}
              {(tokens || moreOptions) && (
                <TableCell sx={{ px: 1, ...headerSX }} />
              )}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          component='div'
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}

export default memo(TableWrapper)
