import { RiskModelWithStrategies } from '@solidant/spool-v2-fe-lib'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { mapRiskModelConfig } from '@/constants/riskModels/riskModels'

const useRiskModelAndStrategies = () => {
  const { sdk } = useContext(AppContext)
  const { chain } = useConnectionState()

  // ARB

  const { data, error } = useSWR(
    sdk ? ['riskModelAndStrategies', chain] : null,
    async () => {
      const riskModelsAndStrategies = await sdk.riskModels.getRiskModels(true)

      const riskModelConfig = mapRiskModelConfig(chain)

      if (riskModelConfig) {
        return riskModelsAndStrategies
          .filter(({ address }) => !!riskModelConfig[address])
          .map((riskModelandStrategy: RiskModelWithStrategies) => {
            const { address, strategies } = riskModelandStrategy

            if (!riskModelConfig[address]) return
            return {
              ...riskModelConfig[address],
              riskModelId: address,
              strategies: strategies.map((strategy) => {
                return {
                  chainId: chain,
                  ...strategy,
                }
              }),
            }
          })
      }
    }
  )

  return {
    riskModelsAndStrategies: data,
    isLoading: !error && !data,
    error,
  }
}

export default useRiskModelAndStrategies
