import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import tenderly from '@/config/tenderly/ethereum.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const ETHEREUM_TENDERLY_CONFIG = {
  RPC_PROVIDER:
    'https://mainnet.rpc.tenderly.co/c2b44bdb-468c-4666-aeb0-e0c7586fd92b',
  CORE_SUBGRAPH:
    'https://spool-v2-testing.spool.fi/subgraphs/name/spool/core-v2',
  API_URL: 'https://api.dev.spool.fi/tenderly',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.dev.spool.fi',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.dev.spool.fi/tenderly',
  PRICE_FEED: 'https://pricefeed.v2.spool.fi/',
  SMART_VAULT_MANAGER: tenderly.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: tenderly.DepositSwap.proxy,
  SMART_VAULT_FACTORY: tenderly.SmartVaultFactory,
  REWARD_MANAGER: tenderly.RewardManager.proxy,
  SPOOL_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  VO_SPOOL: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: tenderly.SpoolLens.proxy,
  SPOOL_TOKEN: tokenAddresses[SupportedNetworks.ETHEREUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: tenderly.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x1111111254eeb25477b68fb85ed929f73a960582',
  SWAPPER: tenderly.Swapper.proxy,
  ALLOW_LIST_GUARD: tenderly.AllowlistGuard,
  IGUARD_MANAGER: tenderly.GuardManager.proxy,
}

export const ETHEREUM_TENDERLY_FLUSHES = {
  WITHDRAW: false,
  SWAP_AND_DEPOSIT: false,
  SINGLE_SWAP_DEPOSIT: false,
  MULTI_SWAP_DEPOSIT: false,
  DEPOSIT: false,
}

export const ETHEREUM_TENDERLY_FEATURES = {
  CREATE_VAULT_ADVANCED: true,
  ARBITRUM_ONE_NET: false,
  FAST_WITHDRAW: true,
  MULTI_SWAP_DEPOSIT: false,
}
