import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import {
  CreatorSmartVault,
  IncentivizeRewardToken,
  IncentivizeVault,
} from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useReincentivize from '@/hooks/incentives/transactions/useReincentivize'

import { countDecimals } from '@/utils/helpers/countDecimals'

interface CreatorVaultsRewardsTablenOptionsProps {
  row: CreatorSmartVault & IncentivizeVault
  lastIncentivizedDays: string
  targetToken: string
  tokenAmount: string
  incentivizeReward: IncentivizeRewardToken
}

const CreatorVaultsRewardsTableOptions: React.FC<
  CreatorVaultsRewardsTablenOptionsProps
> = ({ row, lastIncentivizedDays, targetToken, incentivizeReward }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const t = useLocale()

  const { updateIncentivizedRewards } = useCreatorDashboard()

  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const reincentive = useReincentivize(
    row?.address ?? '',
    targetToken,
    countDecimals(incentivizeReward.totalAmount.toString()) >
      incentivizeReward.asset.decimals
      ? parseFloat(incentivizeReward.totalAmount.toString()).toFixed(
          incentivizeReward.asset.decimals
        )
      : incentivizeReward.totalAmount.toString(),
    lastIncentivizedDays,
    incentivizeReward,
    () =>
      updateIncentivizedRewards(
        row?.address ?? '',
        false,
        row.incentivizedRewards,
        targetToken
      )
  )

  return (
    <>
      <IconButton
        aria-controls={open ? 'investor-vaults-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='investor-vaults-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box py={2}>
          <MenuItem onClick={reincentive}>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.options'
            )}
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
}

export default CreatorVaultsRewardsTableOptions
