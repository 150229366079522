import {
  CreatorSmartVault,
  InvestorSmartVault,
} from '@solidant/spool-v2-fe-lib'

export const calculateCreatorAssetAllocation = (
  creatorSmartVaults: CreatorSmartVault[]
) => {
  return creatorSmartVaults.reduce((acc, { deposits }) => {
    let curr = acc

    deposits.assets.forEach(({ asset, amountUSD }) => {
      if (!Number(amountUSD)) return

      if (acc[asset.address]) {
        curr = {
          ...curr,
          [asset.address]: curr[asset.address] + Number(amountUSD),
        }
        return
      }
      curr = { ...curr, [asset.address]: Number(amountUSD) }
    })

    return curr
  }, {} as Record<string, number>)
}
export const calculateInvestorAssetAllocation = (
  investorSmartVaults: InvestorSmartVault[]
) => {
  const allocation = investorSmartVaults.reduce((acc, vault) => {
    let curr = acc

    // balance assets
    vault.balance.assets.forEach(({ asset, amountUSD }) => {
      if (!Number(amountUSD)) return

      if (acc[asset.address]) {
        curr = {
          ...curr,
          [asset.address]: curr[asset.address] + Number(amountUSD),
        }
        return
      }
      curr = { ...curr, [asset.address]: Number(amountUSD) }
    })

    // Maybe add pending?
    return curr
  }, {} as Record<string, number>)

  // Convert the object to an array, sort it and convert it back to an object
  return Object.fromEntries(
    Object.entries(allocation).sort(([, a], [, b]) => b - a)
  )
}
