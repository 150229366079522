import {
  CreatorFeesEarnedHistory,
  CreatorTvrHistory,
  InvestorDepositsHistory,
  toBigNumber,
} from '@solidant/spool-v2-fe-lib'
import { unix } from 'dayjs'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

import { FeesChartValues } from '@/store/creatorVault/creatorDashboard.types'

export const getHistoricalDatum = (
  history:
    | CreatorTvrHistory[][]
    | InvestorDepositsHistory[][]
    | CreatorFeesEarnedHistory[][],
  dataProp: 'tvr' | 'userBalance'
) => {
  const accumulatedHistoricalData = accumulateHistoricalData(history, dataProp)

  return formatDataToDatum(accumulatedHistoricalData)
}

export const getFeesHistoricalDatum = (
  history: CreatorFeesEarnedHistory[][]
): FeesChartValues => {
  const accumulatedPerformanceFeesHistoricalData = accumulateHistoricalData(
    history,
    'performanceFees'
  )
  const accumulatedManagementFeesHistoricalData = accumulateHistoricalData(
    history,
    'managementFees'
  )

  return {
    managementFeesChartValues: formatDataToDatum(
      accumulatedManagementFeesHistoricalData
    ),
    performanceFeesChartValues: formatDataToDatum(
      accumulatedPerformanceFeesHistoricalData
    ),
  }
}

const accumulateHistoricalData = (
  history:
    | CreatorTvrHistory[][]
    | InvestorDepositsHistory[][]
    | CreatorFeesEarnedHistory[][],
  dataProp: 'tvr' | 'userBalance' | 'performanceFees' | 'managementFees'
) => {
  return Object.values(history).reduce((aggregated, curr) => {
    let initialAggregated = aggregated

    curr.forEach(
      (
        record:
          | CreatorTvrHistory
          | InvestorDepositsHistory
          | CreatorFeesEarnedHistory
      ) => {
        const { timestamp } = record
        const value =
          dataProp === 'tvr' && 'tvr' in record
            ? record.tvr
            : dataProp === 'userBalance' && 'userBalance' in record
            ? record.userBalance
            : dataProp === 'managementFees' && 'managementFee' in record
            ? record.managementFee
            : dataProp === 'performanceFees' && 'performanceFee' in record
            ? record.performanceFee
            : undefined

        if (initialAggregated[timestamp]) {
          initialAggregated = {
            ...initialAggregated,
            [timestamp]: initialAggregated[timestamp].add(
              dataProp === 'managementFees' || dataProp === 'performanceFees'
                ? toBigNumber(value)
                : BigNumber.from(value)
            ),
          }
          return
        }

        initialAggregated = {
          ...initialAggregated,
          [timestamp]:
            dataProp === 'managementFees' || dataProp === 'performanceFees'
              ? toBigNumber(value)
              : BigNumber.from(value),
        }
      },
      {} as Record<number, BigNumber>
    )

    return initialAggregated
  }, {} as Record<number, BigNumber>)
}

const formatDataToDatum = (
  accumulateHistoricalData: Record<number, BigNumber>
) => {
  return Object.entries(accumulateHistoricalData)
    .map(([timestamp, value]) => {
      const bigNumberValue = BigNumber.from(value)
      return {
        x: unix(+timestamp).format('YYYY-MM-DD'),
        y: formatEther(bigNumberValue),
      }
    })
    .sort((a, b) => parseFloat(a.x) - parseFloat(b.x) * 1)
}
