import { useContext } from 'react'

import { RewardsTableContext } from '@/store/rewardsTable/rewardsTable.context'

const useRewardsTableState = () => {
  const rewardsTableContext = useContext(RewardsTableContext)
  if (!Object.keys(rewardsTableContext).length) {
    throw new Error('RewardsTableContext must be used within its provider')
  }
  return rewardsTableContext
}

export default useRewardsTableState
