import { useWeb3React } from '@web3-react/core'
import { useContext } from 'react'

import useModalState from '@/hooks/context/useModalState'
import useGovernanceDetails from '@/hooks/governance/useGovernanceDetails'

import { AppContext } from '@/store/app/app.context'
import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useVote = () => {
  const { openModal, setModalType } = useModalState()
  const { sdk } = useContext(AppContext)
  const { account } = useWeb3React()

  const { governanceDetails, updateGovernanceDetails } =
    useGovernanceDetails(account)

  const submit = async (
    userAddress: string,
    proposalId: string,
    voteChoice: Record<string, string>,
    isSwissCitizen: boolean,
    isGnosisSafe: boolean
  ) => {
    // snapshot only accepts index of the choice as the key for the vote choice object, so 1,2,3,4...etc.
    const convertedVoteChoice = Object.keys(voteChoice).reduce(
      (prevVoteObj, choiceAddress) => {
        const choiceIndex = governanceDetails.emissionVaults.vaults.find(
          (vault) => {
            return vault.address === choiceAddress
          }
        ).choiceIndex

        if (!+voteChoice[choiceAddress]) return prevVoteObj

        return {
          ...prevVoteObj,
          [choiceIndex]: +voteChoice[choiceAddress],
        }
      },
      {}
    )

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.GovernanceVote,
        },
      })

      await sdk.governance.vote(
        userAddress,
        proposalId,
        convertedVoteChoice,
        isSwissCitizen,
        isGnosisSafe
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.GovernanceVote,
        },
      })

      const previousVotes = Object.values(
        governanceDetails.userVotedChoice
      ).reduce((acc, { amount }) => {
        return (acc += +amount)
      }, 0)

      await sleep(MUTATE_SLEEP_DURATION)

      await updateGovernanceDetails(previousVotes)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.GovernanceVote,
        },
      })
    } catch (error) {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.GovernanceVote,
        },
      })
    }
  }
  return { submit }
}

export default useVote
