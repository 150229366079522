import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { GovernanceDetails } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { ChangeEvent, useMemo, useState } from 'react'

import useGovernanceAllocationState from '@/hooks/context/useGovernanceAllocationState'
import useLocale from '@/hooks/context/useLocale'
import useVote from '@/hooks/governance/useVote'

interface SubmitVoteProps {
  governanceDetails: GovernanceDetails
  votingPower: string
}

const SubmitVote: React.FC<SubmitVoteProps> = ({
  governanceDetails,
  votingPower,
}) => {
  const t = useLocale()

  const { allocation, getTotalAllocation } = useGovernanceAllocationState()

  const { account } = useWeb3React()

  const [checked, setChecked] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const { submit } = useVote()

  const remainingVotingPower = useMemo(
    () => Math.trunc(+votingPower - getTotalAllocation()),
    [allocation]
  )

  return (
    <>
      <FormGroup row sx={{ pl: 1 }}>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={handleChange} disableRipple />
          }
          disabled={
            !governanceDetails.isActiveProposal ||
            governanceDetails.userHasVoted ||
            !+votingPower ||
            remainingVotingPower === Math.trunc(+votingPower) ||
            (!remainingVotingPower && governanceDetails.userHasVoted) ||
            !governanceDetails?.emissionVaults.vaults.length
          }
          label={
            <Typography variant='body2'>
              {t('components.molecules.submitVote.checkbox.label')}
            </Typography>
          }
        />
      </FormGroup>
      <Box display='flex'>
        <Button
          variant='contained'
          disabled={
            !governanceDetails.isActiveProposal ||
            governanceDetails.userHasVoted ||
            !+votingPower ||
            remainingVotingPower === Math.trunc(+votingPower) ||
            (!remainingVotingPower && governanceDetails.userHasVoted) ||
            !governanceDetails?.emissionVaults.vaults.length
          }
          // TODO - 2: isGnosisSafe
          onClick={() => {
            submit(
              account,
              governanceDetails.proposal.id,
              allocation,
              checked,
              false
            )
          }}
        >
          {t('components.molecules.submitVote.button.label')}
        </Button>
      </Box>
    </>
  )
}

export default SubmitVote
