import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import { SectionItemWithInfoIconContainer } from '@/components/atoms/SectionItemWithInfoIconContainer'
import SectionItem from '@/components/molecules/SectionItem'

import { Token } from '@/constants/smartVault'
import { formatNumber } from '@/utils/formats'

const SpoolPerVoSpool = () => {
  const t = useLocale()
  const { account } = useWeb3React()

  // Hardcoded SPOOL token address
  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={`${Token.SPOOL} ${t(
        'components.organisms.staking.rewards.sectionItems.spoolperVospool.title'
      )}`}
      subtitle={t(
        'components.organisms.staking.rewards.sectionItems.spoolperVospool.subtitle'
      )}
    >
      <SectionItemWithInfoIconContainer>
        <Box display='flex' alignItems='baseline'>
          <Typography variant='h6'>
            {formatNumber(Number(rewardDetails?.spoolPerVoSpool ?? 0), 2)}
          </Typography>
          <Typography variant='body1'>&nbsp;{Token.SPOOL}</Typography>
        </Box>
        <Tooltip
          title={t(
            'components.organisms.staking.rewards.sectionItems.spoolperVospool.tooltip'
          )}
        >
          <InfoIcon className='lg' sx={{ color: 'primary.light' }} />
        </Tooltip>
      </SectionItemWithInfoIconContainer>
    </SectionItem>
  )
}

export default SpoolPerVoSpool
