import InfoIcon from '@mui/icons-material/Info'
import Star from '@mui/icons-material/Star'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { SmartVaultBase } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import dayjs, { unix } from 'dayjs'
import { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'

import getLocale from '@/locales/getLocale'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import TextTagRenderer from '@/components/atoms/TextTagRenderer'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'
import ApyTooltip from '@/components/organisms/Tooltips/ApyTooltip'

import Verified from '@/assets/icons/general/Verified'

import { DEFAULT_ROW_HEIGHT } from '@/constants'
import { currency } from '@/constants/currency'
import { gaIds } from '@/constants/googleAnalyticIDs'
import { Routes } from '@/constants/routes'
import {
  CLAIM_INCENTIVIZED_REWARDS_TIME,
  REWARDS_SYNC_DURING_CLAIM,
} from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

interface SmartVaultTableRowProps {
  smartVaultBase: SmartVaultBase
}

const SmartVaultTableRow: React.FC<SmartVaultTableRowProps> = ({
  smartVaultBase,
}) => {
  const { chain } = useConnectionState()

  const {
    address,
    name,
    assetGroup,
    adjustedApy,
    owner,
    incentivizedRewards,
    totalVolumeRouted,
    fees,
    baseApy,
    vaultDetails,
  } = smartVaultBase
  const { account } = useWeb3React()

  const { selectedVault, setSelectedVault } = useSmartVaultsTableState()

  const isOpen = selectedVault?.address === address

  const t = getLocale()

  // check if sync time during claim has passed the current time, if it has then remove the keys
  useEffect(() => {
    const syncTimeAtTimeOfClaim = localStorage.getItem(
      REWARDS_SYNC_DURING_CLAIM + address
    )

    if (
      syncTimeAtTimeOfClaim &&
      dayjs().isAfter(unix(+syncTimeAtTimeOfClaim))
    ) {
      localStorage.removeItem(CLAIM_INCENTIVIZED_REWARDS_TIME + address)
      localStorage.removeItem(REWARDS_SYNC_DURING_CLAIM + address)
    }
  }, [])

  //const showRiskModel = !!riskScore

  const handleVaultSelect = () => {
    setSelectedVault(smartVaultBase)
  }

  return (
    <>
      <TableRow
        component={Link}
        to={`${Routes(chain).smartVaults.root.url}/${smartVaultBase.address}`}
        hover
        onClick={() => handleVaultSelect()}
        role='checkbox'
        aria-checked={isOpen}
        tabIndex={-1}
        selected={isOpen}
        sx={{
          '& > td': {
            borderBottom: isOpen ? 'unset' : undefined,
          },
          height: DEFAULT_ROW_HEIGHT,
          backgroundColor: '#fff',
          userSelect: 'none',
          cursor: 'pointer',
          opacity: !selectedVault?.address || isOpen ? 1 : 0.35,
        }}
        id={gaIds.smartVaultRow.main + '-' + gaIds.smartVaultRow.home}
      >
        <TableCell>
          <AssetGroupTokens assetGroup={assetGroup} />
        </TableCell>
        <TableCell sx={{ pl: '0' }}>
          <Box>
            <NameCellContent>
              {/*{mapIcons(SupportedNetworks.ETHEREUM)}*/}
              <SmartVaultNameTableTypography variant='body2'>
                {name}
              </SmartVaultNameTableTypography>
              {owner === account && (
                <IconButton sx={{ padding: '4px' }} disableRipple>
                  <Star color='primary' className='lg' />
                </IconButton>
              )}
            </NameCellContent>
            {vaultDetails?.createdBy && (
              <Box display='flex' gap={0.5} alignItems='center'>
                <Typography variant='caption' color='gray'>
                  {`By ${vaultDetails?.createdBy}`}
                </Typography>
                <Tooltip placement='right' title='Official Partner'>
                  <IconButton sx={{ padding: '0' }}>
                    <Verified />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {vaultDetails?.vaultSource === 'drip' ? (
            <Box textAlign='right'>
              <Typography variant='body2' fontWeight='500'>
                {`${vaultDetails?.farmRewards}`}
              </Typography>
              <Typography
                variant='caption'
                color='rgba(33, 35, 34, 0.7)'
                component={Box}
                display='flex'
                justifyContent='flex-end'
                gap={0.5}
              >
                <span>
                  {t('pages.smartVaults.partners.drip.pointsSubheader')}
                </span>
                <Tooltip
                  onClick={(event) => event.stopPropagation()}
                  title={
                    <Box
                      sx={{ color: 'black' }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <TextTagRenderer
                        inputString={t(
                          'pages.smartVaults.partners.drip.tooltip'
                        )}
                      />
                    </Box>
                  }
                  sx={{ alignSelf: 'center' }}
                >
                  <InfoIcon
                    className='sm'
                    sx={{ alignSelf: 'center', color: 'primary.main' }}
                  />
                </Tooltip>
                {incentivizedRewards?.rewards
                  .filter((reward) => +reward.apy > 0.01)
                  .map((reward, index) => (
                    <span key={index}>
                      {' + '}
                      {reward.asset.symbol} {formatUSD(reward.apy)}%{' '}
                    </span>
                  ))}
              </Typography>
            </Box>
          ) : (
            <Box display='flex' justifyContent='right'>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      minWidth: '400px',
                    },
                  },
                }}
                title={
                  <ApyTooltip
                    performanceFee={Number(fees?.performanceFeePercentage ?? 0)}
                    managementFee={Number(fees?.managementFeePercentage ?? 0)}
                    depositFee={Number(fees?.depositFeePercentage ?? 0)}
                    baseApy={Number(baseApy)}
                    adjustedApy={Number(adjustedApy)}
                    incentivizedRewardsApy={Number(
                      incentivizedRewards?.totalApy ?? 0
                    )}
                  />
                }
                arrow
              >
                <Box textAlign='right'>
                  <Typography variant='body2' fontWeight='500'>
                    {formatUSD(adjustedApy)} %
                  </Typography>
                  <Typography variant='caption' color='rgba(33, 35, 34, 0.7)'>
                    {`Base ${formatUSD(baseApy)}%`}
                    {incentivizedRewards?.rewards
                      .filter((reward) => +reward.apy > 0.01)
                      .map((reward, index) => (
                        <span key={index}>
                          {' + '}
                          {reward.asset.symbol} {formatUSD(reward.apy)}%{' '}
                        </span>
                      ))}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
        </TableCell>
        {/*<TableCell align='right'>
          <Box display='flex' justifyContent='right' gap={0.5}>
            <ServiceSyncWarning address={address} />
            <Typography variant='body2'>
              {currency}{' '}
              {formatUSD(incentivizedRewards.totalClaimableAmountUSD, 2)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align='right'>
          <Box
            display='flex'
            justifyContent='right'
            alignItems='center'
            sx={{ position: 'relative' }}
          >
            <Typography variant='body2'>
              {Number(balance.total) === 0
                ? '-'
                : `${currency} ${formatUSD(balance.total, 2)}`}
            </Typography>
            {(Number(pendingDeposits.totalUSD ?? '0') > 0 ||
              Number(pendingWithdrawals.total ?? '0') > 0) && (
              <Tooltip
                title={
                  <BalanceTooltip
                    pendingDeposits={pendingDeposits.totalUSD ?? '0'}
                    pendingWithdrawals={pendingWithdrawals.total ?? '0'}
                  />
                }
                arrow
              >
                <CurrencyExchangeIcon
                  className='sm'
                  sx={{
                    color: 'primary.light',
                    position: 'absolute',
                    marginRight: -3,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </TableCell>*/}
        <TableCell align='right'>
          <Typography variant='body2'>
            {currency} {formatUSD(totalVolumeRouted.total, 2)}
          </Typography>
        </TableCell>
        <TableCell padding='none' />
        {/*<TableCell align='center'>
          {showRiskModel ? (
            <>
              {formatNumber(riskScore, 1)}/10
              <br />
            </>
          ) : (
            <>{NOT_AVILABLE}</>
          )}
        </TableCell>*/}
        {/* <TableCell align='right'>
          <Box>
            {conditions.length > 0 ? (
              <Tooltip
                title={<ConditionsTooltip conditions={conditions} />}
                arrow
                placement='left'
              >
                <IconButton sx={{ padding: '4px' }} disableRipple>
                  <InfoIcon className='lg' color='primary' />
                </IconButton>
              </Tooltip>
            ) : (
              '-'
            )}
          </Box>
        </TableCell> */}
      </TableRow>
    </>
  )
}

export default memo(SmartVaultTableRow)
