import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import SectionItem from '@/components/molecules/SectionItem'
import AmountTextUSD from '@/components/organisms/Staking/AmountTextUSD'

import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

const NextVoSpoolEpochRewards = () => {
  const t = useLocale()
  const { account } = useWeb3React()

  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.epochRewards.title'
      )}
    >
      <Box display='flex' alignItems='baseline' justifyContent='space-between'>
        <Box display='flex' alignItems='baseline'>
          <Typography variant='h6'>
            {formatUSD(
              rewardDetails?.nextVoSpoolEpochRewards?.spoolRewards ?? 0
            )}
          </Typography>
          <Typography variant='body1'>&nbsp;{Token.SPOOL}</Typography>
        </Box>
        <Box display='flex' alignItems='baseline'>
          <Typography variant='h6'>
            {formatUSD(
              rewardDetails?.nextVoSpoolEpochRewards?.voSpoolRewards ?? 0
            )}
          </Typography>
          <Typography variant='body1'>&nbsp;{Token.VOSPOOL}</Typography>
        </Box>
      </Box>
      <AmountTextUSD
        amount={rewardDetails?.nextVoSpoolEpochRewards?.spoolRewards ?? '0'}
      />
    </SectionItem>
  )
}

export default NextVoSpoolEpochRewards
