import { JsonRpcProvider } from '@ethersproject/providers'
import { SpoolSdk, SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { memo, useEffect, useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { API_ENDPOINTS } from '@/config/sdk'

import { RCNode } from '@/types/index'

export const AppProvider = memo(({ children }: RCNode) => {
  const { account, provider, connector } = useWeb3React()
  const { chain } = useConnectionState()

  // to connect default rpc to specified chain
  useEffect(() => {
    if (!account && !(connector instanceof GnosisSafe)) {
      connector.activate(chain)
    }
  }, [chain, account, connector])

  const tempProvider = new JsonRpcProvider(API_ENDPOINTS[chain].RPC_PROVIDER)

  const spoolSdk = useMemo(
    () =>
      new SpoolSdk(
        {
          coreSubgraph: API_ENDPOINTS[chain].CORE_SUBGRAPH,
          stakingSubgraph: API_ENDPOINTS[chain].STAKING_SUBGRAPH,
          apiUrl: API_ENDPOINTS[chain].API_URL,
          fastWithdrawApiUrl: API_ENDPOINTS[chain].FAST_WITHDRAW_API_URL,
          network: chain as SupportedNetworks,
          analyticsUrl: API_ENDPOINTS[chain].ANALYTICS,
          snapshot: {
            subgraph: API_ENDPOINTS[chain].SNAPSHOT_SUBGRAPH,
            space: API_ENDPOINTS[chain].SNAPSHOT_SPACE,
            hub: API_ENDPOINTS[chain].SNAPSHOT_HUB,
          },
          rewardsUrl: API_ENDPOINTS[chain].REWARDS_URL,
          priceFeedApiUrl: API_ENDPOINTS[chain].PRICE_FEED,
          contractAddresses: {
            IDepositSwapManager: API_ENDPOINTS[chain].DEPOSIT_SWAP_MANAGER,
            IRewardManager: API_ENDPOINTS[chain].REWARD_MANAGER,
            ISmartVaultFactory: API_ENDPOINTS[chain].SMART_VAULT_FACTORY,
            ISmartVaultManager: API_ENDPOINTS[chain].SMART_VAULT_MANAGER,
            ISpoolLens: API_ENDPOINTS[chain].SPOOL_LENS,
            ISpoolStaking: API_ENDPOINTS[chain].SPOOL_STAKING,
            IVoSpool: API_ENDPOINTS[chain].VO_SPOOL,
            LinearAllocationProvider:
              API_ENDPOINTS[chain].LINEAR_ALLOCATION_PROVIDER,
            OneInchRouter: API_ENDPOINTS[chain].ONE_INCH_ROUTER,
            SpoolToken: API_ENDPOINTS[chain].SPOOL_TOKEN,
            Swapper: API_ENDPOINTS[chain].SWAPPER,
            AllowlistGuard: API_ENDPOINTS[chain].ALLOW_LIST_GUARD,
            IGuardManager: API_ENDPOINTS[chain].IGUARD_MANAGER,
          },
        },
        account ? provider?.getSigner() : provider ? provider : tempProvider
      ),
    [account, provider, chain]
  )

  return (
    <AppContext.Provider value={{ sdk: spoolSdk }}>
      {children}
    </AppContext.Provider>
  )
})
