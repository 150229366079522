import { Box, Typography } from '@mui/material'
import GaugeChart from 'react-gauge-chart'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import ColumnlessGridRow from '@/components/molecules/ColumnlessGridRow'
import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

import { mapRiskModelConfig } from '@/constants/riskModels/riskModels'
import { formatUSD } from '@/utils/formats'

const RiskDetails = () => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { riskModel, riskScore } = smartVaultData

  const { chain } = useConnectionState()

  // ARB
  const riskModelConfig = mapRiskModelConfig(chain)

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.risk'
      )}
    >
      <StyledGridItem>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.riskScoreSmall.label'
          )}
        >
          <Box display='flex' justifyContent='center'>
            <GaugeChart
              nrOfLevels={3}
              percent={+smartVaultData.riskScore / 10}
              colors={['#4CAF50', '#2E7D32', '#FFB74D']}
              cornerRadius={0}
              hideText
              arcWidth={0.5}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '35px',
              }}
            />
            <Typography fontSize='14px'>
              {formatUSD(riskScore || 0, 1, 1)}/10
            </Typography>
          </Box>
        </DetailsRow>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.riskScoreProvider.label'
          )}
        >
          <Typography variant='body2' sx={{ width: '100%' }}>
            {riskModel
              ? riskModelConfig[riskModel.address]
                ? riskModelConfig[riskModel.address].riskModelName
                : ''
              : 'Spool Labs'}
          </Typography>
        </DetailsRow>
        <ColumnlessGridRow
          text={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.howRiskCalculated.label'
          )}
          link={
            riskModel
              ? riskModelConfig[riskModel.address]
                ? riskModelConfig[riskModel.address].riskProviderLink
                : ''
              : 'https://docs.spool.fi/genesis/genesis-risk-model'
          }
        />
      </StyledGridItem>
    </SectionColumn>
  )
}

export default RiskDetails
