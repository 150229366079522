import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  TabProps,
  Tabs,
} from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import SocialsMenuItem from '@/components/organisms/TopMenu/SocialsMenuItem'
import Version from '@/components/organisms/TopMenu/Version'

import { gaIds } from '@/constants/googleAnalyticIDs'
import { SubmenuItem } from '@/constants/subMenu'
import { TOP_MENU_ITEMS } from '@/constants/topMenu'

const LinkTab: React.ComponentType<TabProps & { to: string }> =
  Tab as React.ComponentType<TabProps & { to: string }>

const MenuTabs = () => {
  const { chain } = useConnectionState()

  const dropdownRef = useRef(null)

  const {
    belowTabletBreakpoint,
    belowPhabletBreakpoint,
    belowMobileBreakpoint,
  } = useBreakpointDetection()

  const { activeMenuItem, activeSubmenuItem, appMode } = useTopMenuState()
  const [dropdownOpen, setDropdownOpen] = useState<boolean>()
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLDivElement>()
  const { selectedVaultId } = useSmartVaultsTableState()

  const DEFAULT_B2B_TAB = useMemo(
    () => TOP_MENU_ITEMS(chain)['B2B'][0].title,
    [chain]
  )
  const DEFAULT_B2C_TAB = useMemo(
    () => TOP_MENU_ITEMS(chain)['B2C'][0].title,
    [chain]
  )
  const topMenuItems = useMemo(() => TOP_MENU_ITEMS(chain), [chain])

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    submenuItem: SubmenuItem[][]
  ) => {
    const { currentTarget } = event
    if (submenuItem) {
      setDropdownOpen(true)
      setAnchorEl(currentTarget)
      return
    }
    setDropdownOpen(false)
  }

  const dropdownMenuItem = useMemo(
    () =>
      topMenuItems[appMode].find((menuItem) => {
        if (anchorEl) {
          // regex here due to ios adding unknown characters to the string
          return menuItem.title === anchorEl.innerText.replace(/[^a-z]+/i, '')
        }
      }),
    [dropdownOpen, anchorEl]
  )

  const handleDropdownClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleDropdownClickOutside)
    }
  }, [dropdownOpen])

  return (
    <>
      <Tabs
        sx={{
          width: '100%',
          '.MuiTabs-flexContainer': {
            justifyContent: `${belowTabletBreakpoint ? 'space-evenly' : ''} `,
          },
        }}
        value={
          selectedVaultId
            ? false
            : activeMenuItem !== undefined && activeMenuItem !== ''
            ? activeMenuItem
            : appMode === 'B2C'
            ? DEFAULT_B2C_TAB
            : DEFAULT_B2B_TAB
        }
        indicatorColor='primary'
      >
        {topMenuItems[appMode].map(({ title, url, submenuSections }, index) => {
          return (
            <LinkTab
              onMouseOver={(event) => {
                if (!belowTabletBreakpoint) {
                  handleMenuOpen(event, submenuSections)
                }
              }}
              onClick={(event) => {
                if (belowTabletBreakpoint) {
                  handleMenuOpen(event, submenuSections)
                }
              }}
              sx={{
                textTransform: 'none',
                opacity: '1',
                '.MuiTab-iconWrapper': {
                  marginLeft: '0',
                  color: 'gray',
                },
                '&.MuiButtonBase-root': {
                  padding: `${belowPhabletBreakpoint ? '0px 0px' : '0 1rem'}`,
                },
                fontSize: belowMobileBreakpoint ? '14px' : '16px',
                minWidth: belowMobileBreakpoint ? '80px' : '90px',
              }}
              id={gaIds.topNav}
              component={Link}
              to={submenuSections ? '#' : url}
              key={index}
              value={title}
              label={title}
              icon={submenuSections && <ExpandMoreIcon />}
              iconPosition='end'
            />
          )
        })}
      </Tabs>
      {dropdownOpen && (
        <Popper
          ref={dropdownRef}
          open={dropdownOpen}
          anchorEl={anchorEl}
          onMouseLeave={() => setDropdownOpen(false)}
          placement='bottom-start'
          id='menu-list-grow'
          sx={{
            width: `${belowPhabletBreakpoint ? '200px' : '315px'}`,
            zIndex: 1001,
          }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [16, -16],
              },
            },
          ]}
        >
          <Paper
            elevation={4}
            sx={{
              borderRadius: (theme) => theme.spacing(1),
              overflow: 'hidden',
              pb: 1,
            }}
          >
            <MenuList>
              {dropdownMenuItem?.submenuSections?.map(
                (section, sectionIndex) => {
                  return (
                    <Box
                      key={sectionIndex}
                      display='flex'
                      flexDirection='column'
                      borderBottom={
                        sectionIndex !==
                          dropdownMenuItem.submenuSections.length - 1 &&
                        '1.5px solid #e4e4e4'
                      }
                    >
                      {section
                        .filter(
                          ({ mainnetOnly }) =>
                            chain === SupportedNetworks.ETHEREUM || !mainnetOnly
                        )
                        .map(({ title, newTab, url }, index) => {
                          return (
                            <MenuItem
                              sx={{
                                height: '40px',
                                '&:hover': {
                                  color: '#006BA6',
                                  backgroundColor: '#fff',
                                },
                                color: activeSubmenuItem === title && '#006BA6',
                                ml: (theme) => theme.spacing(1),
                                mt:
                                  index === 0 && ((theme) => theme.spacing(1)),
                                mb:
                                  sectionIndex !==
                                    dropdownMenuItem.submenuSections.length -
                                      1 &&
                                  index === section.length - 1 &&
                                  ((theme) => theme.spacing(1)),
                              }}
                              component={Link}
                              to={url}
                              target={newTab && '_blank'}
                              key={title}
                            >
                              {title}
                            </MenuItem>
                          )
                        })}
                    </Box>
                  )
                }
              )}
              {dropdownMenuItem?.social && <SocialsMenuItem />}
              {dropdownMenuItem?.version && <Version />}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  )
}

export default MenuTabs
