import { ApiTransactionType } from '@solidant/spool-v2-fe-lib'

import { HeadCell } from '@/types/table'

export const investorDashboardHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Vault',
    align: 'left',
    notSortable: true,
  },
  {
    id: 'balance',
    numeric: true,
    label: 'Deposit',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'rewards',
    numeric: true,
    label: 'Incentives',
    align: 'right',
    notSortable: true,
  },
]

export const creatorDashboardHeadCells: HeadCell[] = [
  {
    id: 'Vault',
    numeric: false,
    label: 'Name',
    notSortable: true,
  },
  {
    id: 'adjustedApy',
    numeric: true,
    tableHelperTop: 'APY',
    label: 'after fees',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'deposits',
    numeric: true,
    label: 'TVR',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'performanceFees',
    numeric: true,
    label: 'Performance',
    tableHelperBottom: ' Fees',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'managementFees',
    numeric: true,
    label: 'Management',
    tableHelperBottom: 'Fees',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'availableToWithdraw',
    numeric: true,
    tableHelperTop: 'Available',
    label: 'to withdraw',
    align: 'right',
    notSortable: true,
  },
  {
    id: '',
    numeric: true,
    label: 'Action',
    align: 'center',
    notSortable: true,
  },
  {
    id: 'option',
    numeric: true,
    label: '',
    align: 'center',
    notSortable: true,
  },
]

export const creatorDashboardRewardsHeadCells: HeadCell[] = [
  {
    id: 'incentiveToken',
    numeric: false,
    label: 'Incentive Token',
    notSortable: true,
  },
  {
    id: 'remainingAmount',
    numeric: true,
    label: 'Amount left to be distributed',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'remainingDays',
    numeric: true,
    label: 'Count of days left',
    align: 'right',
    notSortable: true,
  },
  {
    id: 'status',
    numeric: true,
    label: 'Status',
    align: 'center',
    width: '120px',
    notSortable: true,
  },
]

export const investorStrategiesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Strategy',
  },
  {
    id: 'apy',
    numeric: true,
    label: '7-days hist. APY',
    align: 'right',
  },
  {
    id: 'amountInUSD',
    numeric: true,
    label: 'Allocated Amount',
    align: 'right',
  },
  {
    id: 'amountInUSDPercentage',
    numeric: true,
    label: 'Allocation',
  },
]

export const creatorStrategiesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Strategy',
  },
  {
    id: 'apy',
    numeric: true,
    label: '7 days hist. APY',
    align: 'right',
  },
  {
    id: 'amountInUSD',
    numeric: true,
    label: 'Allocation Amount',
    align: 'right',
  },
  {
    id: 'amountInUSDPercentage',
    numeric: true,
    label: 'Allocation',
  },
]

export const transactionType: Record<ApiTransactionType, string> = {
  [ApiTransactionType.DEPOSIT_INITIATED]: 'Deposit Initiated',
  [ApiTransactionType.DEPOSIT_SETTLED]: 'Deposit Settled',
  [ApiTransactionType.DNFT_TRANSFER]: 'dNFT Transfer',
  [ApiTransactionType.SVT_TRANSFER]: 'SVT Transfer',
  [ApiTransactionType.WITHDRAW_INITIATED]: 'Withdraw Initiated',
  [ApiTransactionType.WITHDRAW_SETTLED]: 'Withdraw Settled',
  [ApiTransactionType.WNFT_TRANSFER]: 'wNFT Transfer',
}

export const COLOR_CODE = [
  '#9baff4',
  '#6183f7',
  '#3a67ff',
  '#8b3aff',
  '#54caf1',
]
