import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useAccumulatedVoSpool from '@/hooks/staking/useAccumulatedVoSpool'

//import useStakedSpool from '@/hooks/staking/useStakedSpool'
//import LinearProgressWithLabel from '@/components/atoms/LinearProgressWithLabel'
import SectionItem from '@/components/molecules/SectionItem'

import { Token } from '@/constants/smartVault'
import { formatUSD } from '@/utils/formats'

const AccumulatedVoSpool = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  //const { stakedSpool, isLoading: isLoadingStaked } = useStakedSpool(account)
  const { accumulatedVoSpool, isLoading: isLoadingAccumulated } =
    useAccumulatedVoSpool(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.overview.sectionItems.accumulatedVospool.title'
      )}
      sx={{ width: '100%' }}
    >
      {/*<Box display='flex' alignItems='center'>*/}
      <Box display='flex' alignItems='baseline'>
        {/*<Typography variant='body1'>
            {!isLoadingAccumulated ? formatUSD(accumulatedVoSpool) : '0'}
          </Typography>
          <Typography variant='body1'>
            &nbsp;
            {t(
              'components.organisms.staking.overview.sectionItems.accumulatedVospool.content'
            )}
            &nbsp;
            {!isLoadingStaked ? formatUSD(stakedSpool) : '0'}
          </Typography>*/}
        <Typography variant='h6'>
          {!isLoadingAccumulated ? formatUSD(accumulatedVoSpool) : '0'}
        </Typography>
        <Typography variant='body1'>&nbsp;{Token.VOSPOOL}</Typography>
      </Box>
      <Typography variant='body1'>&nbsp;</Typography>

      {/*<Box width='100%'>
          <LinearProgressWithLabel
            value={
              +accumulatedVoSpool && +stakedSpool
                ? (+accumulatedVoSpool / +stakedSpool) * 100
                : 0
            }
          />
        </Box>*/}
      {/*</Box>*/}
    </SectionItem>
  )
}

export default AccumulatedVoSpool
