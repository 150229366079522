import { Box, Typography } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
//import { Link } from 'react-router-dom'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { TopMenuContainer } from '@/components/atoms/Navigation/TopMenuContainer'
import MenuTabs from '@/components/organisms/TopMenu/MenuTabs'
import WalletConnectionMenu from '@/components/organisms/TopMenu/WalletConnectionMenu'

import SpoolLogo from '@/assets/icons/general/SpoolLogo'

import { Routes } from '@/constants/routes'
import { AppMode, mainnetOnlyRoutes, TOP_MENU_ITEMS } from '@/constants/topMenu'

const TopMenu = () => {
  const { appMode, setActiveMenuItem, setActiveSubmenuItem, setActiveTab } =
    useTopMenuState()
  const navigate = useNavigate()
  const { pathname } = useUniversalNavigation()
  const { chain } = useConnectionState()

  const { belowTabletBreakpoint } = useBreakpointDetection()

  const topMenuItems = useMemo(() => TOP_MENU_ITEMS(chain), [chain])

  const mainnetRoutes = useMemo(() => mainnetOnlyRoutes(chain), [chain])

  useEffect(() => {
    mainnetRoutes.forEach((route) => {
      if (chain !== SupportedNetworks.ETHEREUM && pathname.includes(route)) {
        navigate(Routes(chain).smartVaults.root.url)
      }
    })
  }, [pathname, chain, mainnetRoutes])

  useEffect(() => {
    const findAndSetMenuItems = () => {
      const menuItems = topMenuItems[appMode]

      for (const menuItem of menuItems) {
        // Check for tabs
        if (menuItem.tabs) {
          for (const tab of menuItem.tabs) {
            if (pathname.startsWith(tab.url)) {
              setActiveTab(tab.title)
              setActiveMenuItem(menuItem.title)
              return
            }
          }
        } else {
          setActiveTab('')
        }

        // Check for dropdown menu sections
        if (menuItem.submenuSections) {
          for (const section of menuItem.submenuSections) {
            for (const subItem of section) {
              if (location.pathname.startsWith(subItem.url)) {
                setActiveSubmenuItem(subItem.title)
                setActiveMenuItem(menuItem.title)
                return
              }
            }
          }
        } else {
          setActiveSubmenuItem('')
        }

        // Check if only top-level menu item is the match
        if (pathname.startsWith(menuItem.url)) {
          setActiveMenuItem(menuItem.title)
          return
        }
      }
      setActiveMenuItem('')
    }

    findAndSetMenuItems()
  }, [pathname, appMode, chain])

  const handleClick = () => {
    const homeItem = topMenuItems[appMode][0]
    navigate(homeItem.url)
  }

  return useMemo(
    () => (
      <TopMenuContainer>
        <Box display='flex' alignItems='center'>
          <Box
            //component={Link}
            //to='/'
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
            fontSize={0}
            display='flex'
            mr='1rem'
            flexDirection='column'
            alignItems='flex-end'
          >
            <SpoolLogo />
          </Box>
          {appMode === AppMode.B2B && (
            <Typography
              color='primary'
              component={Box}
              sx={{
                border: '1px solid',
                borderColor: 'primary',
                borderRadius: '2px',
                px: '8px',
                ml: '8px',
                mr: '16px',
              }}
              variant='badgeLabel'
            >
              Manager
            </Typography>
          )}
          {!belowTabletBreakpoint && <MenuTabs />}
        </Box>
        <WalletConnectionMenu />
      </TopMenuContainer>
    ),
    [chain, belowTabletBreakpoint]
  )
}

export default TopMenu
