import { parseEther } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'

import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useModalState from '@/hooks/context/useModalState'
import useStakedSpool from '@/hooks/staking/useStakedSpool'
import useUserBalance from '@/hooks/web3/useUserBalance'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { tokenAddresses } from '@/constants/tokens'

const useStakeOrUnstake = (action: string) => {
  const { sdk } = useAppState()
  const { chain } = useConnectionState()

  const { openModal, setModalType } = useModalState()

  const { account } = useWeb3React()

  const { stakedSpool, updateStakedSpool } = useStakedSpool(account)

  const { updateUserBalance } = useUserBalance(tokenAddresses[chain].SPOOL)

  const stakeOrUnstake = async (amount: string) => {
    try {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.Stake,
        },
      })
      openModal(Modals.ACTION_MODAL)

      if (action === 'stake') {
        const tx = await sdk.staking.stake(parseEther(amount).toString())
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Processing,
            transactionType: TransactionType.Stake,
          },
        })
        await tx.wait()
      } else {
        const tx = await sdk.staking.unstake(parseEther(amount).toString())
        setModalType({
          actionModalType: {
            transactionStatus: TransactionStatus.Processing,
            transactionType: TransactionType.Stake,
          },
        })
        await tx.wait()
      }

      updateStakedSpool(stakedSpool)

      updateUserBalance()

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.Stake,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.Stake,
        },
      })
    }
  }

  return stakeOrUnstake
}

export default useStakeOrUnstake
