import Stack from '@mui/material/Stack'
import { useEffect } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useUniversalNavigation from '@/hooks/context/useUniversalNavigation'
import useSteps from '@/hooks/createVault/useSteps'

import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import CreateVaultLayout from '@/components/organisms/CreateVault/CreateVaultLayout'
import CreateVaultRenderStep from '@/components/organisms/CreateVault/CreateVaultRenderStep'

import { DEFAULT_ALLOCATION_PROVIDER } from '@/constants/smartVault'

const CreateVault = () => {
  const { chain } = useConnectionState()

  const {
    currentStep,
    setCurrentStep,
    resetAllSelectedFields,
    setSelectedAllocationProvider,
  } = useCreateVaultState()
  const steps = useSteps()

  const { setPathWithHistory } = useUniversalNavigation()

  useEffect(() => {
    return () => resetAllSelectedFields()
  }, [])

  useEffect(() => {
    setPathWithHistory(steps[currentStep - 1].urlSubpath)
  }, [currentStep])

  useEffect(() => {
    setSelectedAllocationProvider(DEFAULT_ALLOCATION_PROVIDER[chain])
  }, [])

  useEffect(() => {
    if (currentStep !== 1) {
      setCurrentStep(1)
      resetAllSelectedFields()
      setSelectedAllocationProvider(DEFAULT_ALLOCATION_PROVIDER[chain])
    }
  }, [chain])

  return (
    <Stack sx={{ gap: (theme) => theme.spacing(2) }}>
      <SpoolBreadcrumbs>
        <>
          <CreateVaultRenderStep step={currentStep} type='description' />
          <CreateVaultLayout />
        </>
      </SpoolBreadcrumbs>
    </Stack>
  )
}

export default CreateVault
