import { Box, Stack, SxProps, Theme, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

interface SectionColumnProps extends PropsWithChildren {
  title?: string
  subtitle?: string
  sx?: SxProps<Theme>
  actionButtons?: React.ReactNode
  noPadding?: boolean
  noGap?: boolean
  noVerticalGap?: boolean
  titleCenter?: boolean
  fullHeight?: boolean
}

const SectionColumn: React.FC<SectionColumnProps> = ({
  title,
  subtitle,
  sx,
  children,
  actionButtons,
  noPadding,
  noVerticalGap,
  noGap,
  titleCenter,
  fullHeight,
}) => (
  <Stack
    width='100%'
    gap={noVerticalGap ? 0 : (theme) => theme.spacing(2)}
    paddingTop={(theme) => (title ? theme.spacing(1) : theme.spacing(2))}
    padding={(theme) => (noPadding ? 0 : theme.spacing(2))}
    sx={sx}
  >
    <Box
      justifyContent='space-between'
      justifyItems='center'
      textAlign={titleCenter ? 'center' : undefined}
    >
      {title && <Typography variant='overline'>{title}</Typography>}
      {subtitle && <Typography variant='body1'>{subtitle}</Typography>}
      {actionButtons}
    </Box>
    <Stack
      gap={noGap ? 0 : (theme) => theme.spacing(2)}
      sx={{ height: fullHeight ? '100%' : 'auto' }}
    >
      {children}
    </Stack>
  </Stack>
)

export default SectionColumn
