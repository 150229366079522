import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useAccumulatedVoSpool = (userAddress: string) => {
  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk && userAddress ? ['accumulatedVoSpool', userAddress] : null,
    async () => await sdk.staking.getAccumulatedVoSpool(userAddress)
  )

  if (error) {
    console.error(error)
  }

  return {
    accumulatedVoSpool: data,
    isLoading: !error && !data,
    error,
  }
}

export default useAccumulatedVoSpool
