import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import mainnet from '@/config/mainnet/ethereum.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const ETHEREUM_STAGING_CONFIG = {
  RPC_PROVIDER: 'https://cloudflare-eth.com/',
  CORE_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2/api',
  API_URL: 'https://api.dev.spool.fi/mainnet',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.v2.spool.fi',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  REWARDS_URL: 'https://rewards.v2.spool.fi/mainnet',
  PRICE_FEED: 'https://pricefeed.v2.spool.fi/',
  SMART_VAULT_MANAGER: mainnet.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: mainnet.DepositSwap.proxy,
  SMART_VAULT_FACTORY: mainnet.SmartVaultFactory,
  REWARD_MANAGER: mainnet.RewardManager.proxy,
  SPOOL_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  VO_SPOOL: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: mainnet.SpoolLens.proxy,
  SPOOL_TOKEN: tokenAddresses[SupportedNetworks.ETHEREUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: mainnet.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x111111125421ca6dc452d289314280a0f8842a65',
  SWAPPER: mainnet.Swapper.proxy,
  ALLOW_LIST_GUARD: mainnet.AllowlistGuard,
  IGUARD_MANAGER: mainnet.GuardManager.proxy,
}

export const ETHEREUM_FLUSHES = {
  WITHDRAW: false,
  SWAP_AND_DEPOSIT: false,
  SINGLE_SWAP_DEPOSIT: false,
  MULTI_SWAP_DEPOSIT: false,
  DEPOSIT: false,
}

export const ETHEREUM_FEATURES = {
  CREATE_VAULT_ADVANCED: true,
  ARBITRUM_ONE_NET: false,
  FAST_WITHDRAW: true,
  MULTI_SWAP_DEPOSIT: false,
}
