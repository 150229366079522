import useAppState from '@/hooks/context/useAppState'
import useConnectionState from '@/hooks/context/useConnectionState'
import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { tokenAddresses } from '@/constants/tokens'

const useClaimStakingRewards = () => {
  const { chain } = useConnectionState()

  const { openModal, setModalType } = useModalState()
  const { sdk } = useAppState()

  const claimStakingRewards = async () => {
    try {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ClaimToken,
        },
      })
      openModal(Modals.ACTION_MODAL)

      const tx = await sdk.staking.claimRewards(
        [tokenAddresses[chain].SPOOL],
        true
      )
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ClaimToken,
        },
      })
      await tx.wait()

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ClaimToken,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ClaimToken,
        },
      })
    }
  }

  return claimStakingRewards
}

export default useClaimStakingRewards
