import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import getLocale from '@/locales/getLocale'

import { IS_PUBLIC_TESTNET, IS_STAGING, IS_TESTNET } from '@/config/sdk'

import { HeadCell, SmartVaultHeadCell } from '@/types/table'

const t = getLocale()

type Align = 'left' | 'right' | 'center'

export const headCells: SmartVaultHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Smart Vault',
    align: 'left',
  },
  {
    id: 'adjustedApy',
    numeric: true,
    label: 'Total APY after fees',
    align: 'right',
  },
  /*  {
    id: 'claimableRewards',
    numeric: true,
    label: 'Claimable',
    tableHelperBottom: 'rewards',
    align: 'right',
  },
  {
    id: 'userBalance',
    numeric: true,
    label: 'Balance',
    align: 'right',
  },*/
  {
    id: 'tvr',
    numeric: true,
    label: 'TVR',
    align: 'right',
    infoTitle:
      'TVR is a concept refined and utilised in Spool V1 which has continued to Spool v2.',
    infoText:
      'It represents "Total Value Routed" as an alternative to “Total Value Locked” (TVL) as seen with other protocols. Spool does not lock the funds when you send them to a Smart Vault, it merely routes them to the underlying strategies. In this way, the user stays in control at all times, and at no point are your funds under custodial management.',
  },
  /*{
    id: 'riskScore',
    numeric: true,
    label: 'Risk score',
    align: 'right',
    notSortable: true,
    infoTitle: t('components.organisms.tooltips.riskScoreTooltip.text'),
  },*/
  // {
  //   id: 'conditions',
  //   numeric: false,
  //   label: 'Conditions',
  //   tableHelperTop: 'Entry & Exit',
  //   notSortable: true,
  //   align: 'center',
  // },
]

export const strategiesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Strategy',
    align: 'left',
    width: '400px',
  },
  {
    id: 'apy',
    numeric: true,
    label: '7d APY',
    align: 'right',
    width: '50px',
  },
  {
    id: 'allocation',
    numeric: true,
    label: 'Allocation',
    align: 'right',
    width: '100px',
  },
  {
    id: 'riskScore',
    numeric: true,
    label: 'Risk score',
    align: 'right',
    width: '50px',
    infoTitle: t('components.organisms.tooltips.riskScoreTooltip.text'),
  },
  // {
  //   id: 'auditor',
  //   numeric: true,
  //   label: 'Auditor',
  //   align: 'right',
  //   width: '100px',
  // },
]

export const guardConditionsHeadCells: HeadCell[] = [
  {
    id: 'conditions',
    numeric: false,
    label: 'Conditions',
    align: 'left',
  },
  {
    id: 'fulfilled',
    numeric: false,
    label: 'Fulfilled',
    align: 'center',
    width: '100px',
  },
]

export const withdrawReceiptHeadCells: {
  id: string
  numeric: boolean
  label: string
  align?: Align
}[] = [
  {
    id: 'createdOn',
    numeric: false,
    label: 'Date',
  },
  {
    id: 'withdrawAmount',
    numeric: true,
    label: 'Amount',
    align: 'right',
  },
  {
    id: 'wnftId',
    numeric: true,
    label: 'Status',
    align: 'center',
  },
]

export const depositReceiptHeadCells: {
  id: string
  numeric: boolean
  label: string
  align?: Align
}[] = [
  {
    id: 'createdOn',
    numeric: false,
    label: 'Date',
  },
  {
    id: 'depositedAmount',
    numeric: true,
    label: 'Amount',
    align: 'right',
  },
  {
    id: 'dnftId',
    numeric: true,
    label: 'Status',
    align: 'center',
  },
]

export const smartVaultsRewardsHeadCells: {
  id: string
  numeric: boolean
  label: string
  tableHelperTop?: string
  tableHelperBottom?: string
  withCheckbox?: boolean
  align?: 'left' | 'right' | 'center'
  width?: string
  infoTitle?: string
  infoText?: string
}[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'adjustedApy',
    numeric: true,
    label: 'Base APY',
    align: 'right',
  },
  {
    id: 'tvr',
    numeric: true,
    label: 'TVR',
    align: 'right',
    infoTitle:
      'TVR is a concept refined and utilised in Spool V1 which has continued to Spool v2.',
    infoText:
      'It represents "Total Value Routed" as an alternative to “Total Value Locked” (TVL) as seen with other protocols. Spool does not lock the funds when you send them to a Smart Vault, it merely routes them to the underlying strategies. In this way, the user stays in control at all times, and at no point are your funds under custodial management.',
  },
  {
    id: 'smartVaultFees__performanceFeeMinted',
    numeric: true,
    label: 'Earned',
    tableHelperBottom: 'from performance fees',
    align: 'right',
    width: '250px',
  },
  {
    id: 'smartVaultFees__managementFeeMinted',
    numeric: true,
    label: 'Earned',
    tableHelperBottom: 'from management fees',
    align: 'right',
    width: '250px',
  },
]

export enum SmartVaultPage {
  DETAILS = 'details',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  CLAIM = 'claim',
}

export enum WithdrawMode {
  STANDARD = 'standard',
  FAST = 'fast',
  STANDARD_CONVERT = 'standard/convert',
  FAST_CONVERT = 'fast/convert',
}

export enum MetricsMode {
  STANDARD = 'standard',
  ADVANCED = 'advanced',
}

export enum VaultsListAction {
  CANCEL = null,
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  CLAIM = 'claim',
}

export enum Token {
  DAI = 'DAI',
  USDC = 'USDC',
  USDT = 'USDT',
  BTC = 'BTC',
  ETH = 'ETH',
  SPOOL = 'SPOOL',
  VOSPOOL = 'voSPOOL',
}

// For multiAssetDeposits fixed decimals
export const INPUT_FIXED_DECIMALS = 2

// For allocation provider
export const DEFAULT_ALLOCATION_PROVIDER = IS_TESTNET
  ? IS_PUBLIC_TESTNET
    ? {
        [SupportedNetworks.SEPOLIA]:
          '0xbd330aecd16d1ff0db8fba6af9838054d194a9c6',
      }
    : IS_STAGING
    ? {
        [SupportedNetworks.ETHEREUM]:
          '0x7dbadf3cc5c9c7177b6f976910ef84ad90e018a8',
        [SupportedNetworks.ARBITRUM]:
          '0xb103cd88a0c64f81233f3050b196c14692f2bad9',
      }
    : {
        [SupportedNetworks.ETHEREUM]:
          '0x4e1157b40e62782f04d7f4a915f340b8f445b008',
        [SupportedNetworks.ARBITRUM]:
          '0x44a4199ce697c27e7e162df2ac9ffe8d45be7b9b',
      }
  : {
      [SupportedNetworks.ETHEREUM]:
        '0x7dbadf3cc5c9c7177b6f976910ef84ad90e018a8',
      [SupportedNetworks.ARBITRUM]:
        '0xb103cd88a0c64f81233f3050b196c14692f2bad9',
    }

// For Rewards Icon key
export const CLAIM_INCENTIVIZED_REWARDS_TIME = 'claimIncentivizedRewardsTime-'
export const REWARDS_SYNC_DURING_CLAIM = 'rewardsSyncTimeDuringClaim-'
