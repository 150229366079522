import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'

import sepolia from '@/config/testnet/sepolia.contracts.json'
import { tokenAddresses } from '@/constants/tokens'

export const SEPOLIA_CONFIG = {
  RPC_PROVIDER: 'https://sepolia.gateway.tenderly.co/51xvd3NjqSCwdOXr63AGCL',
  CORE_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2-sepolia/api',
  API_URL: 'https://api.dev.spool.fi/sepolia',
  FAST_WITHDRAW_API_URL: 'https://fastwithdraw.dev.spool.fi/sepolia',
  STAKING_SUBGRAPH:
    'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spoolfi-staking/api',
  ANALYTICS: 'https://analytics.spool.fi',
  SNAPSHOT_SUBGRAPH: 'https://hub.snapshot.org/graphql',
  SNAPSHOT_HUB: 'https://hub.snapshot.org',
  SNAPSHOT_SPACE: 'egov.spool.eth',
  REWARDS_URL: 'https://rewards.dev.spool.fi/sepolia',
  PRICE_FEED: 'https://pricefeed.dev.spool.fi/',
  SMART_VAULT_MANAGER: sepolia.SmartVaultManager.proxy,
  DEPOSIT_SWAP_MANAGER: sepolia.DepositSwap.proxy,
  SMART_VAULT_FACTORY: sepolia.SmartVaultFactory,
  REWARD_MANAGER: sepolia.RewardManager.proxy,
  SPOOL_STAKING: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
  VO_SPOOL: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
  SPOOL_LENS: sepolia.SpoolLens.proxy,
  SPOOL_TOKEN: tokenAddresses[SupportedNetworks.ARBITRUM]['SPOOL'],
  LINEAR_ALLOCATION_PROVIDER: sepolia.LinearAllocationProvider.proxy,
  ONE_INCH_ROUTER: '0x111111125421ca6dc452d289314280a0f8842a65',
  SWAPPER: sepolia.Swapper.proxy,
  ALLOW_LIST_GUARD: sepolia.AllowlistGuard,
  IGUARD_MANAGER: sepolia.GuardManager.proxy,
}
