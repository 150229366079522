import WarningIcon from '@mui/icons-material/Warning'
import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useInvestorDashboard from '@/hooks/dashboard/useInvestorDashboard'

import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import UnconnectedWalletDisplay from '@/components/atoms/UnconnectedWalletDisplay'
import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import InvestorAllocations from '@/components/organisms/Dashboard/Investor/InvestorAllocations'
import InvestorMetrics from '@/components/organisms/Dashboard/Investor/InvestorMetrics'
import InvestorVaultTable from '@/components/organisms/Dashboard/Investor/InvestorVaultsTable/InvestorVaultTable'
import ZeroDataDisplay from '@/components/organisms/Dashboard/ZeroDataDisplay'

import { Routes } from '@/constants/routes'

const InvestorDashboard = () => {
  const t = useLocale()
  const { isLoading, error, metrics, investorDashboard } =
    useInvestorDashboard()
  const { account } = useWeb3React()
  const { chain } = useConnectionState()

  return (
    <>
      <SpoolBreadcrumbs breadcrumb={['Deposit Dashboard']} mode='injected' />
      {!account ? (
        <UnconnectedWalletDisplay />
      ) : error ? (
        <Box
          my='2rem'
          mb='4rem'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <WarningIcon sx={{ height: '90px', width: '90px' }} color='primary' />
          <DataMissing text='An Error Occurred...' />
        </Box>
      ) : !investorDashboard && isLoading ? (
        <DataLoading loadingText='Loading portfolio data' />
      ) : !!+metrics?.depositedAmount || !!+metrics?.pendingDeposits ? (
        <>
          <InvestorMetrics />
          <InvestorVaultTable />
          <InvestorAllocations />
        </>
      ) : (
        <ZeroDataDisplay
          displayMessage={t(
            'components.organisms.dashboard.investor.investorZeroData.text'
          )}
          buttonText={t(
            'components.organisms.dashboard.investor.investorZeroData.buttonText'
          )}
          navigationRoute={`${Routes(chain).smartVaults.root.url}`}
        />
      )}
    </>
  )
}

export default InvestorDashboard
