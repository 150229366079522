import useAppState from '@/hooks/context/useAppState'
import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

const useClaimAndStakeStakingRewards = () => {
  const { openModal, setModalType } = useModalState()
  const { sdk } = useAppState()

  const claimAndStakeStakingRewards = async () => {
    try {
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.ClaimToken,
        },
      })
      openModal(Modals.ACTION_MODAL)

      const tx = await sdk.staking.compoundRewards(true)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.ClaimToken,
        },
      })
      await tx.wait()

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.ClaimToken,
        },
      })
    } catch (error) {
      console.error(error)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.ClaimToken,
        },
      })
    }
  }

  return claimAndStakeStakingRewards
}

export default useClaimAndStakeStakingRewards
