import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

import { RewardsTableSort } from '@/types/rewards'

const useCreatorFeeVaults = (
  page: number,
  limit: number,
  owner: string,
  creatorFeesSort: RewardsTableSort,
  vaultAddresses?: string[]
) => {
  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk
      ? ['smartVaults', page, limit, owner, vaultAddresses, creatorFeesSort]
      : null,
    async () =>
      await sdk.smartVault.getCreatorFeeVaults({
        offset: page * limit,
        limit,
        owner,
        vaultAddresses,
        sortBy: creatorFeesSort.key,
        sortOrder: creatorFeesSort.direction,
      })
  )

  if (error) {
    console.error(error)
  }

  return {
    creatorFeesVault: data?.creatorFeeVaults,
    totalCount: data?.totalCount,
    isLoading: !error && !data,
    error,
  }
}

export default useCreatorFeeVaults
