import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import type { Connector } from '@web3-react/types'
import { WalletConnect } from '@web3-react/walletconnect-v2'

import getLocale from '@/locales/getLocale'

import { GnosisSafe } from '@/web3-modules/gnosisSafe'

const t = getLocale()

export function getName(connector: Connector) {
  if (connector instanceof MetaMask) return t('utils.metaMask.label')
  if (connector instanceof WalletConnect) return t('utils.walletConnect.label')
  if (connector instanceof CoinbaseWallet)
    return t('utils.coinbaseWallet.label')
  if (connector instanceof Network) return t('utils.network.label')
  if (connector instanceof GnosisSafe) return t('utils.gnosisSafe.label')
  return t('utils.unknown.label')
}

export const debounce = (func: any, delay = 1000) => {
  let timeout: any

  return (...args: any) => {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, delay)
  }
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
  })
}
