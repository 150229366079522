import { Stack } from '@mui/material'
import { GovernanceDetails } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import useGovernanceAllocationState from '@/hooks/context/useGovernanceAllocationState'
import useLocale from '@/hooks/context/useLocale'
import useStakedSpool from '@/hooks/staking/useStakedSpool'

import CountdownTimer from '@/components/molecules/CountdownTimer'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'
import VoSpoolOverview from '@/components/organisms/Governance/VoSpoolOverview'

interface GovernanceOverviewProps {
  governanceDetails: GovernanceDetails
}

const GovernanceOverview: React.FC<GovernanceOverviewProps> = ({
  governanceDetails,
}) => {
  const t = useLocale()

  const { getTotalAllocation } = useGovernanceAllocationState()

  const { account } = useWeb3React()

  const { stakedSpool } = useStakedSpool(account)

  const proposalHasEnded = useMemo(
    () => governanceDetails?.endTime < dayjs().unix(),
    [governanceDetails]
  )

  return (
    <Section>
      <SectionColumn sx={{ gap: 0 }}>
        <Stack
          display='inline-grid'
          gridAutoColumns='minmax(0, 1fr)'
          gridAutoFlow='column'
          gap={2}
        >
          <VoSpoolOverview
            title={t(
              'components.organisms.governance.overview.accumulated.title'
            )}
            subtitle={t(
              'components.organisms.governance.overview.accumulated.subtitle'
            )}
            amount={
              Math.trunc(+governanceDetails?.userVotingPower).toString() ?? '0'
            }
            totalAmount={stakedSpool ?? '0'}
          />
          <SectionItem
            title={
              proposalHasEnded
                ? t('components.organisms.governance.overview.nextEpoch.title')
                : t(
                    'components.organisms.governance.overview.nextEpoch.votingEndsTitle'
                  )
            }
          >
            <CountdownTimer
              timestamp={
                proposalHasEnded
                  ? governanceDetails?.nextVotingEpoch
                  : governanceDetails?.endTime
              }
              background
            />
          </SectionItem>
          <VoSpoolOverview
            title={t(
              'components.organisms.governance.overview.allocated.title'
            )}
            subtitle={t(
              'components.organisms.governance.overview.allocated.subtitle'
            )}
            amount={getTotalAllocation().toString()}
            totalAmount={
              Math.trunc(+governanceDetails?.userVotingPower).toString() ?? '0'
            }
          />
        </Stack>
      </SectionColumn>
    </Section>
  )
}

export default GovernanceOverview
