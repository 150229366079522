import { Box, TableBody } from '@mui/material'
import {
  EmissionVault,
  GovernanceDetails,
  SortOrder,
  UserVotedChoice,
} from '@solidant/spool-v2-fe-lib'
import { useEffect, useMemo, useState } from 'react'

import useGovernanceAllocationState from '@/hooks/context/useGovernanceAllocationState'
import useLocale from '@/hooks/context/useLocale'

import DataMissing from '@/components/molecules/DataMissing'
import TableWrapper from '@/components/molecules/TableWrapper'
import EmissionTableRow from '@/components/organisms/Governance/Row'

import { headCells } from '@/constants/goverance'
import { sort } from '@/utils/table'

import { EmissionVaultWithSorting } from '@/types/governance'
import { HeadCell } from '@/types/table'

interface EmissionTableProps {
  vaults: EmissionVault[]
  votingPower: number
  isActiveProposal: boolean
  userHasVoted: boolean
  userVotedChoice: UserVotedChoice
  governanceDetails: GovernanceDetails
}

const Table: React.FC<EmissionTableProps> = ({
  vaults,
  votingPower,
  isActiveProposal,
  userHasVoted,
  userVotedChoice,
  governanceDetails,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const t = useLocale()
  const { governanceSort, filledEmissionVaults, allocation, handleSortClick } =
    useGovernanceAllocationState()

  const [headerCells, setHeaderCells] = useState<HeadCell[]>(headCells)
  const [multiSortedEmissionVaults, setMultiSortedEmissionVaults] = useState<
    EmissionVault[]
  >([])

  useEffect(() => {
    handleSortClick(
      'allocationWeight' as any,
      {
        key: 'name',
        direction: SortOrder.DESC,
      },
      allocation
    )

    if (!isActiveProposal) {
      const newHeadCells = headCells
        .map((cell) => {
          if (cell.id === 'apy') {
            return {
              ...cell,
              // TODO: Replace once new total APY fixed
              // tableHelperTop: 'New',
              tableHelperTop: 'Current',
              label: 'Total Apy',
            }
          }

          return cell
        })
        .filter((cell) => cell.id !== 'apyBoost')

      setHeaderCells(newHeadCells)
    }
  }, [isActiveProposal])

  const emissionVaults = useMemo(() => {
    return filledEmissionVaults.length ? filledEmissionVaults : vaults
  }, [filledEmissionVaults, vaults])

  useEffect(() => {
    if (governanceSort.key) {
      const multiSortedEmissionVaults: EmissionVaultWithSorting[] = []

      const allocationPercentageExists = emissionVaults
        .filter(({ allocation }) => !!+allocation.weight)
        .sort((a, b) =>
          sort(a, b, {
            direction: governanceSort.direction,
            key: 'allocationWeight',
          })
        )

      multiSortedEmissionVaults.push(...allocationPercentageExists)

      const sortedVaults = multiSortedEmissionVaults.map(
        ({ address }) => address
      )

      const totalDepositedAmountSort = emissionVaults
        .filter(({ address }) => {
          return !sortedVaults.includes(address)
        })
        .sort((a, b) =>
          sort(a, b, {
            direction: governanceSort.direction,
            key: 'totalDepositedAmount',
          })
        )

      multiSortedEmissionVaults.push(...totalDepositedAmountSort)

      setMultiSortedEmissionVaults(multiSortedEmissionVaults)
    }
  }, [emissionVaults, governanceSort])

  if (vaults.length === 0) {
    return (
      <DataMissing
        text={t('components.organisms.governance.emissionResults.missingText')}
      />
    )
  }

  return (
    <Box width='100%' border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='4px'>
      <TableWrapper
        header={headerCells}
        headerClass='default-table-head'
        pagination={{
          rowsPerPageOptions: [10, 20, 30],
          page: page,
          rowsPerPage: rowsPerPage,
          count: vaults.length,
          setPage: setPage,
          setRowsPerPage: setRowsPerPage,
        }}
        handleSortClick={handleSortClick}
        mutatableData={allocation}
        sortType={governanceSort}
      >
        <TableBody>
          {(governanceSort.key === ('allocationWeight' as any)
            ? multiSortedEmissionVaults
            : emissionVaults
          )
            .sort((a, b) => sort(a, b, governanceSort))
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((row) => (
              <EmissionTableRow
                key={row.address}
                row={row}
                votingPower={votingPower}
                isActiveProposal={isActiveProposal}
                userHasVoted={userHasVoted}
                userVotedChoice={userVotedChoice}
                governanceDetails={governanceDetails}
              />
            ))}
        </TableBody>
      </TableWrapper>
    </Box>
  )
}

export default Table
