import { Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useGovernanceDetails from '@/hooks/governance/useGovernanceDetails'

import PageTransition from '@/components/atoms/PageTransition'
import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import UnconnectedWalletDisplay from '@/components/atoms/UnconnectedWalletDisplay'
import DataLoading from '@/components/molecules/DataLoading'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import SubmitVote from '@/components/molecules/SubmitVote'
import EmissionResults from '@/components/organisms/Governance/EmissionResults'
import GovernanceOverview from '@/components/organisms/Governance/Overview'

import { AllocationProvider } from '@/store/governance/allocation.provider'

const Governance = () => {
  const t = useLocale()
  const { account } = useWeb3React()
  const { isLoading, governanceDetails, error } = useGovernanceDetails(account)

  return (
    <PageTransition>
      <Stack gap={(theme) => theme.spacing(2)}>
        <SpoolBreadcrumbs
          mode='injected'
          breadcrumb={['DAO', 'Emissions Voting']}
        >
          <Typography variant='body1'>
            {t('pages.governance.description')}
          </Typography>
        </SpoolBreadcrumbs>
        {!account ? (
          <UnconnectedWalletDisplay />
        ) : isLoading ? (
          <Section>
            <SectionColumn sx={{ gap: 0 }}>
              <Stack gap={3}>
                <DataLoading
                  loadingText={t(
                    'components.organisms.governance.emissionResults.loadingText'
                  )}
                />
              </Stack>
            </SectionColumn>
          </Section>
        ) : error || (governanceDetails && !governanceDetails?.proposal) ? (
          <Section>
            <SectionColumn sx={{ gap: 0 }}>
              <Stack gap={3}>
                <ErrorWithText
                  errorText={t(
                    'components.organisms.governance.emissionResults.errorText'
                  )}
                />
              </Stack>
            </SectionColumn>
          </Section>
        ) : (
          <AllocationProvider>
            <GovernanceOverview governanceDetails={governanceDetails} />
            <EmissionResults
              vaults={governanceDetails?.emissionVaults?.vaults ?? []}
              userVotingPower={Number(governanceDetails?.userVotingPower ?? 0)}
              isError={!!error}
              isActiveProposal={governanceDetails?.isActiveProposal ?? false}
              userHasVoted={governanceDetails?.userHasVoted}
              userVotedChoice={governanceDetails?.userVotedChoice}
              governanceDetails={governanceDetails}
            />
            {governanceDetails && (
              <SubmitVote
                governanceDetails={governanceDetails}
                votingPower={governanceDetails?.userVotingPower}
              />
            )}
          </AllocationProvider>
        )}
      </Stack>
    </PageTransition>
  )
}

export default Governance
