import { GuardParamType, GuardRequestType } from '@solidant/spool-v2-fe-lib'
import { constants } from 'ethers'

import useAppState from '@/hooks/context/useAppState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useModalState from '@/hooks/context/useModalState'

import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { SC_PERCENTAGE_MULTIPLIER } from '@/constants'
import { GUARDS_ID } from '@/constants/guards'

const useCreateVault = () => {
  const { openModal, setModalType } = useModalState()

  const { sdk } = useAppState()

  const {
    vaultName,
    svtSymbol,
    selectedRiskModel,
    selectedStrategies,
    selectedAllocationProvider,
    selectedAssetGroupId,
    selectedRiskScore,
    selectedManagementFee,
    selectedPerformanceFee,
    selectedGuards,
    resetAllSelectedFields,
    validateVaultName,
    validateSvtSymbol,
  } = useCreateVaultState()

  const handleCreateVault = async () => {
    const strategies = selectedStrategies.map((strategy) => strategy.address)

    const vaultNameValidation = validateVaultName(vaultName)
    const svtSymbolValidation = validateSvtSymbol(svtSymbol)

    if (!vaultNameValidation || !svtSymbolValidation) return

    try {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.PendingSignature,
          transactionType: TransactionType.CreateVault,
        },
      })

      const vaultGuards = sdk.guards.newGuardBuilder()

      // if (selectedGuards[GUARDS_ID.ASSET_LIMIT]?.assetAmount) {
      //   selectedGuards[GUARDS_ID.ASSET_LIMIT].assetAmount.map(
      //     ({ token, requirement, value }) => {
      //       vaultGuards.addAssetGuard(
      //         GuardRequestType.Deposit,
      //         token,
      //         GuardParamType.Executor,
      //         GUARDS_ASSET_LIMIT_OPERATOR[requirement],
      //         parseUnits(value, tokenDetails[token].decimals)
      //       )
      //     }
      //   )
      // }

      if (selectedGuards[GUARDS_ID.NFT_DEPOSITORS]) {
        selectedGuards[GUARDS_ID.NFT_DEPOSITORS].whitelist.map(
          ({ address }) => {
            vaultGuards.addAssetGuard(
              GuardRequestType.Deposit,
              address,
              GuardParamType.Executor,
              '>=',
              '1'
            )
          }
        )
      }

      if (selectedGuards[GUARDS_ID.NFT_BENEFICIARIES]) {
        selectedGuards[GUARDS_ID.NFT_BENEFICIARIES].whitelist.map(
          ({ address }) => {
            vaultGuards.addAssetGuard(
              GuardRequestType.Deposit,
              address,
              GuardParamType.Receiver,
              '>=',
              '1'
            )
          }
        )
      }

      if (
        selectedGuards[GUARDS_ID.SVT_NONTRANSFERABLE] &&
        selectedGuards[GUARDS_ID.SVT_NONTRANSFERABLE].checked
      ) {
        vaultGuards.addLockActionGuard(GuardRequestType.TransferSVTs)
      }

      // If it's a 1 strat vault, we dont need riskTolerance, riskProvider, allocationProvider
      const oneStrategyVault = strategies.length === 1

      const tx = await sdk.smartVault.create(
        vaultName,
        svtSymbol ? svtSymbol : vaultName,
        selectedAssetGroupId,
        strategies,
        oneStrategyVault ? 10000 : 0,
        oneStrategyVault ? 0 : selectedRiskScore,
        oneStrategyVault ? constants.AddressZero : selectedRiskModel,
        oneStrategyVault ? constants.AddressZero : selectedAllocationProvider,
        [],
        [],
        vaultGuards,
        selectedManagementFee * SC_PERCENTAGE_MULTIPLIER,
        0,
        selectedPerformanceFee * SC_PERCENTAGE_MULTIPLIER,
        true
      )

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Processing,
          transactionType: TransactionType.CreateVault,
        },
      })

      await tx.wait()

      resetAllSelectedFields()
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Success,
          transactionType: TransactionType.CreateVault,
        },
      })
    } catch (err) {
      console.error(err)

      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.CreateVault,
        },
      })
    }
  }

  return handleCreateVault
}

export default useCreateVault
