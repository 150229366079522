import { TableCell, TableFooter, TableRow, Typography } from '@mui/material'

import { formatUSD } from '@/utils/formats'

interface RewardsFooterProps {
  totalPerformanceFees: number
  totalManagementFees: number
}

const RewardsFooter: React.FC<RewardsFooterProps> = ({
  totalPerformanceFees,
  totalManagementFees,
}) => (
  <TableFooter>
    <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}>
      <TableCell align='left'>
        <Typography variant='tableHeader' color='text.primary'>
          Total
        </Typography>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell align='right'>
        <Typography variant='tableHeader' color='text.primary'>
          {formatUSD(totalPerformanceFees)}
        </Typography>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='tableHeader' color='text.primary'>
          {formatUSD(totalManagementFees)}
        </Typography>
      </TableCell>
    </TableRow>
  </TableFooter>
)

export default RewardsFooter
