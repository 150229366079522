import { alpha, Box, Card } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { memo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import { tokenDetails } from '@/components/atoms/Tokens'

import { formatUSD } from '@/utils/formats'

interface DashboardPieChartProps {
  assets: Record<string, number>
  format: 'vaults' | 'strategies'
}

const DashboardPieChart: React.FC<DashboardPieChartProps> = ({
  assets,
  format,
}) => {
  const { chain } = useConnectionState()

  return (
    <Box height='200px' minWidth='200px'>
      <ResponsivePie
        data={Object.entries(assets).map(
          ([tokenAddress, allocation], index) => {
            return {
              id: tokenAddress,
              label:
                format === 'vaults'
                  ? tokenDetails[chain][tokenAddress].symbol
                  : tokenAddress,
              value: +allocation,
              color: alpha(
                '#039BE5',
                1 - (index / Object.keys(assets).length) * 0.9
              ),
            }
          }
        )}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        layers={['arcs']}
        colors={{ datum: 'data.color' }}
        innerRadius={0.7}
        tooltip={({ datum: { label, value } }) => (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '2px 4px',
              background: '#ffffff',
            }}
          >
            <Box fontSize={12}>{label}</Box>
            <Box fontSize={14}>
              <strong>$ {formatUSD(value)}</strong>
            </Box>
          </Card>
        )}
      />
    </Box>
  )
}

export default memo(DashboardPieChart)
