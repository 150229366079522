import { EmissionVault, SortOrder } from '@solidant/spool-v2-fe-lib'

import { SortType } from '@/types/table'

export interface AllocationState {
  governanceSort: SortType<EmissionVault>
  allocation: { [key: string]: string }
  filledEmissionVaults: EmissionVault[]
}
export interface AllocationContextProps extends AllocationState {
  handleSortClick: (
    key: keyof EmissionVault,
    governanceSort: SortType<EmissionVault>,
    allocation: { [key: string]: string }
  ) => void
  getTotalAllocation: () => number
  setAllocation: (address: string, amount: string) => void
}

export enum AllocationActionTypes {
  SET_GOVERNANCE_SORT = 'SET_GOVERNANCE_SORT',
  CHANGE_GOVERNANCE_SORT_DIRECTION = 'CHANGE_GOVERNANCE_SORT_DIRECTION',
  SET_ALLOCATION = 'SET_ALLOCATION',
  SET_FILLED_EMISSION_VAULTS = 'SET_FILLED_EMISSION_VAULTS',
}

export type AllocationAction =
  | {
      type: AllocationActionTypes.SET_GOVERNANCE_SORT
      payload: {
        key: keyof EmissionVault
        direction: SortOrder
      }
    }
  | {
      type: AllocationActionTypes.CHANGE_GOVERNANCE_SORT_DIRECTION
    }
  | {
      type: AllocationActionTypes.SET_ALLOCATION
      address: string
      amount: string
    }
  | {
      type: AllocationActionTypes.SET_FILLED_EMISSION_VAULTS
      payload: EmissionVault[]
    }
