import { Box, Button, Stack, SxProps, TextField, Theme } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

interface GovernanceInputProps {
  value: string
  setValue: (value: any) => void
  maxValue: string
  userHasVoted: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const GovernanceInput: React.FC<GovernanceInputProps> = ({
  value,
  setValue,
  maxValue,
  disabled,
  userHasVoted,
  sx,
}) => {
  const { account } = useWeb3React()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (!account) return

    if (
      value !== '.' &&
      +value < Number.MAX_SAFE_INTEGER &&
      (value.match('^[1-9][0-9]*$') || !value)
    ) {
      setValue(value)
    }
  }

  return (
    <Stack flexGrow={1}>
      <Box
        display='flex'
        flexGrow='1'
        gap={(theme) => theme.spacing(1)}
        sx={{
          ...sx,
        }}
      >
        <TextField
          size='small'
          label=''
          onChange={handleChange}
          value={value}
          disabled={!account || disabled}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              backgroundColor: '#fff',
              fontWeight: 500,
            },
          }}
        />
        <Button
          variant='outlined'
          disabled={!account || disabled || userHasVoted}
          onClick={() => {
            setValue(maxValue)
          }}
          sx={{ width: '48px' }}
        >
          Max
        </Button>
      </Box>
    </Stack>
  )
}

export default GovernanceInput
