import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import { SectionItemWithInfoIconContainer } from '@/components/atoms/SectionItemWithInfoIconContainer'
import SectionItem from '@/components/molecules/SectionItem'
import AmountText from '@/components/organisms/Staking/AmountText'

const TotalStakingRewards = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.totalStakingRewards.title'
      )}
    >
      <SectionItemWithInfoIconContainer sx={{ pb: 1.7 }}>
        <AmountText rewards={rewardDetails?.totalRewards} displayPriceFeed />
        <Tooltip
          title={t(
            'components.organisms.staking.rewards.sectionItems.totalStakingRewards.tooltip'
          )}
          arrow
        >
          <InfoIcon className='lg' sx={{ color: 'primary.light' }} />
        </Tooltip>
      </SectionItemWithInfoIconContainer>
    </SectionItem>
  )
}

export default TotalStakingRewards
