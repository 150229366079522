import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import StakingActions from '@/components/organisms/Staking/StakingActions/StakingActions'
import AccumulatedVoSpool from '@/components/organisms/Staking/StakingInfo/AccumulatedVoSpool'
//import GetSpool from '@/components/organisms/Staking/StakingInfo/GetSpool'
import SpoolBalance from '@/components/organisms/Staking/StakingInfo/SpoolBalance'
import SpoolStaked from '@/components/organisms/Staking/StakingInfo/SpoolStaked'

import { StakingPageProps } from '@/types/staking'

const StakingOverview: React.FC<StakingPageProps> = ({ page, setPage }) => {
  const t = useLocale()

  const { account } = useWeb3React()
  const { rewardDetails } = useRewardDetails(account)
  /*const [timeRange, setTimeRange] = useState<TimeRangeOptions>('6M')

  const handleChange = (event: SelectChangeEvent) => {
    setTimeRange(event.target.value as TimeRangeOptions)
  }*/

  /*const chart = useMemo(
    () => (
      <StakedBiAxialChart
        dataLeftAxis={dataLeftAxis}
        dataRightAxis={dataRightAxis}
        handleChange={handleChange}
        timeRange={timeRange}
        noData
      />
    ),
    [timeRange]
  )*/

  return (
    <Section>
      <SectionColumn
        title={t('components.organisms.staking.overview.sectionTitle')}
        sx={{
          backgroundColor: 'white',
          borderTopLeftRadius: (theme) => theme.spacing(1),
          borderBottomLeftRadius: (theme) => theme.spacing(1),
        }}
      >
        <SpoolBalance />
        <Box display='flex' gap={(theme) => theme.spacing(2)}>
          <StakingActions page={page} setPage={setPage} />
        </Box>
      </SectionColumn>
      <SectionColumn
        title='&nbsp;'
        sx={{
          pl: 0,
          backgroundColor: 'white',
          borderTopRightRadius: (theme) => theme.spacing(1),
          borderBottomRightRadius: (theme) => theme.spacing(1),
        }}
      >
        <Box
          display='flex'
          alignItems='stretch'
          gap={(theme) => theme.spacing(2)}
        >
          <SpoolStaked />
          {!account ||
            (!!Number(rewardDetails?.totalRewards[0]?.amount) && (
              <AccumulatedVoSpool />
            ))}
          {/*<GetSpool />*/}
        </Box>
      </SectionColumn>
    </Section>
  )
}

export default StakingOverview
