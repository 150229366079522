import StarIcon from '@mui/icons-material/Star'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import {
  EmissionVault,
  GovernanceDetails,
  UserVotedChoice,
} from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import useGovernanceAllocationState from '@/hooks/context/useGovernanceAllocationState'
import useGetFutureApy from '@/hooks/governance/useGetFutureApy'

import GovernanceInput from '@/components/molecules/Governance/GovernanceInput'

import { debounce } from '@/utils'
import { formatNumber, formatPercentages } from '@/utils/formats'

interface SmartVaultTableRowProps {
  row: EmissionVault
  votingPower: number
  isActiveProposal: boolean
  userHasVoted: boolean
  userVotedChoice: UserVotedChoice
  governanceDetails: GovernanceDetails
}

const EmissionTableRow: React.FC<SmartVaultTableRowProps> = ({
  row,
  votingPower,
  isActiveProposal,
  userHasVoted,
  userVotedChoice,
  governanceDetails,
}) => {
  const {
    address,
    name,
    balance,
    allocation,
    owner,
    apy,
    totalDepositedAmount,
  } = row
  const { amount: allocAmount, weight: allocWeight } = allocation
  const { account } = useWeb3React()
  const {
    setAllocation,
    getTotalAllocation,
    allocation: myAllocation,
  } = useGovernanceAllocationState()
  const { getFutureApy } = useGetFutureApy()

  const remainingVotingPower = useCallback(() => {
    const currentAllocation = Number(myAllocation[address] ?? 0)
    return Math.trunc(votingPower + currentAllocation - getTotalAllocation())
  }, [myAllocation])

  const [futureApy, setFutureApy] = useState(Number(apy) * 100)

  const calcAllocations = () => {
    // total of votes for the row + user votes
    const totalRowAllocation =
      Number(allocAmount) + Number(myAllocation[address] || 0)

    // total amount of votes for entire proposal
    const overallAllocation =
      Number(governanceDetails.emissionVaults.totalAllocationAmount) +
      getTotalAllocation()

    return { totalRowAllocation, overallAllocation }
  }

  const debouncedGetFutureApy = useMemo(
    () =>
      debounce(
        async (totalRowAllocation: number, overallAllocation: number) => {
          const result = await getFutureApy(
            address,
            governanceDetails.proposal.plugins.emissionsAmount,
            governanceDetails.proposal.plugins.emissionsDurations,
            totalRowAllocation / overallAllocation,
            totalDepositedAmount
          )

          // Update linear progress and apyBoost
          setFutureApy(Number(result))
        }
      ),
    [account, totalDepositedAmount, governanceDetails]
  )

  useEffect(() => {
    // if (!myAllocation[address]) {
    //   setAllocation(address, '')
    // }

    if (!isActiveProposal || userHasVoted) {
      setAllocation(
        address,
        userVotedChoice[address]
          ? Math.trunc(Number(userVotedChoice[address].amount)).toString()
          : '0'
      )
    }
  }, [userHasVoted, isActiveProposal, account])

  // doing this useEffect because getTotalAllocation() isn't getting correct value in
  // useDebounce, so this is a solution I came up with -Heng
  useEffect(() => {
    if (!myAllocation[address]) {
      return
    }

    const { overallAllocation, totalRowAllocation } = calcAllocations()

    debouncedGetFutureApy(totalRowAllocation, overallAllocation)
  }, [account, myAllocation])

  const handleInputChange = (amount: string) => {
    if (+amount > remainingVotingPower()) {
      return
    }

    if (isActiveProposal && !userHasVoted) {
      setAllocation(address, amount)
    }
  }

  const handleLinearProgress = () => {
    const { totalRowAllocation, overallAllocation } = calcAllocations()

    if (isActiveProposal && !userHasVoted) {
      return (totalRowAllocation / overallAllocation) * 100
    }
    return Number(allocWeight) * 100
  }

  // TODO: add once new apy calculation added again
  // eslint-disable-next-line
  const handleApyBoost = () => {
    if (isActiveProposal && !userHasVoted) {
      return Number(myAllocation[address]) > 0 ? futureApy : Number(apy) * 100
    }
    return Number(apy) * 100
  }

  const openVault = () =>
    window.open(`https://app.spool.fi/smart-vaults/${address}`)

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: '#fff',
          userSelect: 'none',
          '&:hover': {
            backgroundColor: 'rgba(42, 162, 202, 0.04)',
          },
          cursor: 'pointer',
        }}
      >
        <TableCell sx={{ width: '350px' }} onClick={openVault}>
          <Box display='flex' gap={(theme) => theme.spacing(0.5)}>
            <Typography variant='body2'>{name}</Typography>
            {account === owner && (
              <StarIcon className='lg' sx={{ color: 'primary.main' }} />
            )}
          </Box>
        </TableCell>
        <TableCell align='right' sx={{ width: '120px' }} onClick={openVault}>
          <Typography variant='body2'>
            $ {formatNumber(Number(totalDepositedAmount), 2)}
          </Typography>
        </TableCell>
        <TableCell align='right' sx={{ width: '120px' }} onClick={openVault}>
          <Typography variant='body2'>
            $ {formatNumber(Number(balance), 2)}
          </Typography>
        </TableCell>
        <TableCell align='right' sx={{ width: '100px' }} onClick={openVault}>
          <Typography variant='body2'>
            {formatPercentages(Number(apy) * 100, 2)} %
          </Typography>
        </TableCell>
        {/* {isActiveProposal && (
          <TableCell align='right' sx={{ width: '100px' }} onClick={openVault}>
            <Typography variant='body2'>
              {`${formatNumber(Number(handleApyBoost()), 2)} %`}
            </Typography>
          </TableCell>
        )} */}
        <TableCell align='center' sx={{ width: '200px' }}>
          <GovernanceInput
            maxValue={remainingVotingPower().toString()}
            value={myAllocation[address] ?? ''}
            setValue={handleInputChange}
            disabled={!isActiveProposal || !remainingVotingPower()}
            userHasVoted={userHasVoted}
          />
        </TableCell>
        <TableCell align='center' sx={{ width: '100px' }} onClick={openVault}>
          {formatPercentages(handleLinearProgress())}%
        </TableCell>
      </TableRow>
    </>
  )
}

export default memo(EmissionTableRow)
