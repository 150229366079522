import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useStrategies = (strategyId: string) => {
  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk ? ['strategyVaults', strategyId] : null,
    async () => {
      return await sdk.strategies.getStrategyVaults(strategyId)
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    vaults: data,
    isLoading: !error && !data,
    error,
  }
}

export default useStrategies
