import { Datum } from '@nivo/line'
import {
  CreatorSmartVault,
  DashboardStrategy,
  IncentivizeVault,
  SortOrder,
} from '@solidant/spool-v2-fe-lib'
import { memo, useCallback, useMemo, useReducer } from 'react'

import { CreatorDashboardContext } from '@/store/creatorVault/creatorDashboard.context'
import creatorDashboardReducer from '@/store/creatorVault/creatorDashboard.reducer'
import { initialState } from '@/store/creatorVault/creatorDashboard.state'
import {
  CreatorDashboardActionTypes,
  FeesChartValues,
} from '@/store/creatorVault/creatorDashboard.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const CreatorDashboardProvider = memo(({ children }: RCNode) => {
  const [creatorVault, dispatch] = useReducer(
    creatorDashboardReducer,
    initialState
  )

  const setCreatorVaultsTablePage = (creatorVaultsTablePage: number) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_PAGE,
      payload: creatorVaultsTablePage,
    })
  }

  const setCreatorVaultsTableLimit = (creatorVaultsTableLimit: number) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_LIMIT,
      payload: creatorVaultsTableLimit,
    })
  }

  const handleSortClick = useCallback(
    (key: keyof CreatorSmartVault, sortType: SortType<CreatorSmartVault>) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: CreatorDashboardActionTypes.SET_CREATOR_VAULTS_TABLE_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: CreatorDashboardActionTypes.CHANGE_CREATOR_VAULTS_SORT_DIRECTION,
      })
    },
    []
  )

  const setSelectedVaults = (selectedVaults: CreatorSmartVault[]) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_SELECTED_VAULTS,
      payload: selectedVaults,
    })
  }

  const setVaultsStrategiesTablePage = (vaultsStrategiesTablePage: number) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_PAGE,
      payload: vaultsStrategiesTablePage,
    })
  }

  const setVaultsStrategiesTableLimit = (vaultsStrategiesTablePage: number) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_LIMIT,
      payload: vaultsStrategiesTablePage,
    })
  }

  const setTvrChartValues = (tvrChartValues: Datum[]) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_TVR_CHARTS_VALUE,
      payload: tvrChartValues,
    })
  }

  const setFeesChartValues = (feesChartValues: FeesChartValues) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_FEES_CHART_VALUES,
      payload: feesChartValues,
    })
  }

  const handleStrategiesSortClick = useCallback(
    (key: keyof DashboardStrategy, sortType: SortType<DashboardStrategy>) => {
      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: CreatorDashboardActionTypes.SET_VAULTS_STRATEGIES_TABLE_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: CreatorDashboardActionTypes.CHANGE_VAULTS_STRATEGIES_SORT_DIRECTION,
      })
    },
    []
  )

  const setWithdrawEarningsVault = (
    withdrawEarnignsVault: CreatorSmartVault & IncentivizeVault
  ) => {
    dispatch({
      type: CreatorDashboardActionTypes.SET_WITHDRAW_EARNINGS_VAULT,
      payload: withdrawEarnignsVault,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...creatorVault,
      handleSortClick,
      setCreatorVaultsTablePage,
      setCreatorVaultsTableLimit,
      setSelectedVaults,
      setVaultsStrategiesTablePage,
      setVaultsStrategiesTableLimit,
      handleStrategiesSortClick,
      setTvrChartValues,
      setFeesChartValues,
      setWithdrawEarningsVault,
    }
  }, [
    creatorVault.creatorVaultsTableLimit,
    creatorVault.creatorVaultsTablePage,
    creatorVault.creatorVaultsTableSort,
    creatorVault.selectedVaults,
    creatorVault.creatorVaultsStrategiesPage,
    creatorVault.creatorVaultsStrategiesLimit,
    creatorVault.creatorVaultsStrategiesSort,
    creatorVault.tvrChartValues,
    creatorVault.feesChartValues,
    creatorVault.withdrawEarningsVault,
  ])

  return (
    <CreatorDashboardContext.Provider value={contextValue}>
      {children}
    </CreatorDashboardContext.Provider>
  )
})
