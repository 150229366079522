import ConnectWalletModal from '@/components/organisms/ConnectWalletModal/ConnectWalletModal'
import DetectSmallScreenModal from '@/components/organisms/DetectSmallScreenModal'
import ActionModal from '@/components/organisms/Modals/ActionModal/ActionModal'
import IncentivizeVaultModal from '@/components/organisms/Modals/IncentivizeVaultModal/IncentivizeVaultModal'
import MaintenanceModal from '@/components/organisms/Modals/MaintenanceModal/MaintenanceModal'
import OneStrategyModal from '@/components/organisms/Modals/OneStrategyModal/OneStrategyModal'
import ReceiptsModal from '@/components/organisms/Modals/ReceiptsModal/ReceiptsModal'
import WarningModal from '@/components/organisms/Modals/WarningModal/WarningModal'
import WithdrawModal from '@/components/organisms/Modals/WithdrawModal/WithdrawModal'

const ModalsContainer: React.FC = () => (
  <>
    <ConnectWalletModal />
    <ActionModal />
    <WarningModal />
    <DetectSmallScreenModal />
    <MaintenanceModal />
    <OneStrategyModal />
    <ReceiptsModal />
    <IncentivizeVaultModal />
    <WithdrawModal />
    {/* <WelcomeModal /> */}
  </>
)

export default ModalsContainer
