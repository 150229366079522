import { Box, Stack, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useCommunityInfo from '@/hooks/staking/useCommunityInfo'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import SectionItem from '@/components/molecules/SectionItem'
import StakedPieChart from '@/components/organisms/Charts/StakedPieChart'

// import { currency } from '@/constants/currency'
import { Token } from '@/constants/smartVault'
import { formatNumber, formatUSD } from '@/utils/formats'

const StakingCommunity = () => {
  const t = useLocale()
  const { communityInfo } = useCommunityInfo()

  return (
    <Section>
      <SectionColumn
        title={t('components.organisms.staking.community.sectionTitle')}
        sx={{
          width: '200%',
          backgroundColor: 'white',
          borderTopLeftRadius: (theme) => theme.spacing(1),
          borderBottomLeftRadius: (theme) => theme.spacing(1),
        }}
      >
        <SectionItem
          title={t(
            'components.organisms.staking.community.sectionItems.spoolTokenStaked.title'
          )}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Stack width='max-content'>
              <Typography variant='body1'>
                {formatNumber(
                  communityInfo ? Number(communityInfo.stakedByCommunity) : 0,
                  2,
                  true
                )}
              </Typography>
              <Typography variant='body2'>
                {t('components.organisms.staking.community.content')}{' '}
                {formatUSD(
                  communityInfo
                    ? Number(communityInfo.totalCirculatingSupply)
                    : 0
                )}{' '}
                {Token.SPOOL}
              </Typography>
            </Stack>
          </Box>
        </SectionItem>
      </SectionColumn>

      <SectionColumn
        title='&nbsp;'
        sx={{
          backgroundColor: 'white',
          borderTopRightRadius: (theme) => theme.spacing(1),
          borderBottomRightRadius: (theme) => theme.spacing(1),
        }}
      >
        {/*<StakedPieChart
            staked={
              communityInfo ? Number(communityInfo.stakedByCommunity) : 0
            }
            supply={
              communityInfo ? Number(communityInfo.totalCirculatingSupply) : 0
            }
            height={240}
            width={368}
        />*/}
        {communityInfo && (
          <StakedPieChart
            staked={communityInfo.stakedByCommunity}
            total={communityInfo.totalCirculatingSupply}
          />
        )}
      </SectionColumn>
    </Section>
  )
}

export default StakingCommunity
