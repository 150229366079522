import { useContext } from 'react'

import { AllocationContext } from '@/store/governance/allocation.context'

const useGovernanceAllocationState = () => {
  const allocationContext = useContext(AllocationContext)
  if (!Object.keys(AllocationContext).length) {
    throw new Error('Allocation context must be used within its provider')
  }
  return allocationContext
}

export default useGovernanceAllocationState
