import { Theme } from '@emotion/react'
import {
  Box,
  Card,
  CardContent,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { PropsWithChildren } from 'react'

interface GovernanceOverviewSectionItemProps extends PropsWithChildren {
  title: string
  pretitle?: string
  subtitle?: string
  topTitle?: string
  noPadding?: boolean
  headerClass?: string
  contentClass?: string
  sx?: SxProps<Theme>
}

const GovernanceOverviewSectionItem: React.FC<
  GovernanceOverviewSectionItemProps
> = ({
  title,
  pretitle,
  subtitle,
  topTitle,
  noPadding,
  headerClass,
  contentClass,
  sx,
  children,
}) => {
  const column = headerClass === 'section-item-header-column'
  return (
    <Card sx={sx} variant='outlined' className='section-item-card'>
      <CardContent className='section-item-card-content'>
        <Box display='grid' className={`section-item-header ${headerClass}`}>
          <Stack display='grid' sx={{ px: 2, py: 1.5 }}>
            <Typography variant='tableHelper'>{topTitle}</Typography>
            <Box display='grid'>
              {pretitle && (
                <Typography variant='tableHelper'>
                  {pretitle}
                  {!column && ' '}
                </Typography>
              )}
              <Typography variant='tableHeader'>{title}</Typography>
              {subtitle && (
                <Typography variant='tableHelper'>
                  {!column && ' '}
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
        <Box
          padding={noPadding ? 0 : '16px 16px'}
          sx={{
            backgroundColor: '#fff',
          }}
          className={contentClass}
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  )
}

export default GovernanceOverviewSectionItem
