import { Chip, Stack, Typography } from '@mui/material'

interface ChipTimeProps {
  value: number
  unit: string
  background?: boolean
}

const ChipTime: React.FC<ChipTimeProps> = ({ value, unit, background }) => (
  <Chip
    disabled={value === 0}
    component='div'
    variant='outlined'
    sx={{
      py: 4,
      backgroundColor: background && 'rgb(245, 245, 245)',
      outlineColor: 'rgba(33, 35, 34, 0.12)',
    }}
    label={
      <Stack>
        <Typography variant='h5'>{value}</Typography>
        <Typography variant='caption'>{unit}</Typography>
      </Stack>
    }
  />
)

export default ChipTime
