import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useStakedSpool = (userAddress: string) => {
  const { sdk } = useContext(AppContext)

  const { data, error, mutate } = useSWR(
    sdk && userAddress ? ['stakedSpool', userAddress] : null,
    async () => await sdk.staking.getStakedSpool(userAddress)
  )

  const retryMutation = async (previousValue: string | undefined) => {
    let retries = 0
    do {
      const currentValue = await mutate()
      if (currentValue !== previousValue) {
        break
      }
      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 10)
  }

  const updateStakedSpool = async (previousValue?: string) => {
    if (!previousValue) {
      return mutate(undefined, true)
    }

    return retryMutation(previousValue)
  }

  if (error) {
    console.error(error)
  }

  return {
    stakedSpool: data,
    isLoading: !error && !data,
    updateStakedSpool: updateStakedSpool,
    error,
  }
}

export default useStakedSpool
