import { GovernanceDetails, SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useContext } from 'react'
import useSWR from 'swr'

import useConnectionState from '@/hooks/context/useConnectionState'

import { AppContext } from '@/store/app/app.context'

import { IS_TESTNET } from '@/config/sdk'
import { MUTATE_SLEEP_DURATION } from '@/constants'
import { sleep } from '@/utils/web3'

const useGovernanceDetails = (userAddress: string) => {
  const { sdk } = useContext(AppContext)
  const { chain } = useConnectionState()

  const { data, error, mutate } = useSWR(
    sdk ? ['governanceDetails', userAddress] : null,
    async () => {
      if (chain !== SupportedNetworks.ETHEREUM && IS_TESTNET)
        return {} as GovernanceDetails
      return sdk.governance.getGovernanceDetails(userAddress)
    }
  )

  const updateGovernanceDetails = async (previousVotingValue?: number) => {
    let retries = 0

    do {
      const currentValue: GovernanceDetails = await mutate()

      const newVotingValue = Object.values(currentValue.userVotedChoice).reduce(
        (acc, { amount }) => {
          return (acc += +amount)
        },
        0
      )

      if (newVotingValue !== previousVotingValue) {
        break
      }

      retries++
      await sleep(MUTATE_SLEEP_DURATION)
    } while (retries < 5)
  }

  if (error) {
    console.error(error)
  }

  return {
    governanceDetails: data,
    updateGovernanceDetails,
    isLoading: !error && !data,
    error,
  }
}

export default useGovernanceDetails
