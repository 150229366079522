import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import SectionItem from '@/components/molecules/SectionItem'
import CountdownTimer from '@/components/organisms/Staking/CountdownTimer'

const VoSpoolEpochTimer = () => {
  const t = useLocale()
  const { account } = useWeb3React()

  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.epochBegins.title'
      )}
    >
      <CountdownTimer
        endTime={rewardDetails?.nextVoSpoolEpoch}
        expanded={!!Number(rewardDetails?.totalRewards[0]?.amount)}
      />
    </SectionItem>
  )
}

export default VoSpoolEpochTimer
