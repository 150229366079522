import { Typography } from '@mui/material'

import useConnectionState from '@/hooks/context/useConnectionState'
import usePriceFeed from '@/hooks/web3/usePriceFeed'

import { tokenAddresses } from '@/constants/tokens'
import { formatUSD } from '@/utils/formats'

interface AmountTextUSDProps {
  amount: string
}

const AmountTextUSD: React.FC<AmountTextUSDProps> = ({ amount }) => {
  const { chain } = useConnectionState()

  const { priceFeed } = usePriceFeed(tokenAddresses[chain]['SPOOL'])

  return (
    <Typography variant='caption' color='rgba(33, 35, 34, 0.6)'>
      {'$ '}
      {formatUSD(
        !!priceFeed && !!amount ? Number(priceFeed) * Number(amount) : 0
      )}
    </Typography>
  )
}

export default AmountTextUSD
