import { Box, Button, Stack, TableBody, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useRewardsTableState from '@/hooks/context/useRewardsTableState'
import useCreatorFeeVaults from '@/hooks/creatorFee/useCreatorFeeVaults'

import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import ErrorWithText from '@/components/molecules/ErrorWithText'
import TableWrapper from '@/components/molecules/TableWrapper'
import RewardsFooter from '@/components/organisms/SmartVaults/Rewards/RewardsFooter'
import RewardsTableRow from '@/components/organisms/SmartVaults/Rewards/RewardsTableRow'

import { Modals } from '@/store/modal/modals.types'

import { Routes } from '@/constants/routes'
import { smartVaultsRewardsHeadCells } from '@/constants/smartVault'

import { HeadCell } from '@/types/table'

const RewardsTable = () => {
  const t = useLocale()

  const { chain } = useConnectionState()

  const {
    page,
    setPage,
    limit,
    setLimit,
    rewardsTableSort,
    handleRewardsTableSort,
  } = useRewardsTableState()

  const { account } = useWeb3React()

  const { openModal } = useModalState()

  const { creatorFeesVault, totalCount, isLoading, error } =
    useCreatorFeeVaults(page, limit, account, rewardsTableSort)

  const totalPerformanceFees = useMemo(
    () =>
      creatorFeesVault?.reduce(
        (acc, creatorFeesVault) =>
          acc + Number(creatorFeesVault.performanceFeeAccrued),
        0
      ),
    [creatorFeesVault]
  )

  const totalManagementFees = useMemo(
    () =>
      creatorFeesVault?.reduce(
        (acc, creatorFeesVault) =>
          acc + Number(creatorFeesVault.managementFeesAccrued),
        0
      ),
    [creatorFeesVault]
  )

  if (isLoading) {
    return (
      <Stack gap={3}>
        <DataLoading
          loadingText={t(
            'components.organisms.smartVaults.rewards.rewardsTable.loading'
          )}
        />
      </Stack>
    )
  }

  if (!account) {
    return (
      <Stack gap={3}>
        <DataMissing text={t('general.walletNotConnected')} />
        <Box display='flex' alignItems='center' gap={2} margin='auto'>
          <Typography variant='body2'>
            {t('general.connectYourWallet')}
          </Typography>
          <Button
            variant='contained'
            size='small'
            onClick={() => openModal(Modals.CONNECT_WALLET)}
          >
            {t(
              'components.organisms.sideMenu.walletConnectionMenu.buttons.connect.label'
            )}
          </Button>
        </Box>
      </Stack>
    )
  }

  if (totalCount === 0) {
    return (
      <Stack gap={3}>
        <DataMissing
          text={t(
            'components.organisms.smartVaults.rewards.rewardsTable.notFound'
          )}
        />
        <Box display='flex' alignItems='center' gap={2} margin='auto'>
          <Link to={Routes(chain).createVault.createVaultRiskModel.url}>
            <Button variant='contained' size='small'>
              {t(
                'components.organisms.smartVaults.rewards.rewardsTable.createVault.button'
              )}
            </Button>
          </Link>
        </Box>
      </Stack>
    )
  }

  if (error) {
    return (
      <ErrorWithText
        errorText={t(
          'components.organisms.smartVaults.rewards.rewardsTable.error'
        )}
      />
    )
  }

  return (
    <>
      <Box
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        overflow='hidden'
      >
        <TableWrapper
          header={smartVaultsRewardsHeadCells as HeadCell[]}
          headerClass='default-table-head'
          pagination={
            totalCount > 10 && {
              rowsPerPageOptions: [10, 20, 30],
              page: page,
              rowsPerPage: limit,
              count: totalCount,
              setPage: setPage,
              setRowsPerPage: setLimit,
            }
          }
          sortType={rewardsTableSort}
          handleSortClick={handleRewardsTableSort}
        >
          <TableBody>
            {creatorFeesVault?.map((creatorFeeVault) => (
              <RewardsTableRow
                key={creatorFeeVault.address}
                row={creatorFeeVault}
              />
            ))}
          </TableBody>
          <RewardsFooter
            totalManagementFees={totalManagementFees ?? 0}
            totalPerformanceFees={totalPerformanceFees ?? 0}
          />
        </TableWrapper>
      </Box>
    </>
  )
}

export default RewardsTable
