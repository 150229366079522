import { useContext } from 'react'

import useModalState from '@/hooks/context/useModalState'

import { AppContext } from '@/store/app/app.context'
import {
  Modals,
  TransactionStatus,
  TransactionType,
} from '@/store/modal/modals.types'

import { HARD_CODED } from '@/constants/goverance'

const useGetFutureApy = () => {
  const { openModal, setModalType } = useModalState()
  const { sdk } = useContext(AppContext)

  const getFutureApy = async (
    address: string,
    emissionAmount: string,
    emissionDurationDays: number,
    weight: number,
    totalDepositedAmount: string
  ): Promise<string> => {
    const staticApy =
      HARD_CODED[address.toLowerCase() as keyof typeof HARD_CODED] ?? 0
    try {
      const futureApy = await sdk.governance.getFutureApy(
        emissionAmount,
        emissionDurationDays,
        isNaN((weight + staticApy) / 2) ? 0 : (weight + staticApy) / 2,
        totalDepositedAmount
      )
      return futureApy
    } catch (error) {
      openModal(Modals.ACTION_MODAL)
      setModalType({
        actionModalType: {
          transactionStatus: TransactionStatus.Failure,
          transactionType: TransactionType.FutureApy,
        },
      })
      return '0'
    }
  }
  return { getFutureApy }
}

export default useGetFutureApy
