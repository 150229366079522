import {
  ModalsAction,
  ModalsActionTypes,
  ModalState,
} from '@/store/modal/modals.types'

export const modalsReducer = (
  state: Record<string, ModalState>,
  action: ModalsAction
) => {
  switch (action.type) {
    case ModalsActionTypes.OPEN_MODAL:
      return {
        ...state,
        [action.payload]: { ...state[action.payload], isOpen: true },
      }

    case ModalsActionTypes.CLOSE_MODAL:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isOpen: false,
          isMinimized: false,
        },
      }

    case ModalsActionTypes.SET_MODAL_TYPE:
      return {
        ...state,
        actionModal: { ...state.actionModal, modalType: action.payload },
        warningModal: { ...state.warningModal, modalType: action.payload },
        welcomeModal: { ...state.welcomeModal, modalType: action.payload },
        receiptsModal: { ...state.receiptsModal, modalType: action.payload },
        incentivizeVaultModal: {
          ...state.incentivizeVaultModal,
          modalType: action.payload,
        },
        withdrawModal: {
          ...state.withdrawModal,
          modalType: action.payload,
        },
      }

    case ModalsActionTypes.MINIMIZE_MODAL:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isMinimized: !state[action.payload].isMinimized,
        },
      }
  }
}
