import { Box, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import SectionItem from '@/components/molecules/SectionItem'

import { formatUSD } from '@/utils/formats'

const ReturnsFromStaking = () => {
  const t = useLocale()
  const { account } = useWeb3React()
  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.returnsFromStaking.title'
      )}
    >
      <Box display='flex' alignItems='baseline' justifyContent='space-between'>
        <Box display='flex' flexDirection='column' alignItems='baseline'>
          <Typography variant='h6'>APY on SPOOL</Typography>
          <Typography variant='h6'>
            {formatUSD(rewardDetails?.apy?.emissionRewards || 0)}%
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='end'>
          <Typography variant='h6'>APY on voSPOOL</Typography>
          <Typography variant='h6'>
            {formatUSD(rewardDetails?.apy?.voSpoolRewards || 0)}%
          </Typography>
        </Box>
      </Box>
    </SectionItem>
  )
}

export default ReturnsFromStaking
