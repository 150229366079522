const Shelf = () => {
  return (
    <svg
      width='54'
      height='58'
      viewBox='0 0 54 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.94737 26.1L25.5789 0L41.2105 26.1H9.94737ZM41.2105 58C37.6579 58 34.6382 56.7312 32.1513 54.1937C29.6645 51.6562 28.4211 48.575 28.4211 44.95C28.4211 41.325 29.6645 38.2437 32.1513 35.7062C34.6382 33.1687 37.6579 31.9 41.2105 31.9C44.7632 31.9 47.7829 33.1687 50.2697 35.7062C52.7566 38.2437 54 41.325 54 44.95C54 48.575 52.7566 51.6562 50.2697 54.1937C47.7829 56.7312 44.7632 58 41.2105 58ZM0 56.55V33.35H22.7368V56.55H0Z'
        fill='#006BA6'
      />
    </svg>
  )
}

export default Shelf
