import WarningIcon from '@mui/icons-material/Warning'
import { Tooltip, Typography } from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { Suspense } from 'react'

import Spool from '@/assets/icons/chainIcons/Spool'
import DAI from '@/assets/icons/tokenIcons/DAI'
import ETH from '@/assets/icons/tokenIcons/ETH'
import PYUSD from '@/assets/icons/tokenIcons/PYUSD'
import USDC from '@/assets/icons/tokenIcons/USDC'
import USDE from '@/assets/icons/tokenIcons/USDE'
import USDT from '@/assets/icons/tokenIcons/USDT'
import WETH from '@/assets/icons/tokenIcons/WETH'

type tokenDetailsType = {
  [key: string]: {
    symbol: string
    decimals: number
    lsd: boolean
    icon?: JSX.Element
  }
}

export const tokenDetails: Record<SupportedNetworks, tokenDetailsType> = {
  [SupportedNetworks.ETHEREUM]: {
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
      symbol: 'WETH',
      decimals: 18,
      lsd: false,
      icon: <WETH />,
    },
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
      symbol: 'USDC',
      decimals: 6,
      lsd: false,
      icon: <USDC />,
    },
    '0xdac17f958d2ee523a2206206994597c13d831ec7': {
      symbol: 'USDT',
      decimals: 6,
      lsd: false,
      icon: <USDT />,
    },
    '0x6b175474e89094c44da98b954eedeac495271d0f': {
      symbol: 'DAI',
      decimals: 18,
      lsd: false,
      icon: <DAI />,
    },
    '0x40803cea2b2a32bda1be61d3604af6a814e70976': {
      symbol: 'SPOOL',
      decimals: 18,
      lsd: false,
      icon: <Spool />,
    },
    '0x4c9edd5852cd905f086c759e8383e09bff1e68b3': {
      symbol: 'USDe',
      decimals: 18,
      lsd: false,
      icon: <USDE />,
    },
    '0x6c3ea9036406852006290770bedfcaba0e23a0e8': {
      symbol: 'PYUSD',
      decimals: 6,
      lsd: false,
      icon: <PYUSD />,
    },
    SVT: {
      symbol: 'SVT',
      decimals: 18,
      lsd: false,
      icon: <Typography>SVT</Typography>,
    },
    native: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
    ETH: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
  },
  [SupportedNetworks.ARBITRUM]: {
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': {
      symbol: 'WETH',
      decimals: 18,
      lsd: false,
      icon: <WETH />,
    },
    '0xaf88d065e77c8cc2239327c5edb3a432268e5831': {
      symbol: 'USDC',
      decimals: 6,
      lsd: false,
      icon: <USDC />,
    },
    '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9': {
      symbol: 'USDT',
      decimals: 6,
      lsd: false,
      icon: <USDT />,
    },
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': {
      symbol: 'DAI',
      decimals: 18,
      lsd: false,
      icon: <DAI />,
    },
    '0xeca14f81085e5b8d1c9d32dcb596681574723561': {
      symbol: 'SPOOL',
      decimals: 18,
      lsd: false,
      icon: <Spool />,
    },
    SVT: {
      symbol: 'SVT',
      decimals: 18,
      lsd: false,
      icon: <Typography>SVT</Typography>,
    },
    native: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
    ETH: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
  },
  [SupportedNetworks.SEPOLIA]: {
    '0x88b25a14dae46c33ae3eef2b45a5ec758d9a5ec6': {
      symbol: 'WETH',
      decimals: 18,
      lsd: false,
      icon: <WETH />,
    },
    '0xa6b92fcd4ee124353c8a6acf1edb574f46f3f8df': {
      symbol: 'USDC',
      decimals: 6,
      lsd: false,
      icon: <USDC />,
    },
    '0x72d6a42377e7511ff69aab65fb3c075512fcbae5': {
      symbol: 'USDT',
      decimals: 6,
      lsd: false,
      icon: <USDT />,
    },
    '0x2a3a3872c242c35093a8fc48dac838c4b2d24a03': {
      symbol: 'DAI',
      decimals: 18,
      lsd: false,
      icon: <DAI />,
    },

    '0xeca14f81085e5b8d1c9d32dcb596681574723561': {
      symbol: 'SPOOL',
      decimals: 18,
      lsd: false,
      icon: <Spool />,
    },
    SVT: {
      symbol: 'SVT',
      decimals: 18,
      lsd: false,
      icon: <Typography>SVT</Typography>,
    },
    native: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
    ETH: {
      symbol: 'ETH',
      decimals: 18,
      lsd: false,
      icon: <ETH />,
    },
  },
}

export const mapAssetTokenIcon = (
  chainId: SupportedNetworks,
  key: string | number
) => {
  if (!tokenDetails[chainId][key] || !tokenDetails[chainId][key].icon) {
    // Just in case there is an unknown token
    return (
      <Tooltip title='Undetected token' arrow placement='left'>
        <WarningIcon className='lg' sx={{ color: 'primary.main' }} />
      </Tooltip>
    )
  }
  return <Suspense>{tokenDetails[chainId][key].icon}</Suspense>
}
