import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Button, TextField, Typography } from '@mui/material'
import { CreatorSmartVault, IncentivizeVault } from '@solidant/spool-v2-fe-lib'
import { parseUnits } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import { isAddress } from 'web3-validator'

import useConnectionState from '@/hooks/context/useConnectionState'
import useIncentivizeVaultState from '@/hooks/context/useIncentivizeVault'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useCreatorDashboard from '@/hooks/creatorDashboard/useCreatorDashboard'
import useAddIncentive from '@/hooks/incentives/transactions/useAddIncentive'
import useApproveToken from '@/hooks/web3/useApproveToken'
import useTokenDetails from '@/hooks/web3/useTokenDetails'
import useUserBalance from '@/hooks/web3/useUserBalance'

import ModalBoxOuterContainer from '@/components/atoms/ModalBoxContainer'
import TransactionInput from '@/components/molecules/TransactionInput'
import AddTableRowDropdown from '@/components/organisms/Incentives/TokensTable/AddTableRow/AddTableRowDropdown'
import ModalBox from '@/components/organisms/Modals/ModalBox'

import { Modals } from '@/store/modal/modals.types'

import { API_ENDPOINTS } from '@/config/sdk'
import { tokenAddresses } from '@/constants/tokens'
import { CHAIN_EXPLORER } from '@/constants/web3'

const IncentivizeVaultModal: React.FC = () => {
  const t = useLocale()
  const { modals, closeModal } = useModalState()
  const { chain } = useConnectionState()

  const {
    days,
    tokenAmount,
    targetToken,
    isCustomToken,
    customTokenAddress,
    setDays,
    setTokenAmount,
    setCustomTokenAddress,
  } = useIncentivizeVaultState()

  const { updateIncentivizedRewards } = useCreatorDashboard()

  const vaultDetails =
    modals['incentivizeVaultModal'].modalType?.incentivizeVaultModalType?.vault

  const [vault, setVault] = useState<CreatorSmartVault & IncentivizeVault>(
    {} as CreatorSmartVault & IncentivizeVault
  )

  useEffect(() => {
    return () => {
      setVault({} as CreatorSmartVault & IncentivizeVault)
      setCustomTokenAddress('')
      setTokenAmount('')
      setDays('')
    }
  }, [])

  useEffect(() => {
    if (vaultDetails) {
      setVault(vaultDetails)
    }
  }, [vaultDetails, modals])

  const rewards = vault?.incentivizedRewards?.rewards

  const underlyingVaultTokens = vault?.assetGroup?.assetGroupTokens
    ? vault?.assetGroup?.assetGroupTokens.map(({ address }) => address)
    : []

  const addIncentive = useAddIncentive(
    vault?.address ?? '',
    days,
    isCustomToken ? customTokenAddress : targetToken,
    tokenAmount,
    () =>
      updateIncentivizedRewards(vault.address, true, vault?.incentivizedRewards)
  )

  const { isTokenApproved, allowance, approve } = useApproveToken(
    isCustomToken ? customTokenAddress : targetToken,
    API_ENDPOINTS[chain].REWARD_MANAGER,
    tokenAmount
  )

  const { balance: tokenBalance } = useUserBalance(targetToken)

  const { decimals } = useTokenDetails(targetToken)

  return (
    <ModalBox id='incentivizeVaultModal'>
      <ModalBoxOuterContainer sx={{ width: '400px', padding: '0rem' }}>
        <Box
          display='flex'
          paddingY='1rem'
          px='1rem'
          alignItems='center'
          justifyContent='space-between'
          borderBottom='1px solid rgba(0, 0, 0, 0.12)'
        >
          <Typography fontWeight={500}>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.action'
            )}
          </Typography>
          <Box
            display='flex'
            onClick={() => closeModal(Modals.INCENTIVIZE_VAULT_MODAL)}
          >
            <CloseIcon
              sx={{
                color: 'text.secondary',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
        <Box display='flex' margin='1rem' flexDirection='column' gap='1.5rem'>
          <Box display='flex' flexDirection='column' gap='1rem'>
            <Typography>
              {t(
                'components.organisms.modals.incentivizeVaultModal.descriptions.tokenSelect'
              )}
            </Typography>
            <AddTableRowDropdown
              excludedTokens={[
                ...underlyingVaultTokens,
                ...(rewards ? rewards.map((token) => token.asset.address) : []),
              ]}
            />
          </Box>
          {isCustomToken && (
            <Box display='flex' flexDirection='column' gap='1rem'>
              <Typography>
                {t(
                  'components.organisms.modals.incentivizeVaultModal.descriptions.tokenAmount'
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <TextField
                  multiline
                  size='small'
                  label='Address'
                  InputLabelProps={{
                    shrink:
                      customTokenAddress?.length > 0 || targetToken?.length > 6,
                  }}
                  onChange={(event) =>
                    setCustomTokenAddress(event.target.value)
                  }
                  value={isCustomToken ? customTokenAddress : targetToken}
                  disabled={!isCustomToken}
                  sx={{
                    flexGrow: 1,
                    '& .MuiInputBase-root': {
                      fontWeight: 500,
                    },
                  }}
                />
                {targetToken && !isCustomToken && (
                  <a
                    href={`${CHAIN_EXPLORER[chain].url}address/${targetToken}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <OpenInNewIcon
                      sx={{ ml: 0.5, width: '16px', color: 'icon.primary' }}
                    />
                  </a>
                )}
              </Box>
            </Box>
          )}
          <Box display='flex' flexDirection='column' gap='1rem'>
            <Typography>
              {t(
                'components.organisms.modals.incentivizeVaultModal.descriptions.tokenAmount'
              )}
            </Typography>
            <TransactionInput
              inputLabel='Amount'
              tokenId={isCustomToken ? customTokenAddress : targetToken}
              value={tokenAmount}
              setValue={(value: string) => {
                setTokenAmount(value)
              }}
              showMax={false}
            />
          </Box>
          <Box display='flex' flexDirection='column' gap='1rem'>
            <Typography>
              {t(
                'components.organisms.modals.incentivizeVaultModal.descriptions.duration'
              )}
            </Typography>
            <Box width='100%'>
              <TextField
                size='small'
                label='Days'
                onChange={(event) => {
                  const { value } = event.target
                  if (
                    (value !== '.' &&
                      +value < Number.MAX_SAFE_INTEGER &&
                      value.match('^[1-9][0-9]*$')) ||
                    !value
                  ) {
                    setDays(event.target.value)
                  }
                }}
                value={days}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    fontWeight: 500,
                  },
                }}
              />
            </Box>
          </Box>
          <Button
            variant='contained'
            size='large'
            onClick={() => {
              if (isTokenApproved) {
                addIncentive()
                return
              }
              approve(tokenAmount)
            }}
            disabled={
              !tokenAmount ||
              (parseUnits(tokenAmount, decimals).gt(tokenBalance) &&
                !isCustomToken) ||
              (isCustomToken && !isAddress(customTokenAddress))
            }
          >
            {isTokenApproved
              ? t('components.organisms.incentives.tokensTable.addIncentive')
              : targetToken === tokenAddresses[chain].USDT &&
                !allowance.isZero() &&
                parseUnits(tokenAmount || '0', decimals).gt(allowance)
              ? t(
                  'components.organisms.incentives.tokensTable.resetAndApproveToken'
                )
              : t('components.organisms.incentives.tokensTable.approve')}
          </Button>
        </Box>
      </ModalBoxOuterContainer>
    </ModalBox>
  )
}

export default IncentivizeVaultModal
