import { Box } from '@mui/material'
import dayjs, { extend, unix } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { memo, useEffect, useState } from 'react'

import useLocale from '@/hooks/context/useLocale'

import ChipTime from '@/components/molecules/ChipTime'

extend(duration)

interface CountdownTimerProps {
  timestamp?: number
  background?: boolean
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  timestamp,
  background,
}) => {
  const [timer, setTimer] = useState(
    dayjs.duration({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  )

  const t = useLocale()

  useEffect(() => {
    const currentTime = dayjs().unix()

    if (!timestamp || currentTime > timestamp) return

    const updateTimer = () => {
      const timeRemaining = unix(timestamp).diff(dayjs())
      const durationTime = dayjs.duration(timeRemaining)
      setTimer(durationTime)
    }

    updateTimer()

    const intervalId = setInterval(updateTimer, 1000)

    return () => clearInterval(intervalId)
  }, [timestamp])

  return (
    <Box
      display='flex'
      justifyContent='center'
      gap={(theme) => theme.spacing(1)}
    >
      <ChipTime
        value={timer.days()}
        unit={t('components.organisms.governance.overview.nextEpoch.days')}
        background={background}
      />
      <ChipTime
        value={timer.hours()}
        unit={t('components.organisms.governance.overview.nextEpoch.hours')}
        background={background}
      />
      <ChipTime
        value={timer.minutes()}
        unit={t('components.organisms.governance.overview.nextEpoch.minutes')}
        background={background}
      />
      <ChipTime
        value={timer.seconds()}
        unit={t('components.organisms.governance.overview.nextEpoch.seconds')}
        background={background}
      />
    </Box>
  )
}

export default memo(CountdownTimer)
