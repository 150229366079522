import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface CreateVaultButtonProps {
  buttonText: string
  navigationRoute: string
}

const CreateVaultButton: React.FC<CreateVaultButtonProps> = ({
  buttonText,
  navigationRoute,
}) => {
  const navigate = useNavigate()
  return (
    <Box>
      <Button
        variant='contained'
        color='primary'
        sx={{ px: 3, py: 1, mt: '1rem' }}
        onClick={() => {
          navigate(navigationRoute)
        }}
      >
        <Typography variant='button' sx={{ whiteSpace: 'nowrap' }}>
          {buttonText}
        </Typography>
      </Button>
    </Box>
  )
}

export default CreateVaultButton
