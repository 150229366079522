import { useContext } from 'react'
import useSWR from 'swr'

import { AppContext } from '@/store/app/app.context'

const useCommunityInfo = () => {
  const { sdk } = useContext(AppContext)

  const { data, error } = useSWR(
    sdk ? ['communityInfo'] : null,
    async () => await sdk.staking.getCommunityInfo()
  )

  if (error) {
    console.error(error)
  }

  return {
    communityInfo: data,
    isLoading: !error && !data,
    error,
  }
}

export default useCommunityInfo
