import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, IconButton, Typography } from '@mui/material'
import { CreatorSmartVault, IncentivizeVault } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreatorDashboardState from '@/hooks/context/useCreatorDashboardState'
import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'
import useUserClaimableNfts from '@/hooks/user/useUserClaimableNfts'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import AssetGroupTokens from '@/components/molecules/AssetGroupTokens'
import CreatorVaultsMobileRowWithdrawal from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsMobileRowWithdrawal'
import CreatorVaultsTableRowExpand from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRowExpand'
import CreatorVaultsTableRowOptions from '@/components/organisms/Dashboard/Creator/CreatorVaultsTable/CreatorVaultsTableRowOptions'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'

import { Modals } from '@/store/modal/modals.types'

import { currency } from '@/constants/currency'
import { Routes } from '@/constants/routes'
import { formatUSD } from '@/utils/formats'
import { toDecimalPlaces } from '@/utils/helpers/toDecimalPlaces'

interface CreatorVaultsMobileRowProps {
  row: CreatorSmartVault & IncentivizeVault
}

const CreatorVaultsMobileRow: React.FC<CreatorVaultsMobileRowProps> = ({
  row,
}) => {
  const { modals } = useModalState()

  const actionModalType = modals['actionModal']?.modalType

  const t = useLocale()
  const { chain } = useConnectionState()

  const { userClaimablewNFTs } = useUserClaimableNfts()

  const { connector, chainId } = useWeb3React()

  const {
    name,
    address,
    assetGroup,
    adjustedApy,
    deposits,
    pendingWithdrawals,
    performanceFeesEarned,
    managementFeesEarned,
    incentivizedRewards,
    userBalance,
  } = row

  const { selectedVaults, setSelectedVaults, setWithdrawEarningsVault } =
    useCreatorDashboardState()

  const { openModal, setModalType } = useModalState()

  const navigate = useNavigate()

  const onRowClick = () => {
    navigate(`${Routes(chain).smartVaults.root.url}/${address}`)
  }

  const isSelected = useMemo(
    () => !!selectedVaults.find((vault) => vault.address === address),
    [selectedVaults, row]
  )

  const onDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const updatedVaults = isSelected
      ? selectedVaults.filter((vault) => vault.address !== address)
      : [...selectedVaults, row]

    setSelectedVaults(updatedVaults)
  }

  const vaultClaimableNFTs = useMemo(() => {
    if (!userClaimablewNFTs) return []

    return userClaimablewNFTs.filter(({ vaultId }) => vaultId === address)
  }, [userClaimablewNFTs])

  return (
    <>
      <Box
        padding='1rem'
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='4px'
        tabIndex={-1}
        display='flex'
        flexDirection='column'
        gap='1rem'
        onClick={onRowClick}
        mb='1rem'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex'>
            <Box>
              <AssetGroupTokens assetGroup={assetGroup} />
            </Box>
            <Box display='flex' justifyContent='space-between' pl='0.5rem'>
              <NameCellContent>
                <SmartVaultNameTableTypography fontSize='16px'>
                  {name}
                </SmartVaultNameTableTypography>
              </NameCellContent>
            </Box>
          </Box>
          <Box>
            <CreatorVaultsTableRowOptions row={row} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' fontSize='14px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.apy'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {toDecimalPlaces(adjustedApy, 2)} %
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.tvr'
            )}
          </Typography>
          <Typography fontSize='14px'>
            {currency} {formatUSD(deposits.total)}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' fontSize='14px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.performanceFees'
            )}
          </Typography>
          <Typography
            fontSize='14px'
            color={`${+performanceFeesEarned > 0.099 ? '#2E7D32' : ''}`}
          >
            {+performanceFeesEarned > 0.099 && '+'}$
            {formatUSD(performanceFeesEarned ?? '0')}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' fontSize='14px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.managementFees'
            )}
          </Typography>
          <Typography
            fontSize='14px'
            color={`${+managementFeesEarned > 0.099 ? '#2E7D32' : ''}`}
          >
            {+managementFeesEarned > 0.099 && '+'}$
            {formatUSD(managementFeesEarned ?? '0')}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography color='text.secondary' fontSize='14px'>
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.mobileTitles.availableToWithdraw'
            )}
          </Typography>
          <Typography fontSize='14px'>
            ${formatUSD(userBalance ?? '0')}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box
            fontSize='14px'
            color='#006BA6'
            sx={{ textTransform: 'none', padding: '0', cursor: 'pointer' }}
            onClick={(event: any) => {
              event.stopPropagation()
              event.preventDefault()
              if (!+userBalance) {
                setModalType({
                  warningModalType: {
                    message: t(
                      'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.noEarnings'
                    ),
                  },
                  ...actionModalType,
                })
                openModal(Modals.WARNING_MODAL)
                return
              }

              if (chainId !== chain) {
                connector.activate(chain)
                return
              }
              setWithdrawEarningsVault(row)
              setModalType({
                warningModalType: {
                  message: t(
                    'components.organisms.dashboard.creator.creatorVaultsTable.row.warning.claimEarnings'
                  ),
                  isActionRequired: true,
                  action: () => openModal(Modals.WITHDRAW_MODAL),
                },
                ...actionModalType,
              })
              openModal(Modals.WARNING_MODAL)
            }}
          >
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.row.action'
            )}
          </Box>
          {!!incentivizedRewards?.rewards.length && (
            <Box
              display='flex'
              alignItems='center'
              onClick={(event: any) => onDropdownClick(event)}
            >
              <Typography fontSize='14px'>Show details</Typography>
              <IconButton sx={{ padding: '0px' }} size='small'>
                {isSelected ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Box>
          )}
        </Box>
        <CreatorVaultsTableRowExpand
          row={row}
          vaultClaimableNFTs={vaultClaimableNFTs}
        />
        {(!!vaultClaimableNFTs?.length ||
          !!Number(pendingWithdrawals.total)) && (
          <CreatorVaultsMobileRowWithdrawal
            vaultClaimableNFTs={vaultClaimableNFTs}
            row={row}
          />
        )}
      </Box>
    </>
  )
}

export default memo(CreatorVaultsMobileRow)
