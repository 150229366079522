import { Box } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useRewardDetails from '@/hooks/staking/useRewardDetails'

import SectionItem from '@/components/molecules/SectionItem'
import AmountText from '@/components/organisms/Staking/AmountText'

const YourClaimableRewards = () => {
  const t = useLocale()

  const { account } = useWeb3React()

  const { rewardDetails } = useRewardDetails(account)

  return (
    <SectionItem
      title={t(
        'components.organisms.staking.rewards.sectionItems.claimableRewards.title'
      )}
    >
      <Box display='flex' alignItems='baseline' sx={{ pb: 1.7 }}>
        <AmountText
          rewards={rewardDetails?.claimableRewards}
          displayPriceFeed
        />
      </Box>
    </SectionItem>
  )
}

export default YourClaimableRewards
