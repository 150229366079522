import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { CreatorSmartVault, IncentivizeVault } from '@solidant/spool-v2-fe-lib'
import { useState } from 'react'

import useLocale from '@/hooks/context/useLocale'
import useModalState from '@/hooks/context/useModalState'

import { Modals } from '@/store/modal/modals.types'

interface CreatorVaultsTableRowOptionsProps {
  row: CreatorSmartVault & IncentivizeVault
}

const CreatorVaultsTableRowOptions: React.FC<
  CreatorVaultsTableRowOptionsProps
> = ({ row }) => {
  const { openModal, setModalType } = useModalState()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }
  const t = useLocale()

  return (
    <>
      <IconButton
        aria-controls={open ? 'investor-vaults-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='investor-vaults-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box py={2}>
          <MenuItem
            onClick={(event) => {
              setModalType({
                incentivizeVaultModalType: { vault: row },
              })
              openModal(Modals.INCENTIVIZE_VAULT_MODAL)
              handleClose(event)
            }}
          >
            {t(
              'components.organisms.dashboard.creator.creatorVaultsTable.menuOption.incentivizeVault'
            )}
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
}

export default CreatorVaultsTableRowOptions
