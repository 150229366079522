import { alpha, Box, Card, useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { Fragment, memo, useCallback } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import { mapAssetTokenIcon, tokenDetails } from '@/components/atoms/Tokens'

import { tokenAddresses } from '@/constants/tokens'
import { TokenAddress } from '@/constants/web3'

import { CustomLayerComponentProps } from '@/types/dashboard'

interface StakedPieChartProps {
  staked: string
  total: string
}

const StakedPieChart: React.FC<StakedPieChartProps> = ({ staked, total }) => {
  const theme = useTheme()

  const { chain } = useConnectionState()

  const pieData = [
    {
      id: 'Unstaked',
      label: 'SPOOL Tokens',
      value: Number(total) - Number(staked),
      color: alpha(theme.palette.primary.main, 0.5),
    },
    {
      id: 'Staked',
      label: 'SPOOL Tokens',
      value: Number(staked),
      color: alpha(theme.palette.primary.main, 1),
    },
  ]

  const CustomLayerComponent = useCallback(
    () => (layerProps: CustomLayerComponentProps) => {
      const { centerX, centerY, dataWithArc, radius } = layerProps

      let topLeft = 0,
        topRight = 0,
        bottomLeft = 0,
        bottomRight = 0

      return (
        <>
          {dataWithArc.map((datum, index) => {
            const middleAngle = (datum.arc.startAngle + datum.arc.endAngle) / 2
            const middleAngleDeg = (middleAngle * 180) / Math.PI
            const isRightSide = middleAngleDeg >= 0 && middleAngleDeg < 180
            const isTopSide = middleAngleDeg < 90 || middleAngleDeg > 270

            const middleAngleXY =
              (3 * Math.PI) / 2 +
              (datum.arc.startAngle + datum.arc.endAngle) / 2
            const lineOneStartX =
              centerX + radius * 0.8 * Math.cos(middleAngleXY)
            const lineOneStartY =
              centerY + radius * 0.8 * Math.sin(middleAngleXY)

            let lineOneEndX, lineOneEndY, lineTwoEndX

            if (isRightSide && isTopSide) {
              lineOneEndX = 250
              lineOneEndY = -30 + topRight * 80
              lineTwoEndX = lineOneEndX + 100
              topRight++
            } else if (isRightSide && !isTopSide) {
              lineOneEndX = 250
              lineOneEndY = 210 - bottomRight * 80
              lineTwoEndX = lineOneEndX + 100
              bottomRight++
            } else if (!isRightSide && !isTopSide) {
              lineOneEndX = 120
              lineOneEndY = 105 - bottomLeft * 80
              lineTwoEndX = lineOneEndX - 100
              bottomLeft++
            } else {
              lineOneEndX = 120
              lineOneEndY = -30 + topLeft * 80

              lineTwoEndX = lineOneEndX - 100
              topLeft++
            }
            const lineTwoEndY = lineOneEndY

            return (
              <Fragment key={index}>
                <style>
                  {`
                                .normal {
                                    font-size: 14px;
                                    font-weight: 500;
                                    fill: rgba(33, 35, 34, 1);
                                }
                                .thin {
                                    font-size: 12px;
                                    font-weight: 400;
                                    fill: rgba(33, 35, 34, 0.6);
                                }
                            `}
                </style>
                <svg
                  width='100'
                  height='100'
                  viewBox='0 0 100 100'
                  preserveAspectRatio='xMidYMid meet'
                  x={lineTwoEndX - (isRightSide ? 15 : 0)}
                  y={lineTwoEndY - 20}
                  textAnchor={!isRightSide ? 'start' : 'end'}
                >
                  {mapAssetTokenIcon(chain, tokenAddresses[chain].SPOOL)}
                </svg>
                <line
                  x1={lineOneStartX}
                  y1={lineOneStartY}
                  x2={lineOneEndX}
                  y2={lineOneEndY}
                  stroke='rgba(33, 35, 34, 0.6)'
                  strokeWidth='0.5'
                />
                <line
                  x1={lineOneEndX}
                  y1={lineOneEndY}
                  x2={lineTwoEndX}
                  y2={lineTwoEndY}
                  stroke='rgba(33, 35, 34, 0.6)'
                  strokeWidth='0.5'
                />
                <text
                  className='normal'
                  x={lineTwoEndX}
                  y={lineTwoEndY + 20}
                  textAnchor={!isRightSide ? 'start' : 'end'}
                >
                  {datum.id}
                </text>
                <text
                  className='normal'
                  x={lineTwoEndX}
                  y={lineTwoEndY + 40}
                  textAnchor={!isRightSide ? 'start' : 'end'}
                >
                  {datum.label}
                </text>
                <text
                  className='thin'
                  x={lineTwoEndX}
                  y={lineTwoEndY + 60}
                  textAnchor={!isRightSide ? 'start' : 'end'}
                >
                  {((datum.value * 100) / Number(total)).toFixed(1)} %
                </text>
              </Fragment>
            )
          })}
        </>
      )
    },
    [pieData]
  )

  return (
    <Box height='240px' width='368px'>
      <ResponsivePie
        data={pieData}
        margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
        arcLinkLabel=''
        // @ts-ignore: type mismatch because of the library definition and actual implementation
        layers={['arcs', 'legends', CustomLayerComponent()]}
        enableArcLabels={false}
        colors={{ datum: 'data.color' }}
        tooltip={({ datum: { label, value } }) => (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '2px 4px',
              background: '#ffffff',
            }}
          >
            <Box fontSize={12}>{label}</Box>
            <Box fontSize={14}>
              <strong>
                {value.toFixed(0)}{' '}
                {tokenDetails[chain][TokenAddress.SPOOL].symbol}
              </strong>
            </Box>
          </Card>
        )}
      />
    </Box>
  )
}

export default memo(StakedPieChart)
