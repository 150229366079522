import { Box, Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'

import useLocale from '@/hooks/context/useLocale'
import useClaimAndStakeStakingRewards from '@/hooks/staking/transactions/useClaimAndStakeStakingRewards'
import useClaimStakingRewards from '@/hooks/staking/transactions/useClaimStakingRewards'
import useRewardDetails from '@/hooks/staking/useRewardDetails'
import useStakedSpool from '@/hooks/staking/useStakedSpool'

import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import AccumulatedVoSpool from '@/components/organisms/Staking/StakingInfo/AccumulatedVoSpool'
import EndGoal from '@/components/organisms/Staking/StakingInfo/EndGoal'
import NextVoSpoolEpochRewards from '@/components/organisms/Staking/StakingInfo/NextVoSpoolEpochRewards'
import ReturnsFromStaking from '@/components/organisms/Staking/StakingInfo/ReturnsFromStaking'
import SpoolPerVoSpool from '@/components/organisms/Staking/StakingInfo/SpoolPerVoSpool'
import TotalStakingRewards from '@/components/organisms/Staking/StakingInfo/TotalStakingRewards'
import VoSpoolEpochTimer from '@/components/organisms/Staking/StakingInfo/VoSpoolEpochTimer'
import YourClaimableRewards from '@/components/organisms/Staking/StakingInfo/YourClaimableRewards'

import { StakingPageProps } from '@/types/staking'

const StakingRewards: React.FC<StakingPageProps> = () => {
  const t = useLocale()
  const { account } = useWeb3React()

  const { rewardDetails } = useRewardDetails(account)
  const { stakedSpool, isLoading } = useStakedSpool(account)

  const claimStakingRewards = useClaimStakingRewards()
  const claimAndStakeStakingRewards = useClaimAndStakeStakingRewards()

  return (
    <Section>
      <SectionColumn
        title={t('components.organisms.staking.rewards.sectionTitle')}
      >
        <Box
          display='flex'
          gap={(theme) => theme.spacing(2)}
          sx={{ '& > *': { flexGrow: 1 } }}
        >
          <YourClaimableRewards />
          <TotalStakingRewards />
        </Box>

        <Box display='flex' gap={(theme) => theme.spacing(2)}>
          <Button
            variant='contained'
            size='large'
            onClick={claimStakingRewards}
            disabled={
              // page === 'stake' ||
              // page === 'unstake' ||
              !account || !Number(rewardDetails?.claimableRewards[0].amount)
            }
          >
            {t('components.organisms.staking.rewards.buttons.claiming.label')}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={claimAndStakeStakingRewards}
            disabled={
              // page === 'stake' ||
              // page === 'unstake' ||
              !account || !Number(rewardDetails?.claimableRewards[0].amount)
            }
          >
            {t(
              'components.organisms.staking.rewards.buttons.claimingAndStaking.label'
            )}
          </Button>
        </Box>

        {account && !isLoading && !!Number(stakedSpool) && (
          <>
            <Box
              display='flex'
              gap={(theme) => theme.spacing(2)}
              sx={{ '& > *': { flexGrow: 1 } }}
            >
              <AccumulatedVoSpool />
              <EndGoal />
            </Box>
            <Box
              display='flex'
              gap={(theme) => theme.spacing(2)}
              sx={{ '& > *': { flexGrow: 1 } }}
            >
              <ReturnsFromStaking />
            </Box>
          </>
        )}
      </SectionColumn>

      <SectionColumn title='&zwnj;'>
        <VoSpoolEpochTimer />
        <NextVoSpoolEpochRewards />
        <SpoolPerVoSpool />
      </SectionColumn>
    </Section>
  )
}

export default StakingRewards
