import { Radio, styled, TableCell, TableRow, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { memo, useCallback, useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'

import StrategiesTooltip from '@/components/organisms/CreateVault/RiskModel/Tooltips/StrategiesTooltip'

import { RiskModelDto } from '@/types/create-vault'

interface RiskModelTableRowProps {
  riskModelData: RiskModelDto
}

const ListBox = styled(Box)(() => ({
  maxWidth: '300px',
  whiteSpace: 'pre-wrap',
  paddingTop: '1rem',
  fontSize: '14px',
}))

const RiskModelTableRow: React.FC<RiskModelTableRowProps> = ({
  riskModelData,
}) => {
  const {
    riskModelId,
    description,
    // documentationLink,
    riskModelName,
    riskFactors,
    strategies,
  } = riskModelData

  const { selectedRiskModel, setSelectedRiskModel, setStepCompletedStatus } =
    useCreateVaultState()

  const [rowHovered, setRowHovered] = useState<boolean>(false)

  const t = useLocale()

  const handleSelectedRiskModel = useCallback(
    (model: string) => {
      selectedRiskModel === model
        ? setSelectedRiskModel('')
        : setSelectedRiskModel(model)
    },
    [selectedRiskModel]
  )

  const isSelected = selectedRiskModel === riskModelId

  useEffect(() => {
    if (selectedRiskModel) {
      setStepCompletedStatus(1, true)
      return
    }
    setStepCompletedStatus(1, false)
    return
  }, [selectedRiskModel])

  return (
    <>
      <TableRow
        key={riskModelId}
        role='checkbox'
        tabIndex={-1}
        sx={{
          '& > td': { borderBottom: 'unset' },
          backgroundColor:
            rowHovered && !isSelected
              ? 'rgba(0, 0, 0, 0.04)'
              : isSelected
              ? 'rgba(42, 162, 202, 0.12)'
              : '#fff',
          userSelect: 'none',
        }}
        onClick={() => handleSelectedRiskModel(riskModelId)}
        onMouseEnter={() => setRowHovered(true)}
        onMouseLeave={() => setRowHovered(false)}
      >
        <TableCell padding='none' sx={{ minWidth: '0px' }}>
          <Radio
            color='primary'
            checked={isSelected}
            sx={{ paddingRight: '2px' }}
            disableRipple
          />
        </TableCell>
        <TableCell>
          {/* {mapCoinSvg(image)} */}
          <Typography
            variant='body2'
            sx={{
              overflowWrap: 'anywhere',
              whiteSpace: 'normal',
            }}
            fontSize='20px'
            fontWeight={300}
          >
            {riskModelName}
          </Typography>
        </TableCell>
        <TableCell align='right'>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            gap='0.2rem'
          >
            <Tooltip
              title={<StrategiesTooltip strategies={strategies} />}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '800px',
                  },
                },
              }}
              arrow
              sx={{
                padding: '0.25rem 0.5rem',
                borderRadius: '6.25rem',
                border: '1px solid #006BA6',
                width: 'fit-content',
              }}
            >
              <Typography color='primary.main' align='center' variant='body2'>
                {t(
                  'components.organisms.createVault.riskModel.tableRow.strategies.label'
                )}{' '}
                {strategies.length}
              </Typography>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align='left' width='350px' sx={{ verticalAlign: 'top' }}>
          <ListBox padding='1rem 0'>
            <ol type='1' style={{ padding: '0px 16px', margin: 0 }}>
              {riskFactors &&
                riskFactors.map((riskFactor, index) => (
                  <li key={index}>{riskFactor}</li>
                ))}
            </ol>
          </ListBox>
        </TableCell>
        <TableCell align='left'>
          <ListBox max-width='300px' whiteSpace='pre-wrap'>
            <ul
              style={{
                padding: '0px 16px',
                margin: 0,
                marginBottom: '0.6rem',
                listStyleType: 'none',
              }}
            >
              {description &&
                description.map((description, index) => (
                  <li key={index}>{description}</li>
                ))}
            </ul>
          </ListBox>
        </TableCell>
      </TableRow>
      {/*<TableRow
        role='checkbox'
        tabIndex={-1}
        sx={{
          '& > td': { borderBottom: 'unset' },
          backgroundColor:
            rowHovered && !isSelected
              ? 'rgba(0, 0, 0, 0.04)'
              : isSelected
              ? 'rgba(42, 162, 202, 0.12)'
              : '#fff',
          userSelect: 'none',
        }}
        onMouseEnter={() => setRowHovered(true)}
        onMouseLeave={() => setRowHovered(false)}
        onClick={() => handleSelectedRiskModel(riskModelId)}
      >
        <TableCell colSpan={3}></TableCell>
        <TableCell align='left' colSpan={2}>
          <Box display='flex' flexDirection='column'>
            <Breadcrumbs aria-label='breadcrumbs'>
              <Typography variant='overline'>
                {t(
                  'components.organisms.createVault.riskModel.tableRow.breadcrumbs.label'
                )}
              </Typography>
            </Breadcrumbs>
            <Typography sx={{ fontSize: '14px' }}>
              {t(
                'components.organisms.createVault.riskModel.tableRow.documentation.description'
              )}
            </Typography>
            <Link
              width='fit-content'
              href={documentationLink}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                variant='outlined'
                sx={{ width: 'fit-content', my: '0.6rem' }}
              >
                {t(
                  'components.organisms.createVault.riskModel.tableRow.documentation.buttonLabel'
                )}
              </Button>
            </Link>
          </Box>
        </TableCell>
      </TableRow>*/}
    </>
  )
}

export default memo(RiskModelTableRow)
