import { Box, Typography } from '@mui/material'

import LinearProgressWithLabel from '@/components/atoms/LinearProgressWithLabel'
import { SectionItemWithInfoIconContainer } from '@/components/atoms/SectionItemWithInfoIconContainer'
import GovernanceOverviewSectionItem from '@/components/organisms/Governance/GovernanceOverviewSectionitem'

import { formatNumber } from '@/utils/formats'

interface VoSpoolOverview {
  title: string
  subtitle: string
  amount: string
  totalAmount: string
}

const VoSpoolOverview: React.FC<VoSpoolOverview> = ({
  title,
  subtitle,
  amount,
  totalAmount,
}) => {
  return (
    <GovernanceOverviewSectionItem
      title={title}
      subtitle={subtitle}
      headerClass='section-item-header-column'
    >
      <SectionItemWithInfoIconContainer marginTop='0.2rem'>
        <Box display='flex' alignItems='baseline' width='156px'>
          <Typography variant='body1'>
            {!isNaN(Number(amount)) ? formatNumber(Number(amount)) : 0}
          </Typography>
          <Typography variant='body1'>
            &nbsp;of{' '}
            {!isNaN(Number(totalAmount))
              ? formatNumber(Number(totalAmount))
              : 0}
          </Typography>
        </Box>
        <Box width='35%'>
          <LinearProgressWithLabel
            value={
              Number(totalAmount) > 0 && Number(amount) > 0
                ? (Number(amount) / Number(totalAmount)) * 100
                : 0
            }
          />
        </Box>
      </SectionItemWithInfoIconContainer>
    </GovernanceOverviewSectionItem>
  )
}

export default VoSpoolOverview
