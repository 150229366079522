import { Box } from '@mui/material'
import dayjs, { extend, unix } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useEffect, useState } from 'react'

import ChipTime from '@/components/molecules/ChipTime'

extend(duration)

interface CountdownTimerProps {
  endTime?: number
  expanded?: boolean
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  endTime,
  expanded,
}) => {
  const [timer, setTimer] = useState<duration.Duration>(
    dayjs.duration({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    })
  )

  useEffect(() => {
    let intervalId: number

    if (endTime) {
      const interval = 1000

      intervalId = window.setInterval(() => {
        const duration = dayjs.duration(unix(endTime).diff(dayjs()))
        setTimer(duration)
      }, interval)
    }

    return () => window.clearInterval(intervalId)
  }, [endTime])

  return (
    <Box
      display='flex'
      justifyContent='center'
      gap={(theme) => theme.spacing(1)}
      py={expanded && 5.6}
    >
      <ChipTime value={timer.days()} background unit='Days' />
      <ChipTime value={timer.hours()} background unit='Hours' />
      <ChipTime value={timer.minutes()} background unit='Mins' />
      <ChipTime value={timer.seconds()} background unit='Secs' />
    </Box>
  )
}

export default CountdownTimer
