import dayjs, { extend, unix } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useEffect, useState } from 'react'

extend(duration)

const useCountdown = (timestamp: number) => {
  const [timer, setTimer] = useState(
    dayjs.duration({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  )

  useEffect(() => {
    const currentTime = dayjs().unix()

    if (!timestamp || currentTime > timestamp) return

    const updateTimer = () => {
      const timeRemaining = unix(timestamp).diff(dayjs())
      const durationTime = dayjs.duration(timeRemaining)
      setTimer(durationTime)
    }

    updateTimer()

    const intervalId = setInterval(updateTimer, 1000)

    return () => clearInterval(intervalId)
  }, [timestamp])

  return timer
}

export default useCountdown
