import {
  Box,
  Button,
  Input,
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'

import useCreateVaultState from '@/hooks/context/useCreateVaultState'
import useLocale from '@/hooks/context/useLocale'
import useDebounce from '@/hooks/utils/useDebounce'

import IncrementDecrementContainer from '@/components/atoms/IncrementDecrementContainer'
import NumericFormatCustom from '@/components/atoms/NumericFormatCustom'
import CustomSlider from '@/components/molecules/CustomSlider/CustomSlider'

import { PERFORMANCE_FEE_OPTIONS } from '@/constants/createVault'

const PerformanceFeeConfiguration: React.FC = () => {
  const t = useLocale()

  const { max, min, step } = PERFORMANCE_FEE_OPTIONS

  const [disabled, setDisabled] = useState<boolean>(false)

  const { selectedPerformanceFee, setSelectedPerformanceFee } =
    useCreateVaultState()

  const [value, setValue] = useState(selectedPerformanceFee)

  const debouncedValue = useDebounce(value.toString())

  useEffect(() => {
    setSelectedPerformanceFee(Number(debouncedValue))
  }, [debouncedValue])

  const handleBeforeChange = () => setDisabled(true)

  const handleAfterChange = () => {
    setDisabled(false)
  }

  const handleFeeIncrement = () => {
    if (value === max) {
      return
    }
    const newFee = value + step
    const roundedFee = Math.round((newFee + Number.EPSILON) * 100) / 100
    setValue(roundedFee)
  }

  const handleFeeDecrement = () => {
    if (value === min) {
      return
    }
    const newFee = value - step
    const roundedFee = Math.round((newFee + Number.EPSILON) * 100) / 100
    setValue(roundedFee)
  }

  const handleFitPerformanceFeeInRange = () => {
    let newPerformanceFee = value
    if (value < min) {
      newPerformanceFee = min
    }
    if (value > max) {
      newPerformanceFee = max
    }
    setValue(newPerformanceFee)
  }

  return (
    <Box
      paddingTop='3rem'
      paddingBottom='2rem'
      display='flex'
      bgcolor='#FFFFFF'
      border='1px solid rgba(0, 0, 0, 0.12)'
      borderRadius='4px'
      justifyContent='center'
    >
      <Box display='flex' marginLeft='1rem'>
        <Button
          variant='contained'
          sx={{ height: '53px', width: '90px', marginTop: '1rem' }}
          disabled={value === min || disabled}
          onClick={() => setValue(min)}
        >
          {t(
            'components.organisms.createVault.launch.performanceFee.configuration.minButton.label'
          )}
        </Button>
        <CustomSlider
          range={PERFORMANCE_FEE_OPTIONS}
          rangeLabel={PERFORMANCE_FEE_OPTIONS}
          step={step}
          rangeSuffix='%'
          value={value}
          onChange={setValue}
          onBeforeChange={handleBeforeChange}
          onAfterChange={handleAfterChange}
        />
        <Button
          onClick={() => setValue(max)}
          disabled={value === max || disabled}
          variant='contained'
          sx={{ height: '53px', width: '90px', marginTop: '1rem' }}
        >
          {t(
            'components.organisms.createVault.launch.performanceFee.configuration.maxButton.label'
          )}
        </Button>
      </Box>
      <Box display='flex' marginLeft='1.5rem'>
        <IncrementDecrementContainer
          onClick={handleFeeDecrement}
          sx={{
            borderRadius: '4px 0px 0px 4px',
            borderColor: 'primary.main',
            '&:hover': {
              borderColor: 'primary.main',
            },
          }}
        >
          -
        </IncrementDecrementContainer>
        <Box marginTop='1rem' height='53px' mx='0.5rem'>
          <Typography
            textAlign='center'
            fontSize='12px'
            color='rgba(0, 0, 0, 0.6)'
            fontWeight={400}
          >
            {t('components.organisms.createVault.launch.performanceFee.title')}
          </Typography>
          <Input
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
            onBlur={handleFitPerformanceFeeInRange}
            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
            inputProps={{
              sx: {
                height: '26px',
                textAlign: 'center',
                color: (value < min || value > max) && 'error.main',
              },
            }}
            sx={{ width: '130px' }}
            error={value < min || value > max}
            inputComponent={
              NumericFormatCustom as unknown as React.ElementType<InputBaseComponentProps>
            }
          />
        </Box>
        <IncrementDecrementContainer
          onClick={handleFeeIncrement}
          sx={{
            borderRadius: '0px 4px 4px 0px',
            borderColor: 'primary.main',
            '&:hover': {
              borderColor: 'primary.main',
            },
          }}
        >
          +
        </IncrementDecrementContainer>
      </Box>
    </Box>
  )
}

export default PerformanceFeeConfiguration
