import { EmissionVault, SortOrder } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { memo, useCallback, useMemo, useReducer } from 'react'

import useGovernanceDetails from '@/hooks/governance/useGovernanceDetails'

import {
  AllocationContext,
  initialState,
} from '@/store/governance/allocation.context'
import allocationReducer from '@/store/governance/allocation.reducer'
import { AllocationActionTypes } from '@/store/governance/allocation.types'

import { EmissionVaultWithSorting } from '@/types/governance'
import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const AllocationProvider = memo(({ children }: RCNode) => {
  const [allocationState, dispatch] = useReducer(
    allocationReducer,
    initialState
  )

  const { account } = useWeb3React()

  const { governanceDetails } = useGovernanceDetails(account)

  const setAllocation = (address: string, amount: string) => {
    dispatch({
      type: AllocationActionTypes.SET_ALLOCATION,
      address: address,
      amount: amount,
    })
  }

  const getTotalAllocation = useCallback(() => {
    return Object.values(allocationState.allocation).reduce((prev, curr) => {
      return prev + +curr
    }, 0)
  }, [allocationState])

  const handleSortClick = useCallback(
    (
      key: keyof EmissionVault,
      sortType: SortType<EmissionVault>,
      allocation: { [key: string]: string }
    ) => {
      const totalAllocation = Object.values(allocation).reduce((prev, curr) => {
        return prev + +curr
      }, 0)

      const overallAllocation =
        Number(governanceDetails.emissionVaults.totalAllocationAmount) +
        totalAllocation

      const filterableEmmissionVaults = governanceDetails.isActiveProposal
        ? governanceDetails.emissionVaults.vaults.map((vault) => {
            const { amount } = vault.allocation

            const totalRowAllocation =
              Number(amount) + Number(allocation[vault.address] || 0)

            if (!allocation[vault.address]) {
              return {
                ...vault,
                sortableAllocationWeight: (
                  totalRowAllocation / overallAllocation
                ).toString(),
                sortableAllocationAmount: '0',
              }
            }

            return {
              ...vault,
              sortableAllocationWeight: (
                totalRowAllocation / overallAllocation
              ).toString(),
              sortableAllocationAmount: allocation[vault.address],
            }
          })
        : governanceDetails.emissionVaults.vaults.map((vault) => {
            return {
              ...vault,
              sortableAllocationWeight: (
                +vault.allocation.weight * 100
              ).toString(),
              sortableAllocationAmount: governanceDetails.userVotedChoice[
                vault.address
              ]
                ? governanceDetails.userVotedChoice[vault.address].amount
                : '0',
            }
          })

      dispatch({
        type: AllocationActionTypes.SET_FILLED_EMISSION_VAULTS,
        payload: filterableEmmissionVaults,
      })

      if (!sortType?.key || sortType?.key !== key) {
        dispatch({
          type: AllocationActionTypes.SET_GOVERNANCE_SORT,
          payload: {
            key,
            direction: SortOrder.DESC,
          },
        })
        return
      }

      dispatch({
        type: AllocationActionTypes.CHANGE_GOVERNANCE_SORT_DIRECTION,
      })
    },
    [] as EmissionVaultWithSorting[]
  )

  const contextValue = useMemo(() => {
    return {
      ...allocationState,
      handleSortClick,
      setAllocation,
      getTotalAllocation,
    }
  }, [allocationState])

  return (
    <AllocationContext.Provider value={contextValue}>
      {children}
    </AllocationContext.Provider>
  )
})
