import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography } from '@mui/material'
import { TimeConversions } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useGovernanceDetails from '@/hooks/governance/useGovernanceDetails'
import useCountdown from '@/hooks/utils/useCountdown'

import { EgovWarning } from '@/constants/goverance'
import { sleep } from '@/utils/web3'

const VOTING_EPOCH_ACTIVE_DAYS = 8

const egovAlert = {
  [EgovWarning.BeforeStart]:
    'Use your voSPOOL vote to allocate monthly $SPOOL emissions to the vaults of your choice. Next Voting starts on ',
  [EgovWarning.During]:
    'Use your voSPOOL vote to allocate monthly $SPOOL emissions to the vaults of your choice.',
  [EgovWarning.BeforeEnd]:
    'Use your voSPOOL vote to allocate monthly $SPOOL emissions to the vaults of your choice.',
} as Record<EgovWarning, string>

interface EgovAlertProps {
  egovWarningPeriod: EgovWarning
  setOpenEgovAlert: React.Dispatch<React.SetStateAction<boolean>>
}

const EgovAlert: React.FC<EgovAlertProps> = ({
  egovWarningPeriod,
  setOpenEgovAlert,
}) => {
  const { account } = useWeb3React()

  const [bannerHeight, setBannerHeight] = useState('0px')

  const { governanceDetails } = useGovernanceDetails(account)

  const timer = useCountdown(governanceDetails?.endTime)

  const closeEgovAlertBanner = useCallback(() => {
    if (governanceDetails.isActiveProposal) {
      localStorage.setItem(
        'egovBannerResetTime',
        governanceDetails.endTime.toString()
      )
      setOpenEgovAlert(false)
      return
    }

    const nextEpochVotingEndTime =
      governanceDetails.nextVotingEpoch +
      VOTING_EPOCH_ACTIVE_DAYS * TimeConversions.SECONDS_PER_DAY

    localStorage.setItem(
      'egovBannerResetTime',
      nextEpochVotingEndTime.toString()
    )
    setOpenEgovAlert(false)
  }, [governanceDetails])

  useEffect(() => {
    sleep(1000)
    setBannerHeight('45px')
  }, [])

  return (
    <Box
      sx={{
        background: 'linear-gradient(270deg, #CC4278 0%, #4887BD 100%)',
        transition: 'height 500ms ease-out',
        animationDelay: '500ms',
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height={bannerHeight}
      px='1.5rem'
    >
      <Box
        display='flex'
        width='100%'
        maxWidth='1250px'
        justifyContent='space-between'
      >
        <Box display='flex' alignItems='center'>
          <Typography
            ml='1rem'
            fontSize='16px'
            variant='subtitle2'
            color='#ffffff'
          >
            {egovAlert[egovWarningPeriod]}{' '}
          </Typography>
          &nbsp;
          {egovWarningPeriod === EgovWarning.BeforeStart ? (
            <Typography fontSize='16px' variant='subtitle2' color='#ffffff'>
              {dayjs(governanceDetails.nextVotingEpoch * 1000).format('D.M.YY')}
            </Typography>
          ) : (
            egovWarningPeriod === EgovWarning.BeforeEnd && (
              <>
                <Typography fontSize='16px' variant='subtitle2' color='#ffffff'>
                  ENDS IN {timer.days()}:{timer.hours()}:{timer.minutes()}
                </Typography>
              </>
            )
          )}
        </Box>

        <Box display='flex' color='#ffffff' alignItems='center'>
          {egovWarningPeriod !== EgovWarning.BeforeStart && (
            <Box
              component={Link}
              to='eth/governance/voting'
              fontSize='16px'
              color='#ffffff'
              padding='0.3rem'
              borderRadius='0.2rem'
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: 'white !important',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
              mr='1rem'
            >
              VOTE NOW
            </Box>
          )}
          <CloseIcon
            onClick={closeEgovAlertBanner}
            sx={{ cursor: 'pointer' }}
            color='inherit'
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EgovAlert
