import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { createContext } from 'react'

import {
  AllocationContextProps,
  AllocationState,
} from '@/store/governance/allocation.types'

export const initialState: AllocationState = {
  allocation: {},
  governanceSort: {
    key: 'name',
    direction: SortOrder.DESC,
  },
  filledEmissionVaults: [],
}

export const AllocationContext = createContext<AllocationContextProps>({
  ...initialState,
  handleSortClick: () => undefined,
  getTotalAllocation: () => undefined,
  setAllocation: () => undefined,
})
