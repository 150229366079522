import EqualAllocation from '@/assets/icons/allocationProviders/EqualAllocation'
import ExponentialNormalised from '@/assets/icons/allocationProviders/ExponentialNormalised'
import FixedAllocation from '@/assets/icons/allocationProviders/FixedAllocation'
import Linear from '@/assets/icons/allocationProviders/Linear'

export type AllocationProvider = Record<
  string,
  {
    name: string
    type?: string
    description: string
    icon: JSX.Element
    isReady: boolean
  }
>

export const ALLOCATION_PROVIDERS_ETHEREUM: AllocationProvider = {
  '0x7dbadf3cc5c9c7177b6f976910ef84ad90e018a8': {
    name: 'Linear Agent',
    type: 'Normalised',
    description:
      'The agent linearly allocates capital between different strategies',
    icon: <Linear />,
    isReady: true,
  },
  '0xcebf2117a483eff141c25bf8d365b9b81af9112f': {
    name: 'Exponential Agent',
    type: 'Normalised',
    description:
      'The agent exponentially allocates capital between different strategies',
    icon: <ExponentialNormalised />,
    isReady: true,
  },
  '0x6f519d997cd2027f164586718ca248d2384d7db1': {
    name: 'Equal allocation',
    description:
      'All strategies in the vault are allocated according to the same weight',
    icon: <EqualAllocation />,
    isReady: true,
  },
  'Fixed Allocation': {
    name: 'Fixed Allocation',
    type: 'Manual',
    description:
      'Avoid using an agent for capital allocation. Input weight values manually [Coming soon]',
    icon: <FixedAllocation />,
    isReady: false,
  },
}

export const ALLOCATION_PROVIDERS_ETHEREUM_TENDERLY: AllocationProvider = {
  '0x4e1157b40e62782f04d7f4a915f340b8f445b008': {
    name: 'Linear Agent',
    type: 'Normalised',
    description:
      'The agent linearly allocates capital between different strategies',
    icon: <Linear />,
    isReady: true,
  },
  '0x015afc876a4d115e2ff01000d9d3adc714e5870b': {
    name: 'Exponential Agent',
    type: 'Normalised',
    description:
      'The agent exponentially allocates capital between different strategies',
    icon: <ExponentialNormalised />,
    isReady: true,
  },
  '0xe3eca4c4b0bb84532d43db64e505cc6ee750fd7c': {
    name: 'Equal allocation',
    description:
      'All strategies in the vault are allocated according to the same weight',
    icon: <EqualAllocation />,
    isReady: true,
  },
  'Fixed Allocation': {
    name: 'Fixed Allocation',
    type: 'Manual',
    description:
      'Avoid using an agent for capital allocation. Input weight values manually [Coming soon]',
    icon: <FixedAllocation />,
    isReady: false,
  },
}

export const ALLOCATION_PROVIDERS_ARBITRUM: AllocationProvider = {
  '0xb103cd88a0c64f81233f3050b196c14692f2bad9': {
    name: 'Linear Agent',
    type: 'Normalised',
    description:
      'The agent linearly allocates capital between different strategies',
    icon: <Linear />,
    isReady: true,
  },
  '0x848d546969e3374d23756c02f2b8d791d2dee502': {
    name: 'Exponential Agent',
    type: 'Normalised',
    description:
      'The agent exponentially allocates capital between different strategies',
    icon: <ExponentialNormalised />,
    isReady: true,
  },
  '0x35849051e4a46c018df0e3ff498c7d92d3d14457': {
    name: 'Equal allocation',
    description:
      'All strategies in the vault are allocated according to the same weight',
    icon: <EqualAllocation />,
    isReady: true,
  },
  'Fixed Allocation': {
    name: 'Fixed Allocation',
    type: 'Manual',
    description:
      'Avoid using an agent for capital allocation. Input weight values manually [Coming soon]',
    icon: <FixedAllocation />,
    isReady: false,
  },
}

export const ALLOCATION_PROVIDERS_ARBITRUM_TENDERLY: AllocationProvider = {
  '0x44a4199ce697c27e7e162df2ac9ffe8d45be7b9b': {
    name: 'Linear Agent',
    type: 'Normalised',
    description:
      'The agent linearly allocates capital between different strategies',
    icon: <Linear />,
    isReady: true,
  },
  '0x06f37ee4e643a022a3cab3341313a72b091ee0e5': {
    name: 'Exponential Agent',
    type: 'Normalised',
    description:
      'The agent exponentially allocates capital between different strategies',
    icon: <ExponentialNormalised />,
    isReady: true,
  },
  '0xe08be40e1d7434fff403966d93952cbbee64c27f': {
    name: 'Equal allocation',
    description:
      'All strategies in the vault are allocated according to the same weight',
    icon: <EqualAllocation />,
    isReady: true,
  },
  'Fixed Allocation': {
    name: 'Fixed Allocation',
    type: 'Manual',
    description:
      'Avoid using an agent for capital allocation. Input weight values manually [Coming soon]',
    icon: <FixedAllocation />,
    isReady: false,
  },
}

export const ALLOCATION_PROVIDERS_SEPOLIA: AllocationProvider = {
  '0xbd330aecd16d1ff0db8fba6af9838054d194a9c6': {
    name: 'Linear Agent',
    type: 'Normalised',
    description:
      'The agent linearly allocates capital between different strategies',
    icon: <Linear />,
    isReady: true,
  },
  '0x1ad0332a59aafac9f7de822e4a842070a9277c68': {
    name: 'Exponential Agent',
    type: 'Normalised',
    description:
      'The agent exponentially allocates capital between different strategies',
    icon: <ExponentialNormalised />,
    isReady: true,
  },
  '0x02ce10513afaf102172651b7dc6f1a6359717421': {
    name: 'Equal allocation',
    description:
      'All strategies in the vault are allocated according to the same weight',
    icon: <EqualAllocation />,
    isReady: true,
  },
  'Fixed Allocation': {
    name: 'Fixed Allocation',
    type: 'Manual',
    description:
      'Avoid using an agent for capital allocation. Input weight values manually [Coming soon]',
    icon: <FixedAllocation />,
    isReady: false,
  },
}

export const UNAVAILABLE_ALLOCATION_PROVIDERS = [
  '0x8AF453AE6173E9212595fB7ED9C626A9fdEDF9D0',
  'Fixed Allocation',
]
