import { Box, Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'

import SpoolBreadcrumbs from '@/components/atoms/SpoolBreadcrumbs'
import Section from '@/components/molecules/Section'
import SectionColumn from '@/components/molecules/SectionColumn'
import RewardsTable from '@/components/organisms/SmartVaults/Rewards/RewardsTable'

const VaultsRewards = () => {
  const t = useLocale()
  return (
    <>
      <Box>
        <SpoolBreadcrumbs page='smartVaults' />
        <Typography variant='body1'>
          {t('pages.smartVaults.vaultsEarnings.description')}
        </Typography>
      </Box>
      <Section>
        <SectionColumn sx={{ gap: 0 }}>
          <RewardsTable />
        </SectionColumn>
      </Section>
    </>
  )
}

export default VaultsRewards
