import { HeadCell } from '@/types/table'

export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Smart vaults',
    width: '400px',
  },
  {
    id: 'totalDepositedAmount',
    numeric: false,
    label: 'TVR',
    width: '100px',
  },
  {
    id: 'balance',
    numeric: true,
    label: 'balance',
    tableHelperTop: 'Your',
    width: '100px',
    align: 'right',
    textAlign: 'left',
  },
  {
    id: 'apy',
    numeric: true,
    tableHelperTop: 'Current',
    label: 'Total Apy',
    width: '100px',
    align: 'right',
    textAlign: 'left',
  },
  // {
  //   id: 'apyBoost',
  //   numeric: true,
  //   tableHelperTop: 'New',
  //   label: 'Total Apy',
  //   width: '100px',
  //   align: 'right',
  //   notSortable: true,
  //   textAlign: 'left',
  // },
  {
    id: 'allocationAmount',
    numeric: false,
    label: 'My allocation',
  },
  {
    id: 'allocationWeight',
    numeric: false,
    label: 'allocation',
    tableHelperTop: 'Total voted',
    align: 'center',
    width: '160px',
  },
]

export const HARD_CODED = {
  '0x06e1dd156c7f9047436b9809acdb9a473f24cd9a': 0.21455526132940259,
  '0x1b513c42d518bb93b523877f328b035cc177349e': 0.1510564341225829,
  '0x26ea8a1c696d9f5d382ff32cae46608c81776579': 0.08266886919534065,
  '0x2d386637e03b9fa2d1e48a741d19da54d7005b34': 0.08160007343754186,
  '0x2e259e055070acc54a63856f5a0e50f816fb0c61': 0.007235355128113938,
  '0x443fc84fb2b304aa779401b9173320fb3bb61054': 0.007235355128113938,
  '0x4ae18b8e3040866b46ac2c52bdfd7d3455b5db73': 0.007235355128113938,
  '0x7512932db8abb440e205c868904ab4ba27fda204': 0.21455526132940259,
  '0x7d38697e177b0dd9bbfff088f9c900447ab5b20b': 0.1510564341225829,
  '0x7d4bf6dc476a4dd2462364f23ac909d51f48d747': 0.08266886919534065,
  '0x7f3299846803722f18c9db2daa89bc49af20021f': 0.08160007343754186,
  '0x8d4d773a86e06afdc794afae3bc6ff09b55af0c6': 0.007235355128113938,
  '0x8e8337f1aa22a9b372bc5abe768ec849cbeaf07c': 0.007235355128113938,
  '0x9d2e5cab6220b08c8be0d92f906916056b1396da': 0.007235355128113938,
  '0xb0c13435f62079af6921e7b6a1ad73a66bfc5b6e': 0.007235355128113938,
}

export enum EgovWarning {
  BeforeStart,
  During,
  BeforeEnd,
  Quiet,
}
