import { Box, styled, TableCell, TableRow, Typography } from '@mui/material'
import { CreatorFeeVault } from '@solidant/spool-v2-fe-lib'
import { Fragment } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'

import { mapIcons } from '@/components/atoms/CryptoIcons'
import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'

import { currency } from '@/constants/currency'
import { formatPercentages, formatUSD } from '@/utils/formats'

const NameCellContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
}))

interface RewardsTableRowProps {
  row: CreatorFeeVault
}

const RewardsTableRow: React.FC<RewardsTableRowProps> = ({ row }) => {
  const { chain } = useConnectionState()

  const {
    name,
    adjustedApy,
    assetGroup,
    performanceFeeAccrued,
    managementFeesAccrued,
    totalVolumeRouted,
  } = row

  return (
    <TableRow>
      <TableCell>
        <Box display='flex' justifyContent='space-between' minWidth='300px'>
          <NameCellContent>
            {mapIcons(chain)}
            <SmartVaultNameTableTypography variant='body2'>
              {name}
            </SmartVaultNameTableTypography>
          </NameCellContent>
          <NameCellContent>
            {assetGroup &&
              assetGroup?.assetGroupTokens.map(({ address }, index) => (
                <Fragment key={index}>
                  {mapAssetTokenIcon(chain, address ?? '')}
                </Fragment>
              ))}
          </NameCellContent>
        </Box>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2'>
          {formatPercentages(adjustedApy)} %
        </Typography>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2'>
          {currency} {formatUSD(totalVolumeRouted.total ?? 0)}
        </Typography>
      </TableCell>
      <TableCell align='right' size='small'>
        <Box
          display='flex'
          alignItems='center'
          marginLeft='auto'
          width='fit-content'
          gap={(theme) => theme.spacing(1)}
        >
          <Typography variant='body2'>
            {currency} {formatUSD(performanceFeeAccrued)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align='right' size='small'>
        <Box
          display='flex'
          alignItems='center'
          marginLeft='auto'
          width='fit-content'
          gap={(theme) => theme.spacing(1)}
        >
          <Typography variant='body2'>
            {currency} {formatUSD(managementFeesAccrued)}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default RewardsTableRow
