import { Typography } from '@mui/material'

import useLocale from '@/hooks/context/useLocale'
import useVaultsPage from '@/hooks/smartVaults/useVaultsPage'

import DetailsRowWithLink from '@/components/atoms/DetailsRowWithLink'
import ColumnlessGridRow from '@/components/molecules/ColumnlessGridRow'
import DetailsRow from '@/components/molecules/DetailsRow'
import SectionColumn from '@/components/molecules/SectionColumn'
import { StyledGridItem } from '@/components/molecules/StyledGridItem'

import { DAO_FEES } from '@/constants'
import { formatUSD } from '@/utils/formats'

const ApyDetails = () => {
  const t = useLocale()

  const { smartVaultData } = useVaultsPage()

  const { fees, baseApy, adjustedApy, incentivizedRewards, vaultDetails } =
    smartVaultData

  const apyAfterFees =
    (Number(adjustedApy) || 0) - (Number(incentivizedRewards?.totalApy) || 0)

  return (
    <SectionColumn
      sx={{
        padding: '0',
      }}
      noVerticalGap
      title={t(
        'components.organisms.smartVaults.collapsibleRow.metrics.sectionTitles.apy'
      )}
    >
      <StyledGridItem>
        <DetailsRow
          name={t('components.organisms.tooltips.apyTooltip.baseApy.title')}
          strong
        >
          <Typography variant='body2' fontWeight={500}>
            {`${formatUSD(baseApy ?? '0')} %`}
          </Typography>
        </DetailsRow>
        {(vaultDetails?.vaultSource === 'drip' ||
          vaultDetails?.vaultSource === 'nuklai') && (
          <>
            <DetailsRowWithLink
              link='https://drip.gitbook.io/drip-finance'
              text={t(
                'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.drip.launchPoints.content'
              )}
              rowTitle={vaultDetails?.farmRewards}
            />
            <DetailsRowWithLink
              link='https://drip.gitbook.io/drip-finance'
              text={t(
                'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.drip.dripPoints.content'
              )}
              rowTitle={t(
                'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.drip.dripPoints.label'
              )}
            />
          </>
        )}
        {!!Number(fees?.depositFeePercentage) && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.depositFee.label'
            )}
          >
            <Typography variant='body2'>
              {`${formatUSD(fees?.depositFeePercentage.toString() ?? '0')} %`}
            </Typography>
          </DetailsRow>
        )}
        {!!Number(fees?.managementFeePercentage) && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.managementFee.label'
            )}
          >
            <Typography variant='body2'>
              {`${formatUSD(
                fees?.managementFeePercentage.toString() ?? '0'
              )} %`}
            </Typography>
          </DetailsRow>
        )}
        {!!Number(fees?.performanceFeePercentage) && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.performanceFee.label'
            )}
          >
            <Typography variant='body2'>
              {`${formatUSD(
                fees?.performanceFeePercentage.toString() ?? '0'
              )} %`}
            </Typography>
          </DetailsRow>
        )}
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.daoFee.label'
          )}
        >
          <Typography variant='body2'>{`${formatUSD(DAO_FEES)} %`}</Typography>
        </DetailsRow>
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.apyAfterFees.label'
          )}
          strong
        >
          <Typography variant='body2' fontWeight={500}>
            {`${formatUSD(apyAfterFees ?? '0')} %`}
          </Typography>
        </DetailsRow>
        {!!Number(incentivizedRewards?.totalApy) && (
          <DetailsRow
            name={t(
              'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.incentivisedRewards.label'
            )}
          >
            <Typography variant='body2'>
              {`${formatUSD(incentivizedRewards?.totalApy ?? '0')} %`}
            </Typography>
          </DetailsRow>
        )}
        <DetailsRow
          name={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.totalApy.label'
          )}
          strong
        >
          <Typography variant='body2' fontWeight={500}>
            {`${formatUSD(adjustedApy ?? '0')} %`}
          </Typography>
        </DetailsRow>
        <ColumnlessGridRow
          text={t(
            'components.organisms.smartVaults.collapsibleRow.metrics.metricsAdvancedGrid.howFeesCalculated.label'
          )}
          link='https://docs.spool.fi/technical-reference/systems-and-overview/fee-systems#vault-owner-fee'
        />
      </StyledGridItem>
    </SectionColumn>
  )
}

export default ApyDetails
